@import './venue/venue-common.scss';
@import './venue/venue-header.scss';
@import './venue/venue-badges.scss';
@import './venue/venue-reviews.scss';
@import './venue/venue-aside.scss';
@import './venue/venue-tooltip.scss';
@import './venue/venue-leads-dialog.scss';
@import './venue/venue-filter.scss';

.venue-gallery {
  position: absolute;
  display: flex;
  width: 100%;
  height: 8rem;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    height: 18rem;
  }

  ~ .venue {
    padding-top: 8rem;

    @media screen and (min-width: $breakpoint-portrait-tablet) {
      padding-top: 14rem;
    }
  }
}

.venue {
  display: grid;
  grid-template-areas:
    'main'
    'reviews'
    'aside'
    'footer';
  gap: 1.5rem;
  padding-top: 4.5rem;
  padding-bottom: 1.5rem;
  line-height: 1.5rem;
  margin: 0 auto;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    grid-template-columns: 19rem 1fr;
    grid-template-areas:
      'main main'
      'aside reviews'
      'footer footer';
    padding-top: 3rem;
    width: calc(100vw - 3rem);
    max-width: 64rem;
  }
}

.venue-layout-fixer-on-not-verified {
  margin-bottom: -4.5rem;

  @media screen and (min-width: #{$breakpoint-portrait-tablet}) {
    display: none;
  }
}

.venue-reviews {
  grid-area: reviews;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.venue-reviews-row {
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @media screen and (max-width: #{$breakpoint-portrait-tablet - 1px}) {
    .felt-pill {
      flex-grow: 1;
      text-align: center;
    }
  }
}

.venue-reviews-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 0;
  padding: 0;
}

.venue-reviews-waypoint {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  scroll-margin-top: 9.75rem;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    scroll-margin-top: 5.75rem;
  }
}

.venue-footer {
  grid-area: footer;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.venue-sticky-header {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
  width: 100vw;
  background-color: white;
  z-index: 400;

  @include breakpoint($breakpoint-portrait-tablet) {
    padding: 1rem 1.5rem;
  }
}

.venue-sticky-header-row {
  display: flex;
  gap: 1rem;
}

.venue-sticky-header-info {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  overflow: hidden;

  [class*='venue-heading'] {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
  }
}

.venue-sticky-header-rating {
  --rating-size: 1rem;
  display: flex;
  gap: 0.5rem;
  line-height: 1rem;
}

.venue-sticky-header-actions {
  display: flex;
  gap: 1rem;
  flex: 0 0 auto;
}

.venue-hide-mobile {
  @media screen and (max-width: #{$breakpoint-portrait-tablet - 1px}) {
    display: none;
  }
}

.venue-show-mobile {
  @media screen and (min-width: $breakpoint-portrait-tablet) {
    display: none;
  }
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}
