.trust-state--wrap {
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: var(--color-grey-500);

  .trust-state {
    position: relative;
    margin-left: 0.25rem;
  }

  .trust-state--icon {
    color: var(--color-signal-green);
    font-size: 0.875rem;
    margin-right: 0.25rem;
  }

  .trust-state--gray {
    color: var(--color-grey-500);
  }
}

.trust-state--hint {
  position: relative;
  display: inline-block;
  line-height: 1.125rem;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: 1px dotted black;
  }
}
