.badge-share-dialog {
  max-width: 39.375rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &-close {
    margin-left: auto;

    @media (min-width: $breakpoint-portrait-tablet) {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .share--wrapper {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
