.skeleton-loader {
  --bone-scale: 1;
  --column-count: 2;
  --color-start: #{$grey-100};
  --color-middle: #{$grey-400};
  --color-end: #{$grey-200};

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  border-radius: 0.5rem;
  padding: 1rem;

  @media (min-width: $breakpoint-small-tablet) {
    padding: 1.5rem;
  }

  &.no-padding {
    padding: 0;
  }

  .thin,
  .medium,
  .fat {
    border-radius: 0.5rem;
    background: linear-gradient(45deg, var(--color-start), var(--color-middle), var(--color-end));
    background-size: 300% 100%;
    animation: swosh 3s ease infinite;
  }

  .thin {
    height: calc(var(--bone-scale) * 1rem);
  }

  .medium {
    height: calc(var(--bone-scale) * 2rem);
  }

  .fat {
    height: calc(var(--bone-scale) * 3.5rem);
  }

  > .column,
  .row {
    grid-column: span 2;
  }

  .half {
    grid-column: span 1;
  }

  .row {
    all: revert;
    display: grid;
    grid-template-columns: repeat(var(--column-count), 1fr);
    gap: 1rem;

    grid-column: span 2;
  }

  @keyframes swosh {
    0% {
      background-position-x: 0%;
    }

    50% {
      background-position-x: 99%;
    }

    100% {
      background-position-x: 1%;
    }
  }
}
