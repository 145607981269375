.rating-list {
  tr td {
    width: auto;

    &:first-child {
      border-left: none;
    }

    &:nth-child(2) {
      width: 30%;
    }

    &:last-child {
      width: 25%;
      border-right: none;
    }
  }

  tfoot th:nth-child(2) {
    font-weight: 400;
  }

  small {
    font-size: 1em;
    font-weight: 300;
    color: $grey-500;
  }

  &-diff {
    display: inline-flex;
    align-items: center;
    font-size: 0.75rem;

    svg {
      margin: 0 0.25rem 0 0.375rem;
    }

    &-negative {
      color: $signal-red;

      svg {
        transform: rotate(135deg);
      }
    }

    &-positive {
      color: $signal-green;

      svg {
        transform: rotate(45deg);
      }
    }
  }
}
