.navigation-bar {
  width: 100%;
  background-color: $light-blue;
  padding: 1em;
  
  @media (min-width: $breakpoint-large-tablet) {
    display: none;
  }

  &-top {
    display: flex;
    align-items: center;
    padding: 1em;
    position: relative;
  }

  &-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    text-decoration: none;
    top: 1.25em;

    img {
      margin-right: 0.75em;
    }

    span {
      font-family: 'bitstream-cooper-md';
      font-size: 1.125em;
      color: $black;
    }
  }
}