.externalappintegration-list {
  .label {
    float: right;
    display: inline;
    padding: 0.5em 0.6em 0.3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
  }

  .label-green {
    background-color: green;
  }
  .label-orange {
    background-color: orange;
  }

  .label-chocolate {
    background-color: chocolate;
  }

  .label-blue {
    background-color: blue;
  }
}
