$article-cards-max-width: 1096px;
$article-card-max-height: 257px;

.articles {
  display: grid;
  gap: 3rem;
  grid-template-columns: auto;
  justify-content: center;
  padding: 1rem 0;

  .article-card {
    max-width: calc(#{$article-cards-max-width} / 3);
    max-height: 22.5rem;

    border-radius: 0.5rem;
    overflow: hidden;

    @include shadow;

    img {
      display: block;
      padding-bottom: 1rem;

      object-fit: cover;
      width: 100%;
      height: $article-card-max-height;
    }
    .article-card-content {
      display: grid;

      padding: 0 1rem 1.6875rem;
      text-align: center;
      h3 {
        margin-bottom: 0.625rem;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      a {
        font-size: 1rem;
        font-weight: 700;
        text-decoration: none;
        align-self: flex-end;
      }
    }
  }

  @include breakpoint($breakpoint-portrait-tablet) {
    grid-template-columns: repeat(3, auto);
    gap: 1.5rem;
  }
}
