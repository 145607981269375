$textarea-height-default: 8.75rem;

:root {
  --textarea-height: #{$textarea-height-default};
}

.shared-inputs-Textarea {
  display: grid;
  gap: 0.5rem;

  .field-heading {
    display: flex;
    justify-content: space-between;

    .required-flag {
      color: var(--color-signal-red);
    }

    &.field-heading--lg {
      .label-text {
        font-family: var(--font-serif);
        font-size: 1.25rem;
        margin: 0 0 0.5rem;
      }
    }
  }

  textarea {
    @include font-family-body;
    @include font-paragraph-14;

    height: var(--textarea-height);
    background-color: var(--color-white);
    border: 1px solid var(--color-grey-400);
    border-radius: var(--border-radius-small);

    color: var(--color-black);
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    padding: 0.5625rem 0.5rem;

    resize: none;

    &::placeholder {
      color: var(--color-grey-500);
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: none;
    }

    &:disabled {
      border-color: var(--color-grey-400);
      color: var(--color-grey-500);
    }

    // States
    &.state-active {
      border-color: var(--color-reco-blue);
    }

    &.state-error {
      border-color: var(--color-signal-red);
    }

    &.has-icon {
      grid-template-columns: auto 2.5rem;
    }
  }

  .help-text {
    color: var(--color-grey-500);
  }
}
