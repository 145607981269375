.form-check {
  display: block;
  padding-left: 1em;
  margin-bottom: 0.125rem;

  .form-check-input {
    float: left;
    margin-left: -1.2em;
  }

  .form-check-input + label {
    margin-left: 0.5em;
  }
}

.form-check-button {
  background: #FFFFFF;
  border: 2px solid $blue1;
  border-radius: 0.5em;
  padding: 0 0 0 0.725em;
  display: flex;
  align-items: center;

  .form-check-input {
    margin-left: 0;
    margin-top: 0;
    flex: 1 0 auto;
  }

  .form-check-label {
    color: $blue1;
    font-weight: 700;
    padding: 0.575rem 0.75rem 0.575rem 0.25rem;
    width: 100%;
  }
}

.form-check-input {
  width: 0.875rem;
  height: 0.875rem;
  margin-top: (1.5 - 1em) / 2; // line-height minus check height
  vertical-align: top;
  background-color: $white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid $gray4;
  appearance: none;
  -webkit-appearance: none;
  color-adjust: exact; // Keep themed appearance for print

  &[type="checkbox"] {
    border-radius: 0.25em;
  }

  &[type="radio"] {
    border-radius: 50%;
  }

  &:active {
    filter: brightness(90%);
  }

  &:focus {
    border-color: $blue3;
    outline: 0;
    box-shadow: none;
  }

  &:checked {
    background-color: $white;

    &[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%233096D0' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }

    &[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-5 -5 10 10'%3e%3ccircle r='3' fill='%233096D0'/%3e%3c/svg%3e");
    }
  }

  &[type="checkbox"]:indeterminate {
    background-color: $red;
    border-color: $red;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%233096D0' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  }

  &:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.6;
  }

  // Use disabled attribute in addition of :disabled pseudo-class
  // See: https://github.com/twbs/bootstrap/issues/28247
  &[disabled],
  &:disabled {
    ~ .form-check-label {
      opacity: 0.6;
    }
  }
}

.form-check-label {
  color: $black;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.875em;
  line-height: 1.5;
}
