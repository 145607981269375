@mixin form-control-gap {
  & + .form-control,
  & + .form-select,
  & + .form-check {
    margin-top: $form-control-gap-default;

    @content;
  }
}

.form-control-inline {
  display: flex;
  gap: $form-control-gap-default;

  @include form-control-gap;
}

// Should handle styling for textual form controls like input and textareas
.form-control {
  display: block;
  width: 100%;
  background-color: $white;
  border: 1px solid $grey-400;
  border-radius: 0.25rem;
  color: $black;
  font-size: $default-font-size;
  font-weight: 300;

  font-family: 'Roboto', HelveticaNeue, sans-serif;
  appearance: none; // Fix appearance for date inputs in Safari
  -webkit-appearance: none;

  padding: 0.5rem;
  line-height: 1.375rem;

  &.form-control-icon {
    padding: 0;
    line-height: normal;
    display: flex;
    align-items: center;

    input {
      height: 2.375rem;
      padding: 0.5rem;
      line-height: 1.375rem;

      @extend .form-control-extended-input;

      padding: 0.3125rem 0 0.3125rem 0.5rem;

      &[type='date']::-webkit-calendar-picker-indicator {
        @extend .form-control-calendar-indicator;
      }
    }

    label {
      display: block;
      cursor: pointer;
      padding: 0.5rem 0.5rem 0.5rem 0.7rem;
    }

    svg {
      font-size: 1rem;
    }
  }

  &.form-control-button {
    padding: 0;
    display: flex;

    input {
      @extend .form-control-extended-input;
    }
  }

  @include form-control-gap {
    .form-control-inline & {
      margin-top: 0;
    }
  }

  &:focus {
    border-color: $blue3;
    outline: 0;
  }

  &::placeholder {
    color: $gray9;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  &:disabled,
  &[readonly] {
    background-color: $gray12;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }

  &.error {
    &,
    &:focus {
      border-color: $pink3;
    }
  }

  &:invalid {
    border-color: $pink3;
    box-shadow: none;
  }
}

.form-control-extended-input {
  padding: 0.3125rem 0.75rem;
  border: none;
  background-color: transparent;
  display: block;
  width: calc(100% - 2.25rem);
  border-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 1px solid $gray4;
  font-family: 'Roboto', HelveticaNeue, sans-serif;
  font-size: 0.875rem;
  position: relative;
}

.form-control-calendar-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
