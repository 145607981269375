.review-card {
  &--action-trigger {
    border: none;
    background: none;
    padding: 0.5rem;
    cursor: pointer;

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;

      svg {
        font-size: 1.25rem;
        color: $blue3;
      }
    }

    &.active {
      > span {
        background: $gray11;
      }
    }
  }

  &--actions {
    background: $white;
    box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.2);
    border: 1px solid $gray4;
    border-radius: 0.5em;
    position: absolute;
    z-index: $layer-5;
    top: 100%;
    right: 0;
    min-width: 190px;
    display: none;

    &.active {
      display: block;
    }

    ul.actions-list {
      margin: 1rem 0;
      padding: 0;

      li.action-item {
        display: flex;
        margin: 0 0 0.5rem;
        border-bottom: none;
        padding: 0;

        .icon-wrapper {
          display: flex;
          font-size: 16px;
          margin-right: 0.75em;
          color: $gray14;
          &.top {
            align-self: start;
            margin-top: 1px;
          }
        }

        a {
          text-decoration: none;
          display: block;
          color: $blue5;
        }

        > button,
        > span:not(.icon-wrapper),
        > a {
          display: flex;
          align-items: center;
          padding: 0.25rem 1rem;
          font-weight: 700;
          font-size: 0.875rem;
          color: $blue5;
          width: 100%;
          box-sizing: border-box;
          text-align: initial;
          font-family: $font-default;
          line-height: 1.25;
        }

        .loading > * {
          color: $gray4;
        }
      }
    }
  }
}
