.hero-search {
  position: relative;
  z-index: $layer-1;
  font-size: 1rem;
  background-color: $light-blue;

  &-outer {
    display: grid;
    gap: 2rem;
    justify-content: center;

    position: relative;
    max-width: 41rem;
    margin: 0 auto;
    padding: 3rem 1.25rem; // should use $indexpage-container-padding, refactor after we decided where to put config

    @media (min-width: $breakpoint-portrait-tablet) {
      padding-top: 3em;
      padding-bottom: 3em;
    }

    @media (min-width: $breakpoint-landscape-tablet) {
      padding-top: 5.375em;
      padding-bottom: 5.375em;
    }

    @media (min-width: $breakpoint-reco-large) {
      padding-top: 6.625em;
      padding-bottom: 6.625em;
    }
  }

  .hero-graphics {
    display: none;

    $graphics-width: 11rem;

    @media (min-width: $breakpoint-portrait-tablet) {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -1rem;
    }

    @media (min-width: $breakpoint-reco-large) {
      left: -2rem;
    }

    img,
    lottie-player {
      width: 11rem;

      @media (min-width: $breakpoint-landscape-tablet) {
        width: 15rem;
      }

      @media (min-width: $breakpoint-reco-large) {
        max-width: 30rem;
        width: 26vw;
      }
    }

    img {
      display: block;
    }

    &.right-mount {
      right: -4em;
      left: auto;

      @media (min-width: $breakpoint-reco-large) {
        right: -1em;
      }
    }
  }

  &-inner {
    text-align: center;
    position: relative;
    z-index: $layer-5;

    @media (min-width: $breakpoint-portrait-tablet) {
      max-width: 29em;
    }

    @media (min-width: $breakpoint-landscape-tablet) {
      max-width: 35.25em;
    }

    @media (min-width: $breakpoint-reco-large) {
      max-width: none;
    }

    .heading1,
    > p {
      text-align: center;
    }

    .heading1 {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
      display: inline-block;
      max-width: 75vw;

      @media (min-width: $breakpoint-portrait-tablet) {
        font-size: 2em;
        margin-bottom: 1rem;
      }
    }

    p {
      font-size: $default-font-size;
      padding: 0 5%;
      margin: 0 0 1rem;
    }

    form {
      display: flex;
      flex-direction: column;
      background-color: $white;

      margin-top: 2rem;
      padding: 1rem;
      border-radius: 1rem;

      // Element wrapping form-control input (created by JS for autocomplete)
      > div {
        width: 100%;
        margin-bottom: 1em;
        position: relative;

        @media (min-width: $breakpoint-portrait-tablet) {
          width: calc(50% - 2em);
          margin-bottom: 0;
          margin-right: 1em;
        }

        svg {
          color: $blue3;
          font-size: 0.875em;
        }
      }

      .form-control {
        width: 100%;
        height: 2.6875rem; // Always a fixed value so that we avoid Cumulative Layout Shift when rendering the react component
      }

      @media (min-width: $breakpoint-portrait-tablet) {
        flex-direction: row;
      }

      button {
        text-align: center;
        justify-content: center;

        @media (min-width: $breakpoint-portrait-tablet) {
          text-align: left;
          justify-content: flex-start;
        }
      }
    }
  }

  $bottom-arch-aspect-ratio: 19.2;
  margin-bottom: calc(100vw / #{$bottom-arch-aspect-ratio});

  .bottom-arch {
    z-index: $layer-below;
    position: absolute;
    left: 0;
    width: 100%;
  }
}
