/**
 * Style selectors for typography are defined in this file.
 */

// @todo make sure that we apply the correct typography styles to all HTML heading elements.
h1 {
  @include font-family-heading;
}

/**
 * Headings
 */
.heading1,
.heading2,
.heading3,
.heading4,
.heading5 {
  color: var(--color-black);

  > a {
    @include link-decoration;
    color: var(--color-black);
  }

  &.heading-underline {
    @include heading-underline;
  }
}

.heading1 {
  @include heading1;
}

.heading2 {
  @include heading2;
}

.heading3 {
  @include heading3;
}

.heading4 {
  @include heading4;
}

.heading5 {
  @include heading5;
}

.heading6 {
  @include heading6;
}

/**
 * Anchor links
 */
.link {
  @include link-decoration;
}

/**
 * Paragraph and Lead
 */
.lead {
  @include font-lead-14;
}
