:root {
  --write-review-height: 3.0625rem;

  @media (min-width: $breakpoint-small-tablet) {
    --write-review-height: 4.0625rem;
  }
}

.profile {
  // Setting base size to 16px here and not on body to avoid unexpected behaviour on all other views.
  // 16px is browser default and makes it easier to use em measurements.
  // Whenever possible this should be moved to body.
  font-size: 16px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto auto var(--write-review-height) auto;
  gap: 1em;
  grid-template-areas:
    'header header'
    'tabs tabs'
    'write-review write-review'
    'main main'
    'sidebar sidebar'
    'footer footer';

  &.profile--without-tabs {
    grid-template-rows: auto var(--write-review-height) auto;
    grid-template-areas:
      'header header'
      'write-review write-review'
      'main main'
      'sidebar sidebar'
      'footer footer';

    @media (min-width: $breakpoint-large-tablet) {
      grid-template-areas:
        'header header'
        'write-review sidebar'
        'main sidebar'
        'footer footer';
    }
  }

  @media (min-width: $breakpoint-small-tablet) {
    gap: 1.5em;
  }

  @media (min-width: $breakpoint-large-tablet) {
    grid-template-areas:
      'header header'
      'tabs tabs'
      'write-review sidebar'
      'main sidebar'
      'footer footer';
  }

  &.profile--sidebar-hidden {
    &.profile--without-tabs {
      @media (min-width: $breakpoint-large-tablet) {
        grid-template-areas:
          'header header'
          'write-review write-review'
          'main main'
          'footer footer';
      }
    }

    @media (min-width: $breakpoint-large-tablet) {
      grid-template-areas:
        'header header'
        'tabs tabs'
        'write-review write-review'
        'main main'
        'footer footer';
    }
  }

  &-header {
    grid-area: header;
    display: flex;
    flex-direction: column;
    padding: 1em;

    @media (min-width: $breakpoint-small-tablet) {
      padding: 1.5em;
    }

    @media (min-width: $breakpoint-large-tablet) {
      flex-direction: row;
    }

    &--content {
      @media (min-width: $breakpoint-large-tablet) {
        width: 60%;
      }

      .text-clamp {
        margin: 0.714em 0 0.25em;

        .lead-legacy {
          margin-bottom: 1em;

          h3,
          p {
            display: inline;
          }

          h3 {
            font-size: 1em;
            font-weight: 300;
            line-height: 1.57;
            margin: 0;

            a {
              text-decoration: none;
            }
          }
        }
      }

      .profile-stats {
        margin-top: 1.75em;

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.875em;
          flex-wrap: wrap;

          @media (min-width: $breakpoint-large-tablet) {
            margin-bottom: 0.875em;
          }

          li {
            width: 100%;
            border-radius: 0.25em;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            padding: 1em;
            text-align: left;
            margin-bottom: 1em;

            @media (min-width: $breakpoint-mobile) {
              width: 48%;
              flex-flow: column;
              text-align: center;
            }

            @media (min-width: $breakpoint-small-tablet) {
              flex-flow: row;
            }

            @media (min-width: $breakpoint-large-tablet) {
              width: 48%;
              margin-bottom: 0;
              flex-flow: column;
              text-align: left;
            }

            @media (min-width: $breakpoint-reco) {
              flex-flow: row;
            }

            .heading1 {
              margin: 0 0.5em 0 0;
              font-size: 2em;
            }

            .unstyled-h3 {
              all: unset;
              font-weight: 600;
              font-size: 0.875em;
            }

            .flex-heading3 {
              font-size: 16px;
              display: flex;
              flex-flow: column;

              p {
                margin: 0;
              }
            }

            strong {
              font-size: 0.875em;
              font-weight: 600;
            }

            small {
              font-size: 0.75em;
            }

            &.light-green {
              background-color: $light-green;
            }

            &.light-blue {
              background-color: $light-blue;
            }

            &.speech-bubble .heading1 {
              width: 5.1em;
              height: 1.71875em;
              background: url(/assets/images/speech-bubble.svg) no-repeat center center;
              background-size: contain;
              text-align: center;
            }

            &.small-sign .heading1 {
              width: 3.0625em;
              height: 1.625em;
              background: url(/assets/images/small-sign.svg) no-repeat center center;
              background-size: contain;
              text-align: center;
            }
          }
        }
      }
    }

    &--side {
      display: flex;
      flex-flow: column;

      @media (min-width: $breakpoint-large-tablet) {
        width: 35%;
        margin-left: auto;
      }

      nav {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        order: 2;

        .profile-phone-hidden {
          display: none;
        }

        @media (min-width: $breakpoint-small-tablet) {
          flex-direction: row;
        }

        @media (min-width: $breakpoint-large-tablet) {
          margin-bottom: 0;
          order: 1;
        }

        a {
          margin-bottom: 1em;
          width: 100%;

          @media (min-width: $breakpoint-small-tablet) {
            margin: 0 1em 1em 0;
          }
        }
      }

      .header-badges {
        order: 1;
        margin-bottom: 1em;

        @media (min-width: $breakpoint-large-tablet) {
          order: 2;
          margin-top: auto;
          margin-bottom: 0.375em;
        }
      }
    }
  }

  &-tabs {
    grid-area: tabs;
    margin: -1em 0 -0.5em;

    .bttn {
      @media (max-width: $breakpoint-small-tablet) {
        font-size: 12px;
        padding: 6px 8px;
      }
    }
  }

  .profile-write-review {
    grid-area: write-review;
    padding: 0 1em;

    @media (min-width: $breakpoint-small-tablet) {
      padding: 0 1.5em;
    }
  }

  &-main {
    grid-area: main;
    padding: 1em;

    @media (min-width: $breakpoint-small-tablet) {
      padding: 1.5em;
    }
  }

  &-main,
  &-sidebar {
    > div {
      display: flex;
      flex-direction: column;
    }
  }

  &-main {
    .bttn-group {
      align-self: center;
    }
  }

  &-sidebar {
    grid-area: sidebar;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    .heading3 {
      align-self: center;
      text-align: center;
    }

    &-inner {
      width: 100%;
      border-radius: 0.5rem;
      padding: 1rem;
      background: $white;

      display: flex;
      flex-direction: column;

      @media (min-width: $breakpoint-small-tablet) {
        padding: 1.5em;
      }
    }

    // Hide sidebar but keep grid area
    &-hidden {
      display: none;
    }

    .heading3 {
      &:nth-of-type(2) {
        margin-top: 2em;
      }
    }
  }

  // Hide sidebar and remove grid area
  &.profile--sidebar-hidden {
    .profile-sidebar {
      @media (min-width: $breakpoint-large-tablet) {
        display: none;
      }
    }
  }

  &-badges {
    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }

  &-employers {
    display: flex;
    flex-direction: column;

    > .heading3 {
      align-self: center;
      text-align: center;
    }

    &--list {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding-bottom: 1.25em;
        border-bottom: 1px solid $gray1;
        margin-bottom: 1.25em;
      }
    }
  }

  &-past-reviews {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .heading3 {
      align-self: center;
      margin: 2em 0;
      text-align: center;
    }

    &--more {
      align-self: center;
      margin-top: 1em;
    }

    &--loader {
      margin-top: 1em;
    }
  }

  &-footer {
    grid-area: footer;

    &--related {
      display: flex;
      flex-direction: column;
      padding: 1em;

      @media (min-width: $breakpoint-small-tablet) {
        padding: 1.5em;
      }

      > .heading3 {
        align-self: center;
      }

      &-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
        grid-gap: 1.25em;
      }
    }
  }
}
