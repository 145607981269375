$company-card-width: 250px;

.company-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  padding: 1rem;
  background: $white;
  border-radius: 0.5rem;
  @include shadow();
  // consider moving the margin into the `shadow mixin`, but be aware of different box models/display modes etc.
  margin: 0 0 $soft-shadow-spread;

  width: $company-card-width;

  .company-card-about {
    > * {
      display: inline;
      white-space: normal;
    }

    a {
      font-weight: 700;
    }
  }

  .company-card-text {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
