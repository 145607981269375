.c {
  float: left;
  height: 4px;
  display: block;
  margin: 0;
}
.cb {
  float: left;
  height: 6px;
  display: block;
  margin: 0;
}
.c1 {
  background: #eb2975;
  width: 19%;
}
.c2 {
  background: #fbc143;
  width: 20%;
}
.c3 {
  background: #479bd3;
  width: 33%;
}
.c4 {
  background: #eb2975;
  width: 12%;
}
.c5 {
  background: #bad548;
  width: 16%;
}
