.venue-aside {
  grid-area: aside;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  scroll-margin-top: 9.75rem;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    scroll-margin-top: 5.75rem;
  }
}

.venue-opening-hours-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  list-style: none;
  padding: 1rem;
  margin: 0;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    padding: 1rem 1.5rem;
  }

  li {
    display: flex;
    justify-content: space-between;
  }
}

.venue-opening-hours-state {
  margin: 0;
  font-weight: 500;
  text-align: center;
  padding: 0 1rem 1rem;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    padding: 0 1.5rem 1.5rem;
  }
}

.venue-aside-section {
  background-color: white;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    border-radius: 0.5rem;
  }

  .venue-hgroup {
    padding: 1rem;
    border-bottom: 1px solid var(--color-grey-300);

    @media screen and (min-width: $breakpoint-portrait-tablet) {
      padding: 1.5rem;
    }
  }
}

.venue-aside-section-block {
  margin: 1rem 0;
  padding: 0 1rem;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    padding: 0 1.5rem;
  }
}

.venue-hgroup + .venue-aside-details {
  border-top: none;
}

.venue-aside-details {
  border-top: 1px solid var(--color-grey-300);

  &:last-of-type:not(:last-child) {
    border-bottom: 1px solid var(--color-grey-300);
  }

  summary {
    padding: 1rem 1.5rem;
    cursor: pointer;
    user-select: none;

    list-style-type: none; /* Firefox */

    &::-webkit-details-marker {
      display: none; /* Chrome */
    }
  }

  &[open] .venue-aside-details-marker {
    transform: rotate(90deg);
  }
}

.venue-aside-summary-col {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.venue-aside-summary-row {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.venue-aside-details-marker {
  flex: 0 0 auto;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: auto;
  text-align: center;
  font-size: 1rem;
  color: var(--color-grey-500);
  transition: transform 150ms ease-out;
}

.venue-aside-details-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: -0.5rem;
  padding: 0 1.5rem 1rem;

  p {
    margin: 0;
  }
}

.venue-aside-details-body-frame {
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: 1rem;
  column-gap: 0.5rem;
  padding: 1rem;
  border: 1px solid var(--color-grey-300);
  border-radius: 0.5rem;
  align-items: center;
}

.venue-aside-section-logo-footer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;

  img {
    height: 1rem;
  }

  p {
    margin: 0;
    color: var(--color-grey-500);
  }
}

.venue-profile-list {
  --rating-size: 1rem;

  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 1rem;
  margin: 0;

  .felt-btn {
    padding: 0.5rem;
  }
}

.venue-profile-image {
  flex: 0 0 auto;
  border-radius: 999px;
  height: 4rem;
  width: 4rem;
  align-self: flex-start;
}

.venue-profile-details {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.venue-venue-list {
  --rating-size: 1rem;

  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 1rem;
  margin: 0;

  .felt-btn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    padding: 0.5rem;
  }
}

.venue-venue-details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
}
