$margin-small: 10px;
$margin-medium: 20px;
$margin-large: 40px;

$default-font-size: 14px;

/**
 * It is important that we slim down the amount of breakpoints available to us
 * when styling. Design + Devs agree on the notations below. We want to keep
 * a small amount of breakpoints and align them to todays standards.
 * We want to make sure that we keep on pushing for mobile first development.
 *
 * Eg. (in mobile first design and) the suggestion below all declarations shared
 * will affect from 0px-767px.
 *
 * @related app/assets/javascripts/util/breakpoints.js
 */
$breakpoint-small-mobile: 320px; // Refactor and Remove
$breakpoint-medium-mobile: 380px; // Refactor to 375
$breakpoint-mobile: 414px; // Refactor and Remove
$breakpoint-small-tablet: 600px; // Refactor and Remove
$breakpoint-portrait-tablet: 768px; // Keep
$breakpoint-large-tablet: 800px; // Refactor and Remove
$breakpoint-reco: 960px; // Refactor and Remove
$breakpoint-landscape-tablet: 1024px; // Keep
$breakpoint-tablet: 1055px; // Refactor and Remove
$breakpoint-reco-large: 1280px; // Keep (maybe refactor into 1440

$page-max-width: 1440px;
