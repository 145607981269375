.alternativeListing {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--color-grey-300);
}

.alternativeListing-source {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  gap: 0.5rem;
  border-radius: 999px;
  font-weight: 500;
  box-shadow: inset 0 0 0 1px var(--color-grey-300);
  background: white;
}

.alternativeListing-header {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.alternativeListing-header-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.alternativeListing-header-heading {
  display: flex;
  align-items: center;
  min-height: 2.5rem;
}

.alternativeListing-header-rating {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 0.5rem;
  row-gap: 0.125rem;
}

.alternativeListing-header-badge {
  flex: 0 0 auto;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.16));
}

.alternativeListing-review {
  margin: 0;

  &:before {
    content: '“';
  }

  &:after {
    content: '”';
  }
}

.alternativeListing-foot {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  flex-direction: column;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    gap: 0.5rem;
    flex-direction: row;
  }
}

.alternativeListing-author {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.alternativeListing-author-avatar {
  border-radius: 999px;
}
