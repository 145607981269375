@mixin font-family-heading {
  font-family: 'bitstream-cooper-md';
}

@mixin font-family-body {
  font-family: $font-default;
}

@mixin _font-heading {
  @include font-family-heading;
  letter-spacing: 0;
  line-height: 1.25;
  font-weight: var(--font-weight-regular);
  margin-top: 0;
}

@mixin _font-bold {
  font-weight: var(--font-weight-bold);
}

@mixin _font-paragraph {
  @include font-family-body;
  font-weight: var(--font-weight-light);
  line-height: 1.5714285714;
}

/**
 * Headings
 */
@mixin _font-heading-32 {
  @include _font-heading;
  font-size: 2rem;

  margin-bottom: var(--grid-24);
}

@mixin _font-heading-24 {
  @include _font-heading;
  font-size: 1.5rem;

  margin-bottom: var(--grid-24);
}

@mixin _font-heading-20 {
  @include _font-heading;
  font-size: 1.25rem;
  line-height: 1.2;

  margin-bottom: var(--grid-16);
}

@mixin _font-heading-18 {
  @include _font-heading;
  font-size: 1.125rem;
  line-height: 1.2;

  margin-bottom: var(--grid-16);
}

@mixin _font-heading-16 {
  @include _font-heading;

  @include breakpoint($breakpoint-reco) {
    @include font-family-body;
    @include _font-bold;
  }

  font-size: 1rem;
  line-height: 1.171875;
}

@mixin _font-heading-14 {
  @include _font-bold;
  font-size: 0.875rem;
  line-height: 1.1721428571;
  margin-top: 0;
}

/**
 * Leads
 */
@mixin font-lead-14 {
  @include _font-bold;
  font-size: 0.875rem;
  line-height: 1.5714285714;
}

/**
 * Paragraphs
 */
@mixin font-paragraph-14 {
  @include _font-paragraph;
  font-size: 0.875rem;
}

@mixin font-paragraph-12 {
  @include _font-paragraph;
  font-size: 0.75rem;
  line-height: 1.5;
}
