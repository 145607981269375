.content-button {
  border: 1px solid $gray8;
  background: $white;
  border-radius: 0.5rem;
  appearance: none;
  padding: 0.25rem 0.5rem;
  display: block;
  text-align: left;
  font-size: 0.75rem;
  min-height: 2.5rem;
  cursor: pointer;

  &.content-button-active,
  &:hover {
    background-color: $gray11;
  }
}