/**
 * Layers (z-index) (legacy)
 */

$layer-14: 500;
$layer-13: 400;
$layer-12: 300;
$layer-11: 200;
$layer-10: 100;
$layer-9: 90;
$layer-8: 80;
$layer-7: 70;
$layer-6: 60;
$layer-5: 50;
$layer-4: 40;
$layer-3: 30;
$layer-2: 20;
$layer-1: 10;
$layer-initial: 0;
$layer-below: -1;
