.organization-pdf {
  display: grid;
  gap: var(--grid-24);
  width: 100%;
  height: fit-content;

  .pdf-main {
    margin-top: 1rem;
  }
}

.pdf {
  display: block;

  > p {
    font-size: 0.875em;

    &:first-child {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 2em;
    }
  }

  .heading5 {
    &.heading-underline {
      display: block;
    }

    small {
      font-size: 1em;
      font-weight: 300;
    }
  }

  &-main {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-reco-large) {
      display: grid;
      grid-template-columns: 10.375em 1fr;
    }
  }

  &-sidebar {
    margin-bottom: 2em;

    @media (min-width: $breakpoint-reco-large) {
      margin-bottom: 0;
    }

    .content-button {
      margin-bottom: 0.5rem;
      width: 100%;
      display: flex;
      align-items: center;

      .reco-rating,
      .notification-badge {
        margin-left: 0.5em;
        float: none !important;
      }
    }

    .rating-filter,
    .select-filter {
      display: none;

      @media (min-width: $breakpoint-reco-large) {
        display: block;
        margin-bottom: 2.5em;
      }

      &-select {
        margin-bottom: 1em;

        @media (min-width: $breakpoint-reco-large) {
          display: none;
        }
      }
    }
  }

  &-content {
    @media (min-width: $breakpoint-reco-large) {
      padding-left: 2.125em;
    }

    > p {
      font-size: 0.875em;
    }

    .heading5 {
      position: relative;
    }

    &-filter {
      position: absolute;
      right: 0;
      bottom: 0.375em;

      .dropdown {
        top: calc(100% + 0.25rem);
      }
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        padding: 0.875em 1em;
        border: 1px solid var(--color-grey-200);
        border-top: none;
        display: flex;
        align-items: flex-start;

        > .form-check {
          margin-top: 1em;
          margin-right: 0.6em;
        }
      }
    }
  }

  &-loader {
    display: flex;
    min-height: 12.5rem;
  }

  &-toolbar {
    &,
    label {
      color: $gray9;
    }

    &-description {
      padding: 1rem;
      border: 1px solid var(--color-grey-200);
      border-bottom: none;
      font-size: 0.875em;
    }

    &-tools {
      display: flex;
      justify-content: space-between;

      padding: 1rem;
      background-color: var(--color-grey-200);
    }

    .form-select-indicator {
      font-size: $default-font-size;
    }
  }

  &-generation-iframe {
    opacity: 0.1;
    height: 1px;
    width: 1px;
    border: 0;
    margin-top: '-1px';
    position: 'relative';
    display: 'block';
  }

  &-action-bar {
    display: flex;
    background-color: var(--color-grey-200);
    align-items: stretch;
    padding: 1em 1em 0;
    white-space: nowrap;
    flex-wrap: wrap;
    flex-direction: column;
    position: sticky;
    bottom: 0;

    @media (min-width: $breakpoint-portrait-tablet) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }

    .bttn {
      margin-bottom: 1rem;

      @media (min-width: $breakpoint-portrait-tablet) {
        margin-left: 0.65em;
      }
    }

    .loader-circular {
      margin: -0.9375em 0 0;
    }

    &-action {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;

      @media (min-width: $breakpoint-portrait-tablet) {
        align-items: center;
        flex-direction: row;
        padding: 0 0.75em;
      }

      &-row-only {
        flex-direction: row;
        align-items: center;
      }

      label,
      strong {
        font-size: 0.875em;
      }

      label {
        margin-right: 0.5em;
      }

      strong {
        margin-left: 0.5em;
      }

      input {
        width: 4.6em;
      }
    }
  }
}
