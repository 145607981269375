.form-label {
  display: inline-block;
  font-size: $default-font-size;
  font-weight: bold;
  line-height: 1.375rem;
  color: $black;
  margin-bottom: 0.5em;

  &.form-label-full-width {
    width: 100%;
  }

  &.form-label-thin {
    font-weight: 300;
  }
}
