.user-navigation {
  display: flex;
  align-items: center;

  img {
    display: block;
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5em;
  }

  &-name {
    font-size: 0.875em;
    font-weight: 700;
    color: $blue5;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  button {
    margin-left: auto;
    border: none;
    background: none;
    padding: 0.5rem;
    cursor: pointer;
    position: relative;
    
    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;

      svg {
        font-size: 1.25rem;
        color: $blue7;
      }
    }

    &.active {
      > span {
        background: $white;
      }
    }
  }
}