.manifest {
  display: flex;
  justify-content: space-between;
  padding: 4.6875rem 1.25rem 1.25rem;
  background-color: $grey-200;

  @include breakpoint($breakpoint-portrait-tablet) {
    min-height: 730px;
    padding: 1rem 1rem 0;
  }

  .manifest-graphics {
    display: none;
    @include breakpoint($breakpoint-portrait-tablet) {
      display: block;
    }
  }
  .manifest-content {
    max-width: 40rem;
    color: $black;
    align-self: center;
    h2 {
      margin-bottom: 1.5rem;
    }

    .heading4 {
      line-height: 17px;

      @include breakpoint($breakpoint-reco-large) {
        font-size: 18px;
        line-height: 22px;
      }
    }
    .manifest-list {
      margin: 1.5rem 0 1.5rem 0.5rem;
      padding: 0;
      list-style: none;
      li {
        margin-bottom: 1.5rem;
        display: flex;
        gap: 0.5rem;
      }
    }
    .icon {
      color: $grey-500;
    }
    a {
      font-weight: 700;
      text-decoration: none;
    }
    .manifest-list-label {
      font-size: $default-font-size;
    }
  }
}

.manifest-graphics {
  &.manifest-reviews-in-box {
    align-self: flex-end;
  }
}
