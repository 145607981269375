.review-card {
  &--meta {
    display: flex;
    margin-top: 0.75rem;
    font-size: .875em;
    align-items: center;

    > a {
      margin-right: 0.75em;
      color: $blue3;
      @include link-decoration;

      svg {
        margin-right: 0.2em;
      }

      &.js--rev-reply {
        svg {
          font-size: 0.9375rem;
        }
      }
    }
  }

  &--likes {
    align-self: flex-start;
    color: $gray9;
    margin-right: 0.75em;

    svg {
      margin-right: .25em;
      color: $pink2;
    }

    &-hidden {
      display: none;
    }
  }

  &--tags {
    font-size: 0.75rem;
    margin: .5em 0 0;

    ul {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
      flex-wrap: wrap;

      @media (min-width: $breakpoint-small-tablet) {
        margin: 0 0 0 .25em;
      }

      .review-card--tags-tag {
        padding: .125em .5em;
        margin-bottom: 0;
        background-color: $gray11;
        border-radius: 1em;
        margin-right: .5em;
        margin-top: 0;
        white-space: nowrap;
      }
    }
  }
}
