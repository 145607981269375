.shared-inputs-InputField {
  display: grid;
  gap: 0.5rem;

  .field-heading {
    display: flex;
    justify-content: space-between;

    .required-flag {
      color: var(--color-signal-red);
    }

    &.field-heading--lg {
      .label-text {
        font-family: var(--font-serif);
        font-size: 1.25rem;
        margin: 0 0 0.5rem;
      }
    }
  }

  .input-field-container {
    display: grid;
    background-color: var(--color-white);
    border: 1px solid var(--color-grey-400);
    border-radius: 0.25rem;

    input {
      @include font-paragraph-14;

      color: var(--color-black);
      background-color: transparent;
      border: 0;
      border-radius: 0.25rem;
      appearance: none;
      -webkit-appearance: none;
      padding: 0.5625rem 0.5rem;

      &::placeholder {
        color: var(--color-grey-500);
      }

      &:focus {
        outline: none;
      }

      &:focus-visible {
        outline: none;
      }

      &:disabled {
        color: var(--color-grey-500);
        background-color: var(--color-grey-100);
        & + .figure {
          opacity: 0.2;
          background: var(--color-grey-300);
        }
      }
    }

    .figure {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: transparent;
      font-size: 1rem;
      cursor: pointer;
    }

    // States
    &.state-active {
      border-color: var(--color-reco-blue);
    }

    &.state-error {
      border-color: var(--color-signal-red);
    }

    &.has-icon {
      grid-template-columns: auto 2.5rem;
    }
  }

  .help-text {
    color: var(--color-grey-500);
  }
}
