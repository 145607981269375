.tooltip-link {
  display: inline-block;
  text-decoration: underline dotted;
  text-underline-offset: 4px;
  cursor: pointer;

  &.bold {
    font-weight: bold;
  }
  &.small {
    font-size: small;
  }
}
