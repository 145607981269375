.action-bar {
  background-color: $grey-200;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;

  @include breakpoint($breakpoint-small-tablet) {
    flex-direction: row;
    align-items: center;
  }

  &-title {
    margin-bottom: 0.75rem;

    @include breakpoint($breakpoint-small-tablet) {
      margin: 0 auto 0 0;
    }
  }

  &-actions {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
  }
}
