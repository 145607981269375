#header-root {
  z-index: $layer-13;
  position: relative;
  height: 8.5rem;

  @include breakpoint($breakpoint-portrait-tablet) {
    height: 4.5rem;
  }
}

.action,
.link {
  @include _font-paragraph;
  display: flex;
  gap: var(--grid-8);
  line-height: 1.5rem;
  color: inherit;
  text-decoration: none;
  user-select: none;
  border: 0;
  border-radius: var(--border-radius-default);
  align-items: center;
  flex: 0 0 auto;
  transition-property: background, box-shadow;
  transition-duration: 300ms;
  transition-timing-function: ease-out;

  &:not(:disabled) {
    cursor: pointer;
  }
}

.action {
  background: var(--color-grey-100);
  justify-content: center;
  padding: 0.5rem;
  box-shadow: $shadow-sm;

  &:hover {
    background: var(--color-grey-200);
  }

  &:active,
  &.active {
    background: var(--color-grey-300);
    box-shadow: none;
  }
}

.action--large {
  padding: 0.75rem;
}

.link {
  padding: 0.5rem 0.75rem;

  &:hover {
    background: var(--color-grey-300);
  }
}

// reset styling from typography.scss
.link.link:hover {
  text-decoration: none;
}

.icon {
  &--placeholder {
    display: inline-block;
    background: var(--color-reco-blue-light);
    border: 1px solid var(--color-reco-blue);
  }

  &--16 {
    width: 1rem;
    height: 1rem;
  }

  &--24 {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.header {
  position: fixed;
  inset: 0 0 auto;
  display: grid;
  grid-template:
    '. .' 2.5rem
    'search search' 2.5rem;
  gap: var(--grid-24);
  align-items: center;
  padding: var(--grid-16) var(--grid-24);
  background-color: var(--color-white);
  box-shadow: $shadow-lg;

  @include breakpoint($breakpoint-portrait-tablet) {
    grid-template:
      '. search .' 2.5rem
      / minmax(max-content, 1fr) minmax(auto, 30rem) minmax(max-content, 1fr);
  }
}

.primary-actions {
  display: grid;
  grid-template-columns: 2.5rem auto;
  gap: var(--grid-24);

  .logo-wrapper {
    display: flex;
    grid-column: 2;
    justify-self: start;
    align-self: center;

    img {
      max-height: 2rem;
    }
  }
}

.secondary-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--grid-8);
}

.profile-modal-container {
  display: none;
  position: relative;

  .action {
    padding: 0.5rem 0.75rem;
  }

  @include breakpoint($breakpoint-portrait-tablet) {
    display: initial;
  }
}

.search-area {
  grid-area: search;
}

#header-root.header-transparent {
  height: 4.5rem;
}

.header-transparent .header {
  position: absolute;
  background: $light-blue;
  box-shadow: none;
  grid-template: '. .' 2.5rem;

  .search-area {
    display: none;
  }

  @include breakpoint($breakpoint-portrait-tablet) {
    grid-template:
      '. .' 2.5rem
      / minmax(max-content, 1fr) minmax(max-content, 1fr);
  }
}
