.block-s {
  padding: 10px;
}

.block-m {
  padding: $margin-medium;
}

.block-l {
  padding: $margin-medium;
}

.rnd5 {
  overflow: hidden;
  @include border-radius(5px);
}

.block-blue {
  border: 2px solid $dblue;
}

.block {
  display: block;
}

.iblock {
  display: inline-block;
}

.vmid {
  vertical-align: middle;
}
