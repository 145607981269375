/**
 * Adds reset utility classes
 */

.clean-button {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  text-align: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  text-transform: none;
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
  box-sizing: border-box;
  border-width: 0;
  user-select: inherit;
}
