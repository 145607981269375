.lead-form {
  scroll-margin-top: calc(8.5rem + 1rem);

  @include breakpoint($breakpoint-portrait-tablet) {
    scroll-margin-top: calc(4.5rem + 1rem);
  }

  > div {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    @media (min-width: $breakpoint-small-tablet) {
      padding: 1.5rem;
    }
  }

  label,
  ul {
    font-size: 0.875rem;
  }

  form {
    display: flex;
    flex-direction: column;

    .form-control {
      width: 100%;
    }
  }

  label:first-child {
    margin-bottom: 1rem;
    font-weight: 700;
  }

  ul {
    padding: 0;

    li {
      list-style: none;

      svg {
        color: var(--color-signal-green);
      }
    }
  }

  button {
    align-self: center;
    margin-bottom: 1rem;
  }

  small {
    font-size: 0.75rem;
    color: var(--color-grey-500);
  }

  &--error,
  &--success {
    align-self: center;
    text-align: center;
    margin: 0;
  }

  .bttn-content-center {
    width: 100%;
  }
}
