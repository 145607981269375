//squashed root selector
.review-card--report {
  position: absolute;
  background-color: $white;
  top: 1.5em;
  left: 50%;
  border: 1px solid $gray4;
  border-radius: 0.5em;
  width: 15.75em;
  z-index: $layer-11;
  transform: translateX(-50%);

  a {
    @include link-decoration;
  }

  header {
    display: flex;
    align-items: center;
    padding: 1em;

    h5,
    a {
      font-size: 0.875em;
      margin: 0;
    }

    a {
      margin-left: auto;
    }
  }

  &-content {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 1.1428em;
    font-size: 0.875em;
  }

  ul {
    padding: 0;
    border-top: 1px solid $gray4;

    li {
      .profile-main & {
        margin: 0;
      }

      a {
        display: block;
        font-size: 0.875em;
        padding: 1em;

        &:hover {
          background-color: $gray2;
        }
      }
    }
  }
}
