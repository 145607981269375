.date-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    time {
      font-size: 0.875em;
      font-weight: 700;
      color: $black;
      background-color: $light-green;
      padding: 0.25em 1em;
      border-radius: 1em;
    }
  }

  ul {
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 0.5em;
    grid-row-gap: 0.5em;
    justify-items: center;

    li {
      &:nth-child(3n+1) {
        place-self: start;
      }

      &:nth-child(3n+3) {
        place-self: end;
      }
    }
  }
}