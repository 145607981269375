.button {
  @if ($mode == 'critical' or $mode == 'all') {
    display: inline-block;
    height: 40px;
    margin: 0;
    padding: 0 10px;
  }
  @if ($mode != 'critical') {
    color: $button-fg;
    line-height: 40px;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;

    @include transition(0.2s);
    @include box-shadow(1px 1px rgba(255, 255, 255, 0.4) inset);
    @include border-radius(4px);
    @include text-shadow(1px 1px rgba(0, 0, 0, 0.2));
  }
}

.button[disabled] {
  opacity: 0.5;
}

@if ($mode != 'critical') {
  .button-orange {
    @include button($orange);
  }
  .button-pink {
    @include button($pink);
  }
  .button-green {
    @include button($green);
  }
  .button-red {
    @include button($red);
  }
  .button-blue {
    @include button($blue);
  }
  .button-dblue {
    @include button($dblue);
  }
  .button-bluegray {
    @include button(#036d95);
  }
  .button-bankId {
    @include button(#469cbe);
  }

  .button-gray {
    @include button(#eee);
    color: $text-color;
    text-shadow: 1px 1px rgba(255, 255, 255, 0.5);
  }
}

.button-empty {
  border: 1px solid #ccc;
  padding: 0 10px;
  height: 40px;
  display: inline-block;

  @if ($mode != 'critical') {
    background: #fff;
    color: $text-color;
    line-height: 40px;
    vertical-align: middle;
    text-shadow: none;
    -webkit-appearance: none;
    appearance: none;

    @include border-radius(4px);
  }
}

@mixin fbutton($background-color) {
  @if ($mode != 'critical') {
    background-color: $background-color;

    &:hover {
      background-color: shade($background-color, 5%);
    }
    &[disabled]:hover {
      background-color: $background-color;
    }
  }
}

.fbtn {
  @if ($mode == 'critical' or $mode == 'all') {
    display: inline-block;
    padding: 7px 20px;
    margin: 0;
    border: 0;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    -webkit-appearance: none;
    appearance: none;
  }

  @if ($mode != 'critical') {
    @include border-radius(5px);
    @include transition(0.2s);

    &[disabled] {
      opacity: 0.4;
    }
  }

  &-center {
    margin: 0 auto;
  }
}

@if ($mode != 'critical') {
  .fbtn-orange {
    @include fbutton($orange);
  }
  .fbtn-blue {
    @include fbutton($blue);
  }
  .fbtn-dblue {
    @include fbutton($pink);
  }
  .fbtn-green {
    @include fbutton($green);
  }
  .fbtn-red {
    @include fbutton($red);
  }
  .fbtn-redorange {
    @include fbutton($red-orange);
  }
  .fbtn-empty {
    padding: 6px 19px;
    border: 1px solid #ddd;
    background-color: #fff;

    &.black {
      border-color: $text-color;
    }

    &.blue,
    &.blue:hover {
      border-color: #479bd3;
    }

    &:hover {
      border: 1px solid #ccc;
      background-color: #fafafa;
      &.black {
        border-color: $text-color;
      }
    }

    &.fbtn-transparent,
    &.fbtn-transparent:hover {
      background: transparent;
    }
  }
}

@if ($mode == 'critical' or $mode == 'all') {
  .button,
  .fbtn {
    &.button-full {
      width: 100%;
      display: block;
    }
    &.button-hi {
      height: 45px;
      line-height: 45px;
    }
    &.button-slim {
      padding: 0 10px;
      height: 31px;
      line-height: 31px;
    }
    &.button-fat {
      padding: 0 20px;
    }
    &.button-round {
      padding: 10px;
      border-radius: 50%;
      &.xl {
        width: 50px;
        height: 50px;
      }
    }

    &.button-icon {
      display: flex;
      align-items: center;

      svg {
        margin-right: 6px;
      }
    }

    &.button-center {
      display: flex;
      justify-content: center;
    }
  }
}

.search-btn {
  @if ($mode == 'critical' or $mode == 'all') {
    -webkit-appearance: none;
    appearance: none;
    height: 48px;
    border: 0;
    border: 1px solid transparent;
    padding: 0 20px;
    outline: 0;
  }

  @if ($mode != 'critical') {
    color: transparent;
    font-family: sans-serif;
    background: transparent;

    .icon {
      vertical-align: top;
    }

    .icon:before {
      color: #85b6ca;
    }

    @include border-radius(27px);
    @include transition(0.2s);
  }
}

@if ($mode != 'critical') {
  form.focused .search-btn {
    background-color: $blue;
    color: #fff;
    border-right: 1px solid darken($blue, 10%);
    border-bottom: 1px solid darken($blue, 10%);

    &:hover {
      background-color: darken($blue, 5%);
    }

    .icon:before {
      color: #fff;
    }
  }
}

// legacy
// mixin `bttn` is conflicting with this declaration
// @todo refactor to usage of the `bttn` mixin
.btn {
  border: 1px solid transparent;
  border-radius: 0.4rem;
  cursor: pointer;
  display: block;
  line-height: 1.3rem;
  padding: 0.4rem 0.8rem;
  position: relative;
  text-decoration: none;
  text-align: center;

  &.iblock {
    display: inline-block;
  }

  &.btn--blue {
    background-color: $blue;
    border-color: $blue;
    color: #fff;
  }

  &.btn--pink {
    background-color: $pink;
    border-color: $pink;
    color: #fff;
  }

  &.btn--gray {
    background-color: #777;
    border-color: #777;
  }

  &.btn--ghost {
    background: transparent;

    &.btn--blue {
      color: $blue;
    }

    &.btn--pink {
      color: $pink;
    }

    &.btn--gray {
      color: #777;
    }
  }

  &:not([disabled]):after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    pointer-events: none;
    background-color: transparent;
    transition: background-color 0.1s linear;
  }

  &[disabled]:before {
    position: absolute;
    left: -1px;
    top: -1px;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    content: ' ';
    border: 1px solid rgba(255, 255, 255, 0.5);
  }

  &:not([disabled]):hover:after {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.bttn {
  @include bttn;
}

.bttn-group {
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    font-size: 0.875em;
    font-weight: 300;
  }

  .bttn,
  label {
    margin: 0 0.25rem;
  }

  &.bttn-group-ghost {
    .bttn,
    label {
      margin: 0 1rem;
      padding-left: 0;
      padding-right: 0;
      font-weight: 400;
    }

    .bttn:hover {
      font-weight: 700;
    }

    .bttn-active {
      font-weight: 700;
    }
  }
}

button:not(.link) {
  font-size: 14px;
}

button.link {
  cursor: pointer;
  border: none;
  background: unset;
  padding: unset;
  color: $blue3;

  &:focus-visible {
    outline: auto;
  }
}
