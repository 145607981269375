.shared-inputs-Checkbox {
  display: inline-flex;
  align-items: center;
  gap: var(--grid-8);

  .checkbox-container {
    position: relative;

    input {
      display: block;
      height: 1.5rem;
      width: 1.5rem;
      background-color: var(--color-white);
      border-radius: var(--border-radius-small);
      border: 1px solid var(--color-grey-400);

      // @todo investigate if we can extract shadows systematically to reflect the design system
      box-shadow: inset 0 0 0.25rem rgba(0, 0, 0, 0.25);

      margin: 0;
      appearance: none;
      outline: none;
      cursor: pointer;

      &:disabled {
        cursor: default;

        + .check-mark {
          cursor: default;
          color: var(--color-grey-400);
        }
      }
    }

    .check-mark {
      position: absolute;
      left: 50%;
      top: 50%;
      font-size: 1rem;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }

  .label-text {
    cursor: pointer;
    &.disabled {
      cursor: default;
      color: var(--color-grey-400);
    }
  }
}
