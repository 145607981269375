.bg-sky {
  background-color: $bg-sky;
}
.bg-gray {
  background-color: $bg-gray;
}
.bg-gray1 {
  background-color: $gray1;
}
.bg-gray2 {
  background-color: $gray2;
}
.bg-lgray {
  background-color: $bg-lgray;
}
.bg-dgray {
  background-color: $bg-dgray;
}
.bg-white {
  background-color: #fff;
}
.bg-bluegray {
  background-color: #505555;
}
.bg-bp-bluegray {
  background-color: #234955;
}
.bg-lightgrayishorange {
  background-color: #fcf5e0;
}
.bg-lblue {
  background-color: #e3f0ff;
}
.bg-lgreen {
  background-color: #f2fcf2;
}
.bg-cc {
  background-color: lighten(#f9efd4, 5%);
}
.bg-cc-d {
  background-color: #f9efd4;
}
.bg-redorange {
  background-color: #f26348;
}
.bg-gray-hvr {
  @include transition(background-color 0.2s ease-in-out);
  &:hover {
    background-color: $bg-gray;
  }
}

.bg-arrow {
  position: relative;
  border-top: 2px solid #ccc;
}
.bg-arrow:before {
  position: absolute;
  content: ' ';
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 0 20px 20px 20px;
  top: -20px;
  left: 50%;
  margin-left: -20px;
  z-index: $layer-9;
  border-color: transparent transparent #ffffff transparent;
}

.bg-arrow:after {
  position: absolute;
  content: ' ';
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 0 22px 22px 22px;
  top: -22px;
  left: 50%;
  margin-left: -22px;
  z-index: $layer-8;
  border-color: transparent transparent #ccc transparent;
}
