@import '../modules/hero/index';
@import '../modules/categories/index';
@import '../modules/manifest/index';
@import '../modules/try-reco/index';
@import '../modules/popular-companies/index';
@import '../modules/articles/index';

@import '../../../javascripts/components/NewCompanies/new-companies';

$indexpage-container-max-width: 71.5rem;
$indexpage-container-padding: 1.25rem;

.page-index {
  background-color: $white;

  .manifest-wrapper,
  .popular-companies-wrapper,
  .new-companies-wrapper {
    &::before,
    &::after {
      @include delimiter;
    }

    &::after {
      transform: rotate(180deg);
    }
  }

  .container-categories {
    max-width: $indexpage-container-max-width;
    padding: $indexpage-container-padding 0;
    margin: 1.5rem auto 3.25rem;

    @include breakpoint($breakpoint-portrait-tablet) {
      margin-bottom: 4.5rem;
    }
  }

  .container-try-reco {
    max-width: $indexpage-container-max-width;
    margin: 1.5rem auto 6rem;

    @include breakpoint($breakpoint-portrait-tablet) {
      margin: 9rem auto 8rem;
    }
  }

  .container-try-reco {
    padding: $indexpage-container-padding;
  }

  .container-articles {
    padding: 6.25rem $indexpage-container-padding 4.125rem;
  }

  .new-companies-wrapper {
    &:after {
      position: absolute;
    }
  }

  .container-top-ten {
    max-width: $indexpage-container-max-width;
    margin: 1.5rem auto 6rem;

    @include breakpoint($breakpoint-portrait-tablet) {
      margin: 9rem auto 8rem;
    }
  }
  .top-ten {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
    padding: 1rem;
    place-items: center;

    @media only screen and (max-width: 600px) {
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr;
      .link {
        order: -1;
      }
    }
  }

  .top-ten-description {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .podium {
    display: grid;
    place-items: center;
  }

  .categories-content {
    margin: 0 1rem;
    .category-list {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 50%));
      gap: 1rem 1.375rem;
      padding: 0;
      margin: 0;
      list-style: none;

      @include breakpoint($breakpoint-small-tablet) {
        grid-template-columns: repeat(4, 1fr);
      }

      .region-information-category {
        @include bttn(true, true);
        justify-content: flex-start;
        background-color: $white;
        padding: 0.5rem;
        .category-label {
          font-weight: 700;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
