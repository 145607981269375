:root {
  --auto-slider-duration: 10s;
  --auto-slider-direction: 'ltr';
}

$slide-gap: 1.5rem;

@mixin auto-slider($isMultiple: false, $slide-gap-row-adjustment: 0px) {
  @keyframes slide {
    from {
      transform: translate3d(var(--auto-slider-direction-from), 0, 0);
    }
    to {
      transform: translate3d(var(--auto-slider-direction-to), 0, 0);
    }
  }

  @if $isMultiple == true {
    & {
      margin-bottom: calc((#{$slide-gap} - #{$slide-gap-row-adjustment}) * -1);
    }
    .auto-slider-container {
      margin-bottom: calc(#{$slide-gap} - #{$slide-gap-row-adjustment});
    }
  }

  .auto-slider-container {
    display: inline-flex;
    overflow: hidden;
    white-space: nowrap;

    .auto-slider-content-wrapper {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      animation: slide var(--auto-slider-duration) linear infinite;

      .auto-slider-slide-entity-container {
        display: inline-block;

        .auto-slider-slide-entity {
          display: flex;
          gap: $slide-gap;
          margin-right: $slide-gap;

          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
}

.auto-slider-init {
  @include auto-slider;
}
