/*
 * The media object.
 *
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/
 */
.media,
.mbody {
  overflow: hidden;
}

.mleft {
  float: left;
}
.mright {
  float: right;
}

@media only screen and (max-width: $breakpoint-reco) {
  .mleft.bp-r,
  .mright.bp-r {
    float: none;
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-small-tablet) {
  .mleft.bp-st,
  .mright.bp-st {
    float: none;
    margin-left: 0;
    margin-right: 0;
  }
}
