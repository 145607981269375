.organization {
  font-size: 16px; // Can be removed when body font-size is changed to 16px
  background-color: $white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-large-tablet) {
    flex-direction: row;
  }

  main {
    width: 100%;
    padding: 1.5em 1em;
    flex: 1;
    display: flex;

    @media (min-width: $breakpoint-large-tablet) {
      padding: 3em;
      // Make room for fixed navigation
      padding-left: 18.125em;
    }

    .heading1 {
      display: block;
    }

    > div {
      width: 100%;
    }
  }

  &-loader {
    display: flex;
    min-height: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
}
