@import './company-card';

.new-companies {
  --carousel-next-fade-color: #{$grey-200};

  padding: 6.5rem 0;
  background-color: $grey-200;
  text-align: center;
  h2 {
    @include heading-underline($inline: true);
  }
  .companies {
    text-align: initial;
  }
}
