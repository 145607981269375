//squashed root selector
.review-card--reviewer-person {
    display: flex;
    align-items: center;

    &-image {
      margin-right: 0.75em;
      align-self: flex-start;

      .icon,
      img {
        width: 2em;
        height: 2em;
        border-radius: 50%;
      }
    }

    &-info {
      font-size: 0.75em;
      font-weight: 300;
      color: $gray9;

      .top {
        display: flex;
        flex-flow: column;
        font-size: 1em;

        @media (min-width: $breakpoint-small-tablet) {
          align-items: center;
          flex-wrap: wrap;
          flex-flow: row;
        }

        a {
          margin-right: 0.25em;
          font-weight: 700;
          font-size: 0.875rem;
          color: $black;
        }
      }

      h5,
      p {
        margin: 0;
      }

      a {
        text-decoration: underline;
        color: $blue3;
      }
    }
  }
