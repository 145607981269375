.results-badge-container {
  float: right;
  position: relative;
}

.results-year-badge-container {
  position: absolute;
  float: left;
  margin-left: 5px;
  width: 50px;
  height: 70px;
  top: -38px;
  right: 70px;

  @media only screen and (max-width: 860px) {
    top: -40px;
    right: 53px;
  }
}

.results-obj--badge {
  width: 70px;
  height: 70px;
  border: 1px solid #ccc;
  border-radius: 100%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);

  img {
    height: 100%;
    width: 100%;
  }
}

.results-tip-container {
  margin-top: -23px;
  position: relative;
  width: 100%;
}

.results-light-ad {
  padding: 20px;
  background-color: white;
  border: 1px solid $gray13;
  border-radius: 5px;
  z-index: $layer-8;
  margin: 1em 0 0;
  color: $black;
}

.results-light-ad-rating {
  float: left;

  @media only screen and (min-width: $breakpoint-tablet) {
    padding: 5px;
  }
  @media only screen and (max-width: $breakpoint-tablet) {
    max-width: 200px;
    padding: 0px;
  }
  @media only screen and (max-width: $breakpoint-small-mobile) {
    max-width: 150px;
    padding: 0px;
  }
}

.results-ad-icon {
  width: 20px;
  height: 20px;
  background: url(/assets/images/icons/reco-menu-lightup.svg) no-repeat center
    center;
  background-size: 20px 17px;
  display: inline-block;
  vertical-align: middle;
  margin-right: .5em;
}

.results-tip {
  position: absolute;
  margin-left: 50%;
  left: -74px;
  top: -19px;
  padding: 0.5625em 0.6875em;
  background-color: #ffffff;
  box-sizing: border-box;
  border: 1px solid $gray13;
  border-radius: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.results-ad-tips {
  font-style: normal;
  font-size: .875em;
  line-height: 1;
  vertical-align: middle;
  font-weight: 700;
}

.results-light-ad:hover {
  .light-ad-button {
    opacity: 1;
  }
}

.light-ad-button {
  position: absolute;
  top: -7px;
  right: 0px;
  opacity: 0;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;

  @media only screen and (max-width: $breakpoint-tablet) {
    position: relative;
    top: 0px;
    margin-top: 10px;
    opacity: 1;
  }
}

.placements {
  &-wrapper {
    min-height: 150px;
    background: linear-gradient(to bottom, white 7%, #fbfbfb 7%);

    @media (min-width: $breakpoint-small-tablet) {
      background: linear-gradient(to bottom, white 7.6%, #fbfbfb 7.6%);
    }

    @media (min-width: $breakpoint-large-tablet) {
      background: linear-gradient(to bottom, white 8.4%, #fbfbfb 8.4%);
    }

    @media (min-width: $breakpoint-tablet) {
      background: linear-gradient(to bottom, white 10%, #fbfbfb 10%);
    }
  }

  &--item {
    &-badge {
      width: 70px;
      height: 70px;
      margin: 0 0 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-inner {
      border-top: 1px solid #ddd;
    }
  }
}
