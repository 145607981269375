.loader {
  background: url(/assets/images/loader.gif) no-repeat center center;
}

.loaderm {
  height: 200px;
}

.loaders {
  height: 100px;
}

.loader-circular {
  position: relative;
  margin: 0 auto;
  display: inline-block;

  &.s {
    width: 35px;
  }

  &.xs {
    width: 20px;
  }

  &.m {
    width: 60px;
  }

  &.l {
    width: 100px;
  }

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.loader-circular-svg {
  animation: loader-rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.loader-circular.default .loader-circular-path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: loader-dash 1.5s ease-in-out infinite, loader-color 6s ease-in-out infinite;
  stroke-linecap: round;
}

.loader-circular.loader-white .loader-circular-path {
  stroke: #ffffff;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: loader-dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes loader-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes loader-color {
  100%,
  0% {
    stroke: #51a7d8;
  }
  40% {
    stroke: #c3da4a;
  }
  66% {
    stroke: #ed2281;
  }
  80%,
  90% {
    stroke: #f9c63c;
  }
}
