.venue-response {
  display: flex;
  color: black;

  img,
  &--image {
    width: 4.375em;
    height: 3.125em;
    border: 2px solid rgba($gray3, 0.5);
    margin-right: .5em;
  }

  &--image {
    background-size: 100%;
    border: none;
  }

  &--content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      margin-bottom: $margin-medium / 2;
    }

    h3, h4 {
      margin-bottom: 0.25em;
    }
  }

  &--rating {
    display: flex;
    align-items: center;
    font-size: 0.875em;

    .reco-rating {
      margin: 0 0.571em 0 0;
      flex: 1 0 auto;

      strong {
        font-weight: 700;
      }
    }
  }

  &--sm {

    margin-top: 1em;

    .reco-rating.rxs {
      width: auto;
    }
  }

  &--md {
    align-items: center;

    img {
      width: 4.375em;
      height: 3.125em;
    }

    .venue-response--rating {
      font-size: .875em;
      flex-direction: column;
      align-items: flex-start;
    }

    .reco-rating {
      margin: 0 0 0.375em 0;
    }

    .venue-response--image,
    img {
      width: 4.375em;
      height: 3.125em;
    }
    
  }

  &--lg {
    align-items: center;

    .venue-response--content {
      @media (min-width: $breakpoint-small-tablet) {
        padding-bottom: 0.25em;
      }
    }

    .venue-response--image,
    img {
      width: 8.625em;
      height: 6.25em;
    }

    .venue-response--rating {
      font-size: 1em;
      flex-direction: column;
      align-items: flex-start;

      @media (min-width: $breakpoint-small-tablet) {
        flex-direction: row;
        align-items: center;
      }

      .reco-rating {
        margin: 0 0 0.7em 0;

        @media (min-width: $breakpoint-small-tablet) {
          margin: 0 1em 0 0;
        }
      }
    }
  }
}
