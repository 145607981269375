.stats-header {
  .stats-header-content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    @include breakpoint($breakpoint-portrait-tablet) {
      flex-direction: row;
      align-items: center;
      column-gap: 1rem;
    }
  }

  .stats-header-description {
    color: $grey-500;
  }

  .stats-header-value-container,
  .stats-header-trend-container {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .stats-header-yellow {
    color: $reco-yellow;
  }

  .stats-header-trend {
    color: $reco-blue;
  }

  .stats-header-trend-negative {
    color: $signal-red;

    svg {
      transform: rotate(135deg);
    }
  }

  .stats-header-trend-positive {
    color: $signal-green;

    svg {
      transform: rotate(45deg);
    }
  }

  .heading2 {
    margin-bottom: 0;
    color: $reco-blue;

    small {
      display: block;
    }
  }
}
