.review-card--response {
  display: none;

  .review-card--responses-unfolded & {
    display: block;
  }

  border-radius: 0.25em;
  padding: 1.5em;
  margin-top: 0.75em;
  background-color: $gray12;
  padding: 1em;
  margin-left: 1.5em;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: -1.125em;
    top: 0.3125em;
    width: 0.75em;
    height: 0.75em;
    border-left: 2px solid $gray4;
    border-bottom: 2px solid $gray4;
    border-bottom-left-radius: 2px;
  }

  q {
    display: block;
    font-size: 0.875em;
    line-height: 1.57;
    text-align: left;
    word-break: break-word;
    color: $black;

    &:before,
    &:after {
      display: none;
    }
  }

  &-name {
    margin-top: 0.6em;
    font-weight: 700;
    font-size: 0.875em;
    color: $black;
    display: block;
  }

  &-actions {
    display: flex;

    a {
      margin: 1em 0.5em;
    }
  }
}
