.wrapper {
  display: grid;
  background-color: #fff
}

.header-wrapper {
  display: grid;
  grid-template-areas:
    'header-legend header-legend header-legend header-badges'
    'header-legend header-legend header-legend header-badges'
    'header-info header-info header-info header-info';
}

@media screen and (max-width: $breakpoint-large-tablet) {
  .header-wrapper {
    display: grid;
    grid-template-areas:
      'header-legend header-legend'
      'header-legend header-legend'
      'header-info header-info'
      'header-badges header-badges';
  }
}

.header-legend {
  grid-area: header-legend;
}

.pg--employee-header { min-height: 66px; padding-left: 200px; }
  #pg--employee-logo img { position: absolute; left: 40px; top: -24px; border-radius: 100%; box-shadow: 0 1px 1px rgba(0,0,0,0.1); }

@media screen and (max-width: $breakpoint-large-tablet) {
  .pg--employee-header { padding-left: 140px; }
  #pg--employee-logo img { left: 20px; width: 130px; height: 130px; top: -24px; }
}

.header-badges {
  .tooltip {
    position: relative;

    &:hover .bttn {
      display: block;
    }

    .bttn {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.badge {
  width: 100px;
  height: 100px;
}

.header-info {
  grid-area: header-info;
}

.info-wrapper {
}

.info-desc {
  grid-area: info-desc;
}

.info-stats {
  align-self: center;
}

.info-stats-a {
  grid-area: info-stats-a;
}

.info-stats-b {
  grid-area: info-stats-b;
}

.circle {
  height: 90px;
  width: 90px;
  min-width: 90px;
  display: table-cell;
  margin: 0 auto;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
}

.leads-form {
  display: grid;
  grid-template-columns: 30% 30% 40%;
  background-color: $bg-lgreen;
}

.wide {
  grid-column-end: span 2;
}

.reviews-wrapper {
  display: grid;
  grid-template-areas:
  'review-filter review-filter review-filter review-filter review-filter'
  'reviews reviews reviews sold-estates sold-estates';
}

.review-filter {
  grid-area: review-filter;
}

.reviews {
  grid-area: reviews;
  display: flex;
}

#review-list .actions {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  > div {
    margin: 0 20px 10px 20px;
  }

  @media (min-width: $breakpoint-small-tablet) {
    flex-direction: row;
  }

  a {
    margin-left: 6px;
  }
}

.sold-estates {
  grid-area: sold-estates;
  display: flex;
}

@media screen and (max-width: $breakpoint-large-tablet) {
  .sold-estates  {
    display: none;
  }
}