.review-card--rating {
  display: flex;
  align-items: center;
  margin: 1rem 0 0.5rem;
  flex-wrap: wrap;
  gap: 0.5rem;

  a {
    text-decoration: none;
  }

  .submit-date {
    font-size: 0.75rem;
    color: var(--color-grey-500);
    line-height: 1.5rem;
    margin-left: auto;
  }
}
