#organization-root .dialog-backdrop {
  top: 0;
}

.dialog {
  &-backdrop {
    top: 8.5rem;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: $layer-14;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    @include breakpoint($breakpoint-portrait-tablet) {
      top: 4.5rem;
    }
  }

  &-paper {
    display: flex;
    flex-direction: column;
    background-color: $white;
    max-height: calc(100% - 4em);
    margin: 1em;
    padding: 1.5em;
    border-radius: 0.5em;
    overflow-y: auto;

    &.dialog-sm {
      max-width: 20.625em;
    }

    &.dialog-md {
      max-width: 25.875em;
    }

    &.dialog-lg {
      max-width: 39.375em;
      width: 100%;
    }

    &.dialog-xl {
      max-width: 43.75rem;
      width: 100%;
    }
  }

  &-title {
    .heading3 {
      width: 100%;
      text-align: center;
      margin-bottom: 1em;
    }

    &.with-close {
      .heading3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      button {
        margin-left: var(--grid-8);
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    color: $black;

    p,
    &-row {
      font-size: 0.875rem;
      margin: 0 0 1.25rem;

      p {
        margin: 0;
      }
    }

    ul {
      padding-left: 1.25rem;
      margin: 0 0 1.25rem;
    }

    &-meta {
      font-size: 0.875em;
      margin-top: 1.25rem;
      text-align: center;
    }

    .form-check + .form-check {
      margin-top: 1em;
    }
  }
}
