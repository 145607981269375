article.page-opt-out {
  padding: 2rem;
  background-color: $white;
  @include shadow;

  h2 {
    @include heading-underline;
    width: 100%;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  button {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  @include breakpoint($breakpoint-portrait-tablet) {
    max-width: 31.25rem;
    margin: 4rem auto 6rem;
    border-radius: 0.5rem;
    padding: 3rem;
  }
}

section.optout {
  text-align: center;
  margin-bottom: 3rem;

  input[type='checkbox'] {
    width: 2rem;
    height: 2rem;
    margin: 1rem auto 0 auto;
    display: grid;
    place-content: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 1.3rem;
    height: 1.3rem;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 2rem 2rem #3096D0;
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  input[type='submit'] {
    @include bttn(true, $hasShadow: false);
  }

  .recaptcha-wrapper {
      margin: 1rem 0;
      display: flex;
      justify-content: center;
    }
}
