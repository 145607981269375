$arrowSize: 0.75rem;

.shared-AttentionBox {
  display: grid;
  column-gap: 1rem;
  grid-template-rows: min-content 1fr;
  position: relative;

  padding: 1rem;
  border-radius: var(--border-radius-default);
  background-color: var(--color-grey-300);

  ::before {
    border-bottom-color: var(--color-grey-300);
  }

  &.attentionBox-green {
    background-color: var(--color-reco-green-light);

    ::before {
      border-bottom-color: var(--color-reco-green-light);
    }
  }

  &.attentionBox-red {
    background-color: var(--color-reco-pink-light);

    ::before {
      border-bottom-color: var(--color-reco-pink-light);
    }
  }

  &.attentionBox-yellow {
    background-color: var(--color-reco-yellow-light);

    ::before {
      border-bottom-color: var(--color-reco-yellow-light);
    }
  }
  &.attentionBox-blue {
    background-color: var(--color-reco-blue-light);

    &::before {
      border-bottom-color: var(--color-reco-blue-light);
    }
  }

  &.arrow {
    margin-top: $arrowSize;

    ::before {
      content: '';
      position: absolute;
      top: -$arrowSize;
      left: 1rem;
      width: 0;
      height: 0;
      border-bottom-width: $arrowSize;
      border-bottom-style: solid;
      border-right: $arrowSize solid transparent;
      border-left: $arrowSize solid transparent;
    }
  }

  &.arrow-bottom-left {
    margin-top: 0;
    margin-bottom: $arrowSize;

    ::before {
      transform: rotate(180deg);
      top: auto;
      bottom: -$arrowSize;
    }
  }

  img {
    display: block;
    object-fit: contain;
    object-position: top;

    width: 40vw;
    height: 100%;
    max-width: 12rem;

    grid-row: 1 / end;
    grid-column: 1/2;
  }

  .attention-box-header {
    display: flex;
    justify-content: space-between;
    grid-row: 1;
    gap: 1rem;

    h4 {
      @include heading4;
      word-break: break-word;

      margin-bottom: 0.5rem;
      @include breakpoint($breakpoint-reco) {
        margin-bottom: 0.6875rem;
      }
    }
  }

  .attention-box-content {
    grid-row: 2;

    a {
      @include link-decoration;
    }

    ul {
      padding-left: 1.25rem;
    }
  }

  .attention-box-dismiss {
    min-width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius-default);
    font-size: 1rem;

    cursor: pointer;
  }
}
