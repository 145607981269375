/**********
 * BUTTON
 **********/

.felt-btn {
  --btn-outer-shadow: rgba(0, 0, 0, 0.15);
  --btn-inner-shadow: rgba(0, 0, 0, 0);
  --btn-ring-shadow: rgba(0, 0, 0, 0);

  font: inherit;
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5rem;
  box-shadow: 0 1px 3px var(--btn-outer-shadow), inset 0 0 4px var(--btn-inner-shadow),
    inset 0 0 0 1px var(--btn-ring-shadow);
  text-decoration: none;
  border: none;
  border-radius: 0.5rem;
  background-color: var(--color-grey-100);
  color: inherit;
  align-items: center;
  transition-property: background, box-shadow, color;
  transition-duration: 300ms;
  transition-timing-function: ease-out;
  cursor: pointer;
  text-align: left;

  &:hover {
    background: var(--color-grey-200);
  }

  &:active,
  &.active {
    background: var(--color-grey-300);
    --btn-outer-shadow: transparent;
  }

  &:disabled,
  &.disabled {
    background-color: var(--color-grey-200);
    color: var(--color-grey-400);
    // font-weight: bold;
    pointer-events: none;

    &:hover,
    &:active,
    &.active {
      background-color: inherit;
    }

    .notification {
      opacity: 0.3;
    }
  }

  > b,
  > strong {
    color: var(--color-reco-blue);
  }

  :is(.felt-icon, .felt-badge):is(:last-child) {
    margin-left: auto;
    margin-right: -0.5rem;
  }

  :is(.felt-icon, .felt-badge):is(:first-child) {
    margin-left: -0.5rem;
  }

  :is(.felt-icon, .felt-badge) + :is(.felt-icon, .felt-badge) {
    margin-left: -0.25rem;
  }
}

.felt-btn--primary {
  background-color: var(--color-reco-blue);
  color: var(--color-white);
  font-weight: 500;

  &:hover {
    background: var(--color-signal-blue);
  }

  &:active,
  &.active {
    background: var(--color-signal-blue);
    box-shadow: none;
  }
}

.felt-btn--secondary {
  background-color: transparent;
  box-shadow: none;
}

.felt-btn--toggle {
  flex: 0 0 auto;
  border-radius: 999px;
}

.felt-btn--xs {
  padding: 0 0.5rem;
  font-size: 0.75rem;
  gap: 0.25rem;

  :is(.felt-icon, .felt-badge):is(:last-child) {
    margin-right: -0.25rem;
  }

  :is(.felt-icon, .felt-badge):is(:first-child) {
    margin-left: -0.25rem;
  }
}

input:checked + .felt-btn--toggle,
.felt-btn--toggle:is(:active, .active) {
  --btn-outer-shadow: transparent;
  --btn-inner-shadow: rgba(0, 0, 0, 0.25);
  --btn-ring-shadow: var(--color-grey-400);
  background: var(--color-grey-300);
}

/**********
 * ICON
 **********/

.felt-icon {
  display: inline-grid;
  place-items: center;
  flex: 0 0 auto;
  font-size: 1rem;
  width: 1.5rem;
  height: 1.5rem;
}

.felt-icon--lg {
  width: 2rem;
  height: 2rem;
  border-radius: 999px;
}

/**********
 * BADGE
 **********/

.felt-badge {
  display: inline-block;
  flex: 0 0 auto;
  padding: 0 0.375rem;
  text-align: center;
  font-weight: 500;
  border-radius: 999px;
  line-height: 1.25rem;
  min-width: 1.25rem;
  font-size: 0.75rem;
  letter-spacing: -0.03;
  background-color: var(--color-grey-400);
  color: var(--color-grey-500);
}

.felt-badge--primary {
  background-color: var(--color-reco-pink);
  color: var(--color-white);
}

.felt-badge--secondary {
  background-color: transparent;
  color: var(--color-black);
}

/**********
 * PILL
 **********/

.felt-pill {
  display: inline-block;
  padding: 0.5rem 1rem;
  flex-grow: 0;
  border-radius: 1.25rem;
  background-color: var(--color-reco-green-light);
}

/**********
 * CHIP
 **********/

.felt-chip {
  display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1.5rem;
  gap: 0.25rem;
  padding: 0 0.5rem;
  flex: 0 0 auto;
  border-radius: 999px;
  background-color: var(--color-grey-300);

  .felt-icon {
    font-size: 0.875rem;
    width: 1.25rem;
    height: 1.25rem;
  }

  :is(.felt-icon, .felt-badge):is(:last-child) {
    margin-left: auto;
    margin-right: -0.375rem;
  }

  :is(.felt-icon, .felt-badge):is(:first-child) {
    margin-left: -0.375rem;
  }

  :is(.felt-icon, .felt-badge) + :is(.felt-icon, .felt-badge) {
    margin-left: -0.25rem;
  }
}

/**********
 * LINK
 **********/

.felt-link {
  font: inherit;
  line-height: 1.5rem;
  background: none;
  border: none;
  text-decoration: none;
  padding: 0;
  cursor: pointer;
  color: var(--color-reco-blue);
  transition: color 150ms ease-out;

  &:hover {
    color: var(--color-signal-blue);
  }
}

/**********
 * CHECKBOX
 **********/

.felt-checkbox {
  flex: 0 0 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  appearance: none;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25), inset 0 0 0 1px var(--color-grey-400);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background 150ms ease-out;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 11'%3E %3Cpath d='M13.781 1.219a.747.747 0 0 1 0 1.06l-8.25 8.252a.747.747 0 0 1-1.06 0L.22 6.281a.75.75 0 0 1 1.06-1.06l3.718 3.72 7.722-7.722a.747.747 0 0 1 1.06 0h.002Z' fill='%23000'/%3E %3C/svg%3E");
  background-position: center;
  background-size: 0;
  background-repeat: no-repeat;

  &:checked {
    background-size: 14px;
  }
}
