.table-empty {
  border: 1px solid $gray7;
  border-top: none;
  padding: 1em;
  text-align: center;
  margin-bottom: 1.5em;

  @media (min-width: $breakpoint-reco) {
    padding: 9em;
  }
}