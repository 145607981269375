.pg-brk {
  display: inline-block;
  vertical-align: middle;
  padding: 5px 11px;
}

.pg-brk, .pg-cur, .pg-link {
  margin: 2px 4px;
}

.pg-cur {
  display: inline-block;
  vertical-align: middle;
  padding: 5px 11px;
  background: #3a5b66;
  color: #fff;
  @include border-radius(3px);
}

.pg-link {
  border: 1px solid #ddd;
  padding: 4px 10px;
  display: inline-block;
  vertical-align: middle;
  background: #fff;
  color: #3a5b66;
  @include border-radius(3px);

  &:hover {
    background: #f2f2f2;
  }
}

@media screen and (max-width: 600px) {
  .pg-pn {
    display: inline-block;
    padding: 5px 10px;
  }

  .pg-brk, .pg-cur, .pg-link {
    margin: 4px;
  }

  .pg-brk {
    padding: 5px;
  }
}

