/**
* [Design system] Color scheme
*/

// Shades
$black: #000000;
$grey-600: #484848;
$grey-500: #787878;
$grey-400: #d6d6d6;
$grey-300: #ebebeb;
$grey-200: #f5f6f5;
$grey-100: #fbfbfb;
$white: #ffffff;

// Reco Color
$reco-green: #c3da4a;
$reco-pink: #ed2281;
$reco-blue: #3096d0;
$reco-yellow: #f9c63c;

// Complementary light shades
$reco-green-light: #eef1db;
$reco-pink-light: #fae4ee;
$reco-blue-light: #e1ecf2;
$reco-yellow-light: #ffedbe;

// Signal colors
$signal-green: #47c645;
$signal-red: #ee4b35;
$signal-blue: #336886;
$signal-orange: #ff9900;

// Brand colors
$brand-facebook: #405992;

// @todo remove when legacy colors are refactored
@import 'legacy/colors';

// Aliases
$link-color: $reco-blue;
