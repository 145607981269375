.stats-reviews {
  display: grid;
  gap: var(--grid-24);
  width: 100%;
  height: fit-content;
}

.stats-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.stats-nav {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1.5em;
}

.stats-box {
  border-radius: 0.5rem;
  border: 1px solid $grey-300;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  background-color: $white;

  &.stats-box-shadow {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  }
}

.stats-actions {
  position: relative;
}

.stats-datepicker {
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 50%;
  transform: translateX(-50%);
  width: 21.5rem;
  z-index: 15;

  @include breakpoint($breakpoint-portrait-tablet) {
    right: 2.546875rem;
    transform: none;
    left: auto;
  }
}

.stats-two-columns {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @include breakpoint($breakpoint-landscape-tablet) {
    flex-direction: row;
    justify-content: space-between;
  }

  .rating-list {
    @include breakpoint($breakpoint-landscape-tablet) {
      width: 50%;
    }
  }

  .stats-chart {
    @include breakpoint($breakpoint-landscape-tablet) {
      width: 40%;
    }
  }
}

.stats-chart {
  height: 22.25rem;

  &.stats-chart-adjusted {
    @include breakpoint($breakpoint-portrait-tablet) {
      width: calc(100% + 2rem);
      margin-left: -2rem;
    }
  }
}

.stats-loader {
  display: flex;
  height: 22.25rem;
}

.stats-widget-loader {
  display: flex;
  justifyContent: center;
  alignItems: center;
}
