.prepared-invites-preview {
  strong {
    font-size: 0.875em;
    font-weight: 700;
  }

  &-recipients {
    display: flex;
    flex-wrap: wrap;

    strong {
      display: block;
      margin-right: 1.625rem;
      margin-top: 0.8em;
    }

    .bttn {
      margin-bottom: 1em;
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  p {
    font-size: 0.875em;
  }

  &-message {
    background-color: $gray12;

    &-inner {
      display: flex;
      flex-direction: column;
      line-height: 1.57;
      max-width: 44rem;
      margin: 0 auto;
      text-align: center;

      iframe {
        height: 40rem;
      }
      
      @media (min-width: $breakpoint-small-tablet) {
        padding-top: 3rem;
        padding-bottom: 3rem;
      }
    }
  }

  &-actions {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-small-tablet) {
      align-items: center;
      flex-direction: row;
    }

    &.prepared-invites-preview-actions-loading {
      .bttn {
        &:last-of-type {
          @media (min-width: $breakpoint-small-tablet) {
            margin-left: 0;
          }
        }
      }

      .loader-circular {
        order: 4;

        @media (min-width: $breakpoint-small-tablet) {
          order: 0;
          margin-right: 1.5em;
          margin-left: auto;
        }
      }
    }

    > * {
      margin-bottom: 1rem;

      @media (min-width: $breakpoint-small-tablet) {
        margin-bottom: 0;
      }
    }

    .bttn {
      &:first-of-type {
        order: 2;

        @media (min-width: $breakpoint-small-tablet) {
          order: 0;
        }
      }

      &:last-of-type {
        order: 3;

        @media (min-width: $breakpoint-small-tablet) {
          order: 0;
          margin-left: auto;
        }
      }
    }

    .form-check {
      order: 1;

      @media (min-width: $breakpoint-small-tablet) {
        order: 0;
        margin-left: auto;
        margin-right: 1rem;
      }
    }
  }
}