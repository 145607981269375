$soft-shadow-spread: 3px;

.popular-companies {
  padding: 6.5rem 0;
  background-color: $grey-200;
  overflow-x: hidden;
  text-align: center;

  h2 {
    @include heading-underline($inline: true);
  }

  .companies {
    text-align: left;

    @include auto-slider(true, $soft-shadow-spread);

    .company {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto 1fr;
      gap: 0.5rem;
      padding: 1rem;

      border-radius: 0.5rem;
      background-color: $white;

      @include shadow;
      // consider moving the margin into the `shadow mixin`, but be aware of different box models/display modes etc.
      margin: 0 0 $soft-shadow-spread;

      h3 {
        font-weight: 300;
        grid-column: span 2;
        margin: 0;
        font-size: $default-font-size;
        overflow: hidden;
        text-overflow: ellipsis;
        a {
          color: $grey-600;
          text-decoration: none;
        }
      }

      .company-rating-text {
        color: $grey-600;
        white-space: nowrap;
      }
    }
  }
}
