article.page-login {
  padding: 2rem;
  background-color: $white;
  @include shadow;

  h2 {
    @include heading-underline;
    margin-bottom: 1rem;
  }

  button {
    margin-bottom: 1rem;
  }

  @include breakpoint($breakpoint-portrait-tablet) {
    max-width: 31.25rem;
    margin: 4rem auto 6rem;
    border-radius: 0.5rem;
    padding: 3rem;
  }
}

section.login {
  text-align: center;
  margin-bottom: 3rem;

  input[type='submit'] {
    @include bttn(true, $hasShadow: false);
  }
}

section.signup,
section.sso-login {
  padding-bottom: 3rem;
  border-bottom: 2px solid $grey-200;
  margin-bottom: 3rem;
}

section.signup {
  text-align: center;

  form {
    fieldset {
      border: 0;
      padding: 0;
      margin: 0;
    }

    input[type='submit'] {
      @include bttn(true);
      color: $grey-600;
    }
  }
  .agreement {
    text-align: initial;
  }

  .recaptcha-wrapper {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
  }

  .form-control-inline > div {
    width: 100%;
  }

  .msg-red {
    margin: 0.5rem 0;
  }
}

section.sso-login {
  text-align: center;
  h4 {
    font-weight: 300;
    margin: 0 0 1rem;
  }
  a {
    @include bttn(true, true);
    background-color: $brand-facebook;
    color: $white;
    font-weight: 700;
    .icon {
      font-size: 1rem;
      line-height: 1;
    }
  }
}

section.partner {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 0.5rem;

  img {
    grid-row: span 3;
    border-radius: 0.5rem;
  }
  h3,
  p {
    margin: 0;
  }
  a {
    grid-column: 2;
  }
}
