.p0 {
  padding: 0;
}
.b0 {
  border: 0;
}
.m0 {
  margin: 0;
}

.ptbm {
  padding: $margin-medium 0;
}
.ptbs {
  padding: $margin-medium/2 0;
}
.rlpm {
  padding: 0 $margin-medium;
}
.bmm {
  margin: 0 0 $margin-medium 0;
}
.bml {
  margin: 0 0 $margin-medium * 2 0;
}
.bpm {
  padding-bottom: $margin-medium;
}
.bpxl {
  padding-bottom: $margin-medium * 3;
}
.tp2 {
  padding-top: 2px;
}
.tp6 {
  padding-top: 6px;
}
.tp20 {
  padding-top: 20px;
}
.bps {
  padding-bottom: $margin-medium / 2;
}
.btm {
  margin: $margin-medium 0 0 0;
}
.bms {
  margin: 0 0 $margin-medium / 2 0;
}
.tms {
  margin-top: $margin-medium / 2;
}
.tm6 {
  margin-top: 6px;
}
.tmm {
  margin-top: $margin-medium;
}
.tml {
  margin-top: $margin-medium * 2;
}
.bm0 {
  margin: 0;
}
.bm6 {
  margin-bottom: 6px;
}
.bm8 {
  margin-bottom: 8px;
}
.bm10 {
  margin-bottom: 10px;
}
.bmxl {
  margin: 0 0 $margin-medium * 3 0;
}
.pl {
  padding: $margin-medium * 2;
}
.pm {
  padding: $margin-medium;
}
.ps {
  padding: $margin-medium/2;
}
.pls {
  padding-left: $margin-medium / 2;
}
.plm {
  padding-left: $margin-medium;
}
.pml {
  padding: $margin-medium * 2;
}
.prm {
  padding-right: $margin-medium;
}
.prs {
  padding-right: $margin-medium / 2;
}
.p6 {
  padding: 6px;
}

/* Adds medium margin to right */
.mrl {
  margin-right: $margin-medium * 2;
}
.mrm {
  margin-right: $margin-medium;
}
.mrsm {
  margin-right: $margin-medium / 2;
}
.mrs {
  margin-right: 6px;
}

/* Adds medium margin to left (for example for media object right part) */
.mls {
  margin-left: 6px;
}
.ml9 {
  margin-left: 9px;
}
.mlm {
  margin-left: $margin-medium;
}

/* Centered max width parts */
.mw245 {
  max-width: 245px;
  margin-left: auto;
  margin-right: auto;
}
.mw380 {
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}
.mw500 {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.mw600 {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.mw960 {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}

.w-full {
  width: 100%;
}

@media screen and (min-width: $breakpoint-small-tablet) {
  .mw245-bpr-st {
    max-width: 245px;
  }
}

@media screen and (max-width: $breakpoint-small-tablet) {
  body .m0-st {
    margin: 0;
  }
}

@media screen and (max-width: $breakpoint-large-tablet) {
  .bp-mrm {
    margin-right: $margin-small;
  }
}
