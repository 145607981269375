.outer-wrap {
  padding: 1rem;
  max-width: 71rem;
  margin: 0 auto;

  @media (min-width: $breakpoint-small-tablet) {
    padding: 1.5rem;
  }
}

/* Weep for mankind... We need the important override. :'( */
.grid {
  margin-left: -20px !important;

  &.grid-rtl {
    margin-left: 0px !important;
    margin-right: -20px !important;

    .gc {
      padding-right: $margin-medium;
      float: right;
    }
  }
}

.grid-flex {
  @extend .grid;
  display: flex;
}

.gc {
  padding-left: $margin-medium;
  float: left;
}

@for $gindex from 1 through 12 {
  .g#{$gindex} {
    width: 100% / 12 * $gindex;
  }
}

@for $spindex from 1 through 12 {
  .gs#{$spindex} {
    margin-left: 100% / 12 * $spindex;
  }

  .grid-rtl .gs#{$spindex} {
    margin-right: 100% / 12 * $spindex;
  }
}

@for $gindex from 1 through 12 {
  .g#{$gindex} {
    width: 100% / 12 * $gindex;
  }
}

@for $spindex from 1 through 12 {
  .gs#{$spindex} {
    margin-left: 100% / 12 * $spindex;
  }

  .grid-rtl .gs#{$spindex} {
    margin-right: 100% / 12 * $spindex;
  }
}

/* Breakpoint reco size */
@media only screen and (max-width: $breakpoint-reco) {
  .bp-r {
    > .gc,
    &.grid-rtl > .gc {
      float: none;
      padding-left: 0;
      width: auto;
    }

    &.grid-rtl > .gc,
    > .gc {
      margin-left: 0;
      margin-right: 0;
    }

    &.grid {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .bp-r-flex {
    @extend .bp-r;
    flex-direction: column;
  }

  .bp-r-flex-reverse {
    @extend .bp-r;
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: $breakpoint-large-tablet) {
  .bp-lt {
    > .gc {
      float: none;
      padding-left: 0;
      width: auto;
    }

    &.grid-rtl > .gc,
    > .gc {
      margin-left: 0;
      margin-right: 0;
    }

    &.grid {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

/* Breakpoint small tablet */
@media only screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-small-tablet) {
  .bp-st {
    > .gc {
      float: none;
      padding-left: 0;
      width: auto;
    }

    &.grid-rtl > .gc,
    > .gc {
      margin-left: 0;
      margin-right: 0;
    }

    &.grid {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

/* Breakpoint mobile */
@media only screen and (max-width: $breakpoint-mobile) {
  .bp-m {
    > .gc {
      float: none;
      padding-left: 0;
      width: auto;
    }

    &.grid-rtl > .gc,
    > .gc {
      margin-left: 0;
      margin-right: 0;
    }

    &.grid {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

@mixin flex-breakpoint($maxWidth, $className) {
  @media only screen and (max-width: $maxWidth) {
    &.bp-#{$className} {
      display: block;

      &.vgut6 > .col + .col {
        margin-top: 6px;
      }

      &.vgut10 > .col + .col {
        margin-top: 10px;
      }

      &.vgut20 > .col + .col {
        margin-top: 20px;
      }

      &.vgut40 > .col + .col {
        margin-top: 40px;
      }

      @for $gindex from 1 through 12 {
        > .col-#{$gindex}-12 {
          flex-basis: auto;
          max-width: 100%;
        }

        > .col-#{$gindex}-offset {
          margin-left: 0;
        }
      }
    }
  }
}

@mixin flex-breakpoint-reverse($minWidth, $className) {
  @media only screen and (min-width: $minWidth) {
    &.rev-bp-#{$className} {
      display: block;

      &.vgut6 > .col + .col {
        margin-top: 6px;
      }

      &.vgut10 > .col + .col {
        margin-top: 10px;
      }

      &.vgut20 > .col + .col {
        margin-top: 20px;
      }

      &.vgut40 > .col + .col {
        margin-top: 40px;
      }

      @for $gindex from 1 through 12 {
        > .col-#{$gindex}-12 {
          flex-basis: auto;
          max-width: 100%;
        }

        > .col-#{$gindex}-offset {
          margin-left: 0;
        }
      }
    }
  }
}

@mixin withGut($key, $size) {
  &.gut#{$key} {
    margin-left: #{-$size / 2};
    margin-right: #{-$size / 2};
    > .col {
      padding-left: #{$size / 2};
      padding-right: #{$size / 2};
    }
  }
}

/* Flex grid */
.row {
  display: flex;

  &.wrap {
    flex-flow: row wrap;
  }

  &.bottom {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  &.top {
    -webkit-box-align: start;
    -webkit-align-items: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  &.center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: flex-center;
  }
  &.ac-space-between {
    -webkit-align-content: space-between;
    -ms-align-content: space-between;
    -o-align-content: space-between;
    align-content: space-between;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  @include withGut('6', 6px);
  @include withGut('10', 10px);
  @include withGut('20', 20px);
  @include withGut('40', 40px);
  @include withGut('60', 60px);
  @include withGut('80', 80px);

  &.reverse {
    flex-direction: row-reverse;
  }

  &.vertical {
    flex-direction: column;
  }

  @include flex-breakpoint(960px, 'r');
  @include flex-breakpoint($breakpoint-reco-large, 'rl');
  @include flex-breakpoint($breakpoint-large-tablet, 'lt');
  @include flex-breakpoint(($breakpoint-small-tablet + $breakpoint-large-tablet)/2, 'mt');
  @include flex-breakpoint($breakpoint-small-tablet, 'st');
  @include flex-breakpoint($breakpoint-mobile, 'm');
  @include flex-breakpoint($breakpoint-small-mobile, 'sm');
  @include flex-breakpoint-reverse(960px, 'r');
  @include flex-breakpoint-reverse($breakpoint-reco-large, 'rl');
  @include flex-breakpoint-reverse($breakpoint-large-tablet, 'lt');
  @include flex-breakpoint-reverse($breakpoint-small-tablet, 'st');
  @include flex-breakpoint-reverse($breakpoint-mobile, 'm');
  @include flex-breakpoint-reverse($breakpoint-small-mobile, 'sm');
}

.col {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;

  &.top {
    -webkit-align-self: flex-start;
    -ms-align-self: flex-start;
    align-self: flex-start;
  }

  &.bottom {
    -webkit-align-self: flex-end;
    -ms-align-self: flex-end;
    align-self: flex-end;
  }

  &.center {
    -webkit-align-self: center;
    -ms-align-self: center;
    align-self: center;
  }

  &.center-content {
    align-content: center;
    justify-content: center;
  }
}

@for $gindex from 1 through 12 {
  .col-#{$gindex}-12 {
    flex-basis: 100% / 12 * $gindex;
    max-width: 100% / 12 * $gindex;
  }

  .col-#{$gindex}-offset {
    margin-left: 100% / 12 * $gindex;
  }
}
