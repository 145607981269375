.venue-reviews-write-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 4.5rem;
  column-gap: 0.5rem;
  row-gap: 4.5rem;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    max-height: 5.5rem;
  }
}

.venue-reviews-write-rating-wrapper {
  display: flex;
  margin: calc(var(--rating-gap) * -1);

  &:hover .venue-reviews-write-rating {
    background-image: url('/assets/images/rating/rating-4.svg');
  }
}

.venue-reviews-write-rating {
  height: var(--rating-size);
  aspect-ratio: 1;
  padding: var(--rating-gap);
  border: 0;
  box-sizing: content-box;
  background-color: transparent;
  background-image: url('/assets/images/rating/rating-0.svg');
  background-size: var(--rating-size);
  background-origin: content-box;
  background-clip: content-box;
  outline-width: 2px;
  outline-offset: -1px;
  cursor: pointer;
}

.venue-reviews-write-rating:hover ~ .venue-reviews-write-rating {
  background-image: url('/assets/images/rating/rating-0.svg');
}
