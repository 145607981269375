.venue-badges-hgroup {
  flex-direction: row;
  gap: 1rem;

  p {
    color: var(--color-reco-blue);
  }
}

.venue-badges-list {
  display: flex;
  flex-direction: row-reverse;
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: center;
  margin-right: -1rem;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    margin-right: -1.5rem;
  }
}

.venue-badges-item {
  flex: 0 1 6rem;
  height: 5rem;
  width: 0;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    flex-basis: 9rem;
    height: 7.5rem;
  }

  &--groupStart {
    flex-shrink: 0;
  }

  img {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.16));
    height: 100%;
  }
}
