.badge-presenter {
  margin: 0;
  border: 1px solid $gray4;
  border-radius: 0.5rem;
  background-color: $gray12;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &-actions {
    position: absolute;
    right: 1rem;
    top: 1rem;
    display: flex;

    button {
      margin-left: 0.5rem;
    }

    @media (min-width: $breakpoint-portrait-tablet) {
      display: none;
    }
  }

  &:hover {
    .badge-presenter-actions {
      display: flex;
    }
  }

  &-image {
    flex: 1;
    display: grid;
    place-items: center;
    padding: 1rem;

    img {
      max-width: 10.375em;
      width: 100%;
    }
  }

  figcaption {
    padding: 1.175rem 1.5rem;
    background-color: $white;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    .heading3 {
      margin: 0;
    }
  }
}
