.date-picker {
  font-family: $font-default;
  font-weight: 300;

  button {
    font-family: $font-default;
    font-weight: 300;
    background: none;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
  }

  nav {
    display: block;
    background-color: $grey-200;
    padding: 0.25rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-between;

    button {
      font-size: 0.75rem;
      border: 1px solid transparent;
      padding: 0.275rem 0.45rem;

      &.active,
      &:hover {
        background-color: $white;
        border: 1px solid $grey-400;
      }
    }
  }

  hr {
    border: none;
    border-top: 1px solid $grey-300;
    margin: 1.25rem 0;
  }

  .date-picker-content {
    padding-top: 1.25rem;
  }
}

.date-picker-calendar-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.375rem;

  button {
    padding: 0.5rem 1rem;

    &:first-child {
      margin-left: -0.5rem;
    }

    &:last-child {
      margin-right: -0.5rem;
    }

    &:disabled {
      color: #d6d6d6;
    }
  }
}

.date-picker-list {
  list-style: none;
  margin: 0;
  padding: 0;

  button {
    width: 100%;
    text-align: left;
    padding: 0.5rem;
    font-size: 0.875rem;
    color: $grey-600;
    display: flex;
    justify-content: space-between;

    span {
      display: block;
      width: 27%;

      &:first-child {
        width: 19%;
      }
    }

    &.active,
    &:not(:disabled):hover {
      background: $grey-300;
    }

    &:disabled {
      color: $grey-400;
      cursor: default;

      .date-picker-month-simple li {
        color: $grey-400;
      }
    }
  }

  &.date-picker-list-small {
    button {
      font-size: 0.75rem;
    }
  }

  &.date-picker-list-wrapped {
    display: flex;
    flex-wrap: wrap;

    > li {
      width: 33.33333333%;
      max-width: 7.25rem;

      button {
        display: block;
        text-align: center;
      }
    }
  }
}

.date-picker-range {
  .date-picker-range-dates {
    display: flex;
    gap: 1em;

    > div {
      width: 50%;
    }
  }
}

.date-picker-calendar-week {
  list-style: none;
  padding: 0;
  margin-top: 0.375rem;

  div,
  button {
    width: 100%;
    text-align: left;
    padding: 0.5rem;
    font-size: 0.875rem;
    display: flex;
    justify-content: space-between;

    span {
      display: block;
      width: 27%;
      text-align: center;

      &:first-child {
        width: 19%;
      }
    }
  }

  div {
    color: $black;
  }

  button {
    color: $grey-600;

    &.active,
    &:not(:disabled):hover {
      background: $grey-300;
    }

    &:disabled {
      color: $grey-400;
      cursor: default;

      .date-picker-month-simple li {
        color: $grey-400;
      }
    }
  }
}

.date-picker-calendar-month {
  display: flex;
  flex-wrap: wrap;
  padding: 0.375rem 0 0 0;

  li {
    list-style: none;
    width: 14.28571429%;
    text-align: center;
    color: $grey-600;
    font-size: 0.5625rem;
  }
}

.date-picker-mini {
  display: inline-flex;
  min-width: 19.25rem;

  .date-picker-mini-previous,
  .date-picker-mini-next,
  .date-picker-mini-date {
    border: none;
    background: none;
    appearance: none;

    &:not(:disabled) {
      cursor: pointer;
    }
  }

  .date-picker-mini-previous,
  .date-picker-mini-next {
    color: $reco-blue;
    padding: 0 1rem;

    &:disabled {
      color: $grey-400;
    }
  }

  .date-picker-mini-date {
    background: $grey-100;
    box-shadow: 0px 1px 3px rgba($black, 0.15);
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 300;
    color: $grey-600;
    padding: 0.65rem 1rem;
    font-family: $font-default;
    flex: 1;
    text-align: center;

    svg {
      margin-left: 0.675rem;
    }

    &:hover {
      background-color: $reco-blue;
      color: $white;
    }
  }
}
