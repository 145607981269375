.estate-card {
  display: flex;
  color: $gray5;
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid $gray1;

  &--image {
    width: 8.25em;
    height: 6em;
    border-radius: 0.25rem;
    margin-right: 1em;
    background-size: 100% auto;
    background-position: center center;
  }

  &--content {
    display: flex;
    flex-direction: column;
    font-size: 0.875em;
    justify-content: center;
    width: calc(100% - 14em);

    h4 {
      font-size: 1em;
      margin: 0 0 0.2857em;
    }

    &-size {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.2857em;
    }

    &-city {
      margin: 0 0 0.2857em;
    }

    &-price {
      strong {
        display: block;
      }
    }
  }
}
