.footer-base {
  margin-top: -0.5rem;

  &::before {
    @include delimiter($grey-600);
  }

  .content-wrapper {
    background-color: $grey-600;
  }

  .content {
    padding: 3rem 1.25rem 1.5rem;
    box-shadow: none;
    margin: 0;

    .footer-links {
      h4,
      a {
        color: $white;
      }

      h4 {
        font-size: 18px;
        line-height: 1.22;
        margin-bottom: 0.5rem;
      }

      a {
        display: inline-block;
        margin-bottom: 0.5rem;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        display: flex;
        flex-flow: column;
        margin-bottom: 3rem;

        &.social-links {
          a {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            line-height: 1.5rem;
          }
        }
      }

      @include breakpoint($breakpoint-small-tablet) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
      }

      @include breakpoint($breakpoint-reco) {
        width: 60%;
      }

      @include breakpoint($breakpoint-landscape-tablet) {
        grid-template-columns: repeat(4, 1fr);
        width: auto;
        max-width: $page-max-width;
        margin: 0 auto;
      }
    }

    .customer-reviews-regions-links {
      border-top: 1px solid $grey-500;
      border-bottom: 1px solid $grey-500;
      padding: 1.5rem 0;
      width: 100%;
      text-align: center;

      h4 {
        color: $grey-400;
        margin: 0 0 1.5rem;

        @include breakpoint($breakpoint-landscape-tablet) {
          margin-bottom: 0;
        }
      }
      ul {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
        list-style: none;
        margin: 0;
        padding: 0;
        a {
          color: $grey-400;
        }
      }

      @include breakpoint($breakpoint-landscape-tablet) {
        display: flex;
        gap: 0.5rem;
        justify-content: center;
      }
    }

    .logo-tagline {
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-top: 1.5rem;
      padding: 1.5rem 0;

      color: $grey-400;
      img {
        height: 1.5rem;
        margin-bottom: 0.5rem;
      }
    }

    @include breakpoint($breakpoint-landscape-tablet) {
      max-width: none;
      padding-left: 0;
      padding-right: 0;
      margin: auto 3rem;
    }
  }
}
