.notification {
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  &:hover {
    background-color: #fafafa;
  }

  &:last-of-type {
    border-bottom: 0;
  }
}
