@import 'forms/form-check';
@import 'forms/form-control';
@import 'forms/form-label';
@import 'forms/form-select';
@import 'forms/validation';

@import 'forms/legacy/form-legacy';

.hr {
  color: transparent;
  height: 1px;
  background-color: $gray11;
  margin: 1.5em 0;
  border: none;
}
