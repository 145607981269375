.obj--logo {
  display: inline-block;
  width: 138px;
  height: 104px;
  background-color: #fff;
  padding: 2px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);

  img {
    height: 100%;
    width: 100%;
  }

  @media only screen and (max-width: 860px) {
    width: 96px;
    height: 70px;
  }
}

#language-switcher-flags {
  float: right;
}

#year-badge-container {
  position: relative;
  float: left;
  margin-left: 10px;
  height: 110px;
  width: 100px;
  -webkit-transition: width 1s;
  transition: width 1s;

  @media only screen and (max-width: 860px) {
    margin-left: 5px;
    width: 50px;
    height: 70px;
  }
}

#year-badge-container:hover {
  @media only screen and (min-width: 860px) {
    width: 450px;
  }
}
