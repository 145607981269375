.try-reco {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;

  @include breakpoint($breakpoint-portrait-tablet) {
    flex-direction: row-reverse;
  }
  picture {
    max-width: 24rem;
    width: calc(100% - 2rem);
    filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.08));
  }
  img {
    width: 100%;
  }
  .try-reco-textblock {
    h2 {
      margin-bottom: 1rem;
      @include breakpoint($breakpoint-reco) {
        @include _font-heading-32;
        margin-bottom: 1rem;
      }
    }
    a {
      font-weight: 700;
      text-decoration: none;
    }
  }
}
