.reviews {
  display: grid;
  gap: var(--grid-24);
  height: fit-content;


  > p {
    font-size: 0.875em;

    &:first-child {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 2em;
    }
  }

  .heading5 {
    &.heading-underline {
      display: block;
    }

    small {
      font-size: 1em;
      font-weight: 300;
    }
  }

  &-main {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-reco-large) {
      display: grid;
      grid-template-columns: 10.375em 1fr;
    }
  }

  &-sidebar {
    margin-bottom: 2em;

    @media (min-width: $breakpoint-reco-large) {
      margin-bottom: 0;
    }

    .content-button {
      margin-bottom: 0.5rem;
      width: 100%;
      display: flex;
      align-items: center;

      .reco-rating,
      .notification-badge {
        margin-left: 0.5em;
        float: none !important;
      }
    }

    select {
      width: 100%;
    }

    .rating-filter {
      display: none;

      @media (min-width: $breakpoint-reco-large) {
        display: block;
      }

      &-select {
        @media (min-width: $breakpoint-reco-large) {
          display: none;
        }
      }
    }
  }

  &-content {
    @media (min-width: $breakpoint-reco-large) {
      padding-left: 2.125em;
    }

    > p {
      font-size: 0.875em;
    }

    .heading5 {
      position: relative;
    }

    &-filter {
      position: absolute;
      right: 0;
      bottom: 0.375em;

      .dropdown {
        top: calc(100% + 0.25rem);
      }
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        margin-bottom: 0.875em;
        padding-bottom: 0.875em;
        border-bottom: 1px solid $gray11;

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }

  .date-range-picker {
    margin-bottom: 2rem;

    button {
      width: 100%;
    }
  }

  &-loader {
    display: flex;
    min-height: 12.5rem;
  }

  .review-card--response {
    max-width: 43.75em;
    display: block;
  }
}

.review-card {
  max-width: 46.875em;

  &--reply {
    width: 100%;
    max-width: 45.25em;

    form {
      &:before {
        content: '';
        position: absolute;
        left: -1.125em;
        top: 0.3125em;
        width: 0.75em;
        height: 0.75em;
        border-left: 2px solid $gray4;
        border-bottom: 2px solid $gray4;
        border-bottom-left-radius: 2px;
      }
    }
  }
}
