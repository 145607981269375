$control-margin: 1.25rem;

.widget-controls {
  &.widget-controls-horizontal {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-landscape-tablet) {
      flex-direction: row;
    }

    > div {
      &:first-child {
        margin-bottom: $control-margin;
      }

      @media (min-width: $breakpoint-landscape-tablet) {
        width: 50%;

        &:first-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &.widget-controls-vertical > div:first-child {
    margin-bottom: $control-margin;
  }

  label {
    display: block;
    font-weight: 700;
    margin: $control-margin 0 0.5rem;

    &:first-child {
      margin-top: 0;
    }
  }

  textarea {
    min-height: 7.25rem;

    @media (min-width: $breakpoint-landscape-tablet) {
      min-height: 6.25rem;
    }
  }

  p {
    color: $black;
    font-size: 0.875em;
  }

  .bttn {
    width: 100%;
  }
}
