.profile-card {
  display: flex;
  color: black;

  &--image {
    width: 3em;
    height: 3em;
    border-radius: 50%;
    margin-right: 0.5rem;
    background-size: 100%;

    &-thick {
      border: 2px solid rgba($gray3, 0.5);
    }

    &-rect {
      border-radius: 0.25em;
      border: none;
      height: 2.3em;
      padding: 0.125em;
      box-shadow: 0px 0px 0.25em rgba(0, 0, 0, 0.2);
    }

    &-border {
      border: 1px solid $gray4;
      align-self: flex-start;
    }
  }

  &--content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      margin-bottom: $margin-medium / 2;
    }

    h3, h4 {
      margin-bottom: 0.25em;
    }

    .trust-state {
      margin-left: 0.375em;
      height: 1.1625em;
    }
  }

  &--group {
    margin-top: 0.6em;
    font-size: 0.875em;

    a {
      color: $black;
      @include link-decoration;
    }
  }

  &--rating {
    display: flex;
    align-items: center;
    font-size: 0.875em;

    .reco-rating {
      margin: 0 0.571em 0 0;
      // flex: 1 0 auto;

      strong {
        font-weight: 700;
      }
    }
  }

  &--xs {
    .profile-card--image {
      width: 2em;
      height: 2em;
      margin-right: 0.625em;
    }

    .profile-card--rating {
      display: none;
    }
  }

  &--sm {
    .reco-rating.rxs {
      width: auto;
    }
  }

  &--md {
    align-items: center;

    .profile-card--rating {
      font-size: 0.875em;
      flex-direction: column;
      align-items: flex-start;
    }

    .reco-rating {
      margin: 0 0 0.375em 0;
    }

    .profile-card--image,
    img {
      width: 4em;
      height: 4em;

      &-rect {
        height: 3.05em;
      }
    }
  }

  &--lg {
    align-items: center;

    .profile-card--content {
      @media (min-width: $breakpoint-small-tablet) {
        padding-bottom: 0.25em;
      }

      h1 {
        @media (min-width: $breakpoint-small-tablet) {
          font-size: 2em;
        }
      }
    }

    .profile-card--image {
      width: 6em;
      height: 6em;
      margin-right: 1.25rem;

      &-rect {
        width: 5.48em;
        height: 4.1875em;

        @media (min-width: $breakpoint-small-tablet) {
          width: 9em;
          height: 6.875em;
        }
      }
    }

    .profile-card--rating {
      font-size: 1em;
      flex-direction: column;
      align-items: flex-start;

      @media (min-width: $breakpoint-small-tablet) {
        flex-direction: row;
        align-items: center;
      }

      .reco-rating {
        margin: 0 0 0.7em 0;

        @media (min-width: $breakpoint-small-tablet) {
          margin: 0 1em 0 0;
        }
      }
    }
  }
}
