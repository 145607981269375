.picture-gallery {
  width: 100%;

  button {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    display: none;

    @media (min-width: $breakpoint-reco-large) {
      display: block;
    }
  }

  .glide {
    &__track {
      height: 100%;

      .glide__slides {
        height: 100%;
        margin: 0;
      }
    }

    &__arrows {
      position: absolute;
      top: 50%;
      width: 100%;
      height: 3rem;
    }

    &__arrow {
      position: absolute;
      width: 3rem;
      height: 3rem;
      background: none;
      border: none;
      color: $white;
      transform: translateY(-50%);

      svg {
        font-size: 2rem;
      }
    }

    &__arrow--left {
      left: 1rem;
    }

    &__arrow--right {
      right: 1rem;
    }
  }

  &--item {
    height: 100%;
    position: relative;

    a {
      display: block;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &.picture-gallery--item-placeholder {
      div {
        width: 100%;
        height: 100%;
        background-color: $gray4;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gray11;

        svg {
          font-size: 4rem;
        }
      }
    }
  }
}
