@mixin carousel-control() {
  .carousel-control {
    position: absolute;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    width: $carousel-control-width;
    @include breakpoint($breakpoint-reco-large) {
      width: $carousel-control-width-large;
    }

    &.control-previous {
      left: 0;
    }

    &.control-next {
      right: 0;
    }

    button {
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 0.5rem;
      border: none;
      background-color: $white;
      cursor: pointer;
      @include shadow();
    }
  }
}
