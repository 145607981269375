/**
 * Adds outer margin scoped to relevant breakpoints
 *
 * @todo understand if we want to configure this to not always affect top and bottom margins
 */
@mixin margin-outer {
  margin: var(--grid-24);

  @include breakpoint($breakpoint-reco) {
    margin: var(--grid-48);
  }
}
