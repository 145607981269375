.clfx {
  overflow: hidden;

  &:before,
  &:after {
    clear: both;
    content: ' ';
    display: table;
  }
}

.oh {
  overflow: hidden;
}

/* Old school clear, when overflow: hidden doesn't cut it. */
.os-clear {
  &:before,
  &:after {
    clear: both;
    content: ' ';
    display: table;
  }
}
