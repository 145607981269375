.booking-form {
  scroll-margin-top: calc(8.5rem + 1rem);

  @include breakpoint($breakpoint-portrait-tablet) {
    scroll-margin-top: calc(4.5rem + 1rem);
  }

  &-inner {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    @media (min-width: $breakpoint-small-tablet) {
      padding: 1.5rem;
    }
  }

  &-spacer {
    display: flex;
    margin: 1.5rem 0;
  }

  &-cancel {
    margin-top: 1rem;
  }

  &-actions {
    display: flex;
    justify-content: center;

    button {
      margin-right: 1.5rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-fields {
    > h4 {
      color: var(--color-black);
      text-align: center;
    }

    form {
      display: flex;
      flex-direction: column;
    }

    &.booking-form-fields-error {
      p {
        text-align: center;
        margin-top: 0;
      }
    }

    form > .loader-circular {
      margin-top: 0.5rem;
    }

    a svg {
      margin-right: 0.5rem;
    }
  }

  &-date-select {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    > span {
      font-weight: 300;
      color: var(--color-black);
      display: block;
      margin-bottom: 1rem;
    }
  }

  &-confirmation {
    margin-bottom: 1.25rem;

    img {
      display: block;
      margin: 0 auto 1.375rem;
    }

    p {
      text-align: center;
      color: var(--color-black);
    }
  }

  &-ticket {
    padding: 2rem 1rem 1.75rem;
    background: var(--color-grey-200);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: calc(100% + 1rem);
      height: 1rem;
      left: -0.5rem;
      background: url('/assets/images/circle.svg');
    }

    &:before {
      top: -0.5rem;
    }

    &:after {
      bottom: -0.5rem;
    }

    > a {
      text-decoration: none;

      a:hover {
        text-decoration: underline;
      }
    }

    .heading3 {
      margin-bottom: 0;
    }

    ul {
      padding: 0;
      list-style: none;
      border-bottom: 2px solid $gray11;
      width: 100%;
      padding-bottom: 0.5rem;

      li {
        display: flex;
        justify-content: space-between;
        padding: 0.5em 0;

        strong {
          font-weight: 700;
        }

        span {
          text-align: right;
        }
      }
    }
  }

  .loader-circular {
    margin-top: 0;
  }
}
