// @todo make sure we load the right font weights here.
// @todo consolidate the way we load fonts, eg. use @font-face for all fonts.
@import url(https://fonts.googleapis.com/css?family=Roboto:100,400,700,300,500);
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700');

@font-face {
  font-family: 'bitstream-cooper-md';
  src: url('/assets/fonts/Bitstream-Cooper-Md-BT-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

$mode: 'all';

@import 'lib/compass-mixins/lib/compass';

$svg-gradient-shim-threshold: 100;

* {
  @include box-sizing(border-box);
}

@import './css_variables.scss';

@import './modules/utils/reset';
@import 'config/index';
@import 'typography/index';
@import 'layout/index';
@import 'mixins/index';

@import 'modules/utils/_tablegrid';
@import 'modules/utils/_grid';
@import 'modules/utils/_clearfix';
@import 'modules/utils/_display';
@import 'modules/admin/index';
@import 'modules/_alert';
@import 'modules/_buttons';
@import 'modules/_felt';
@import 'modules/_content-button';
@import 'modules/header/index';
@import 'modules/_inputs';
@import 'modules/_badge-presenter';
@import 'modules/_date-range-picker';
@import 'modules/_text';
@import 'modules/_dropdown';
@import 'modules/_forms';
@import 'modules/_filter';
@import 'modules/_backgrounds';
@import 'modules/_rainbow';
@import 'modules/_rating';
@import 'modules/_button-bar';
@import 'modules/_media';
@import 'modules/_map';
@import 'modules/_lists';
@import 'modules/_loader';
@import 'modules/_picture-gallery';
@import 'modules/_popup';
@import 'modules/_estate-card';
@import 'modules/_profile-card';
@import 'modules/_booking-form';
@import 'modules/_lead-form';
@import 'modules/_venue-response-card';
@import 'modules/_entity-list';
@import 'modules/_image-order';
@import 'modules/_claim-ownership';
@import 'modules/_review-card';
@import 'modules/_review-card-v2';
@import 'modules/_alternativeListing';
@import 'modules/_trust-state';
@import 'modules/_icons';
@import 'modules/_messages';
@import 'modules/_send-invites';
@import 'modules/_send-invites-form';
@import 'modules/_notifications';
@import 'modules/_autocomplete';
@import 'modules/_blocks';
@import 'modules/_objectpage';
@import 'modules/_searchresult';
@import 'modules/_adminbar';
@import 'modules/_pagination';
@import 'modules/_datepicker';
@import 'modules/_usermenu';
@import 'modules/_dimensions';
@import 'modules/_grid-css';
@import 'modules/_table';
@import 'modules/_typography';
@import 'modules/_share';
@import 'modules/_ad-listings';
@import 'modules/_venue-history-card';
@import 'modules/_review-list';
@import 'modules/_text-clamp';
@import 'modules/footer/index';
@import 'modules/_date-select';
@import 'modules/_lead-cta';
@import 'modules/_organization';
@import 'modules/_navigation';
@import 'modules/_navigation-bar';
@import 'modules/_navigation-popup';
@import 'modules/_notification-badge';
@import 'modules/_user-navigation';
@import 'modules/_profile-badges';
@import 'modules/_table-toolbar';
@import 'modules/_table-pagination';
@import 'modules/_table-empty';
@import 'modules/_table-loading';
@import 'modules/_snackbar';
@import 'modules/search/index';
@import 'modules/_box';
@import 'modules/_dialog';

/*
*--------------------------------------------------------------------------------
* Component oriented stylesheets
*--------------------------------------------------------------------------------
* @todo - find a way to manage the "ugly" relative pathing in this segment
* @todo - can we move this segment to a separate file or further organize it here?
*/

// - Shared stylesheets
@import '../../src/shared/styles/index';

// - Admin stylesheets
@import '../../src/admin/styles/index';

// - Organization stylesheets
@import '../../src/organization/styles/index';

// - Web (visitor/end-user) stylesheets
@import '../../src/web/styles/index';

// - Component stylesheets (in legacy folder)
@import '../../javascripts/components/common/Tooltip/tooltip';
@import '../../javascripts/components/common/Tooltip/tooltip-link';

// --------------------------------------------------------------------------------

@import 'modules/_fortnox-integration-confirmation';
@import 'modules/_badge-code-dialog';
@import 'modules/_badge-list-dialog';
@import 'modules/_share-dialog';
@import 'modules/_fortnox-integration-confirmation';
@import 'modules/_widget-preview';
@import 'modules/_widget-controls';
@import 'modules/_toggle-button-group';
@import 'modules/_action-bar';
@import 'modules/_stats-header';
@import 'modules/_stats-widget-header';
@import 'modules/_rating-list';
@import 'modules/_date-picker';

@import 'pages/index';

@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';

html {
  -webkit-text-size-adjust: none;
  scroll-behavior: smooth;
}

html.js.js--page-hide body {
  visibility: hidden;
}

html.js.js--page-loaded body {
  visibility: visible;
}

html body .hidden {
  display: none;
}

#nativeRecoBooking * {
  @include box-sizing(content-box);
  line-height: initial;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-default;
  font-weight: 300;
  font-size: 14px;
  color: $text-color;
  line-height: 1.5714285714;
  -webkit-font-smoothing: antialiased;
  overflow-y: scroll;
  overflow-x: hidden;
}

html,
body {
  min-height: 100vh;
}

html.nojs img[data-lazy='true'] {
  display: none;
}
html.js img[data-lazy='true'] {
  display: inline;
  &.iblock {
    display: inline-block;
  }
  &.block {
    display: block;
  }
}

html.js .js--hidden {
  display: none;
}

a {
  color: $link-color;
  cursor: pointer;
}

b,
strong {
  font-weight: 500;
}

.hv-bg-lgray:hover {
  background: $bg-lgray;
}

.hv-bg-gray:hover {
  background: #ddd;
}

img {
  border: 0;
}

@media screen and (max-width: $breakpoint-small-tablet) {
  body {
    padding-top: 0;
  }

  body .pm-st {
    padding: $margin-medium;
  }
}

@media screen and (max-width: $breakpoint-large-tablet) {
  body .pm-lt {
    padding: $margin-medium;
  }

  body .p0-lt {
    padding: 0;
  }
}

.mobile-desktop-switch {
  padding: 5px;
  background-color: #0093df;
  min-height: 42px;
  text-align: center;
  font-size: 18px;
}

.mobile-desktop-switch a {
  color: #fff;
}

.content {
  max-width: 1040px;
  margin: 0 auto;
  margin-bottom: 40px;
  @include box-shadow(0 0 3px rgba(0, 0, 0, 0.2));
}

.content-thin {
  max-width: 1040px;
  margin: 0 auto;

  &-shadow {
    border-radius: 8px;
  }
}

.content-small {
  max-width: 640px;
  margin: 0 auto;
  margin-bottom: 40px;
  @include box-shadow(0 0 3px rgba(0, 0, 0, 0.2));
}

.content-medium {
  max-width: 730px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.content-large {
  max-width: 810px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.noshadow {
  @include box-shadow(0);
}

.thin-shadow {
  @include box-shadow(0 0 3px rgba(0, 0, 0, 0.2));
}

.cta {
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  padding: 10px;
  display: block;
  color: #fff;
  background-color: $orange;
  margin: 5px;
  font-weight: 500;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  @include background-image(linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1)));

  @include box-shadow(
    0px 3px 0px shade($orange, 30%),
    0px 3px 0px rgba(255, 255, 255, 0.5) inset,
    1px 1px 2px rgba(0, 0, 0, 0.2)
  );

  &:hover {
    background-color: shade($orange, 5%);
    //border: 1px solid shade($orange, 15%);
  }
}

.js--banner {
  background-image: url(/assets/images/logo.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
}

html body .bp-r-s {
  display: none;
}

@media only screen and (max-width: $breakpoint-reco) {
  .bp-r-h {
    display: none;
  }

  html body .bp-r-s {
    display: block;
  }
}

#results-column {
  width: 60%;
  max-width: 940px;
}

@media only screen and (max-width: $breakpoint-large-tablet) {
  .bp-lt-h {
    display: none;
  }

  html body .bp-lt-s {
    display: block;
  }

  .tmm-lt {
    margin-top: $margin-medium;
  }
}

@media only screen and (max-width: $breakpoint-small-tablet) {
  .bp-st-h {
    display: none;
  }

  html body .bp-st-s {
    display: block;
  }

  #results-column {
    width: auto;
    float: none;
  }
}

@media only screen and (max-width: $breakpoint-mobile) {
  .bp-m-h {
    display: none;
  }

  html body .bp-m-s {
    display: block;
  }
}

#main-map-container:after {
  position: absolute;
  content: ' ';
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: $layer-3;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
}

#map-container {
  background: #555;
}
#map-container:after {
  position: absolute;
  content: ' ';
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: $layer-3;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
}

.search-input {
  border: 1px solid #ccc;
  width: 100%;
  background: #fff;
  outline: 0;
  padding: 0 9px;
  line-height: 1.2em;
  height: 31px;
  font-size: 13px;
  vertical-align: top;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0), 1px 1px 2px rgba(0, 0, 0, 0.07) inset;
  &:focus {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.8), 0 0 0 rgba(0, 0, 0, 0) inset;
    border-color: #ea0;
  }
  @include transition(all linear 0.1s);
  @include border-radius(3px);
}

.js--hidden-cmts,
.js--hidden-thread {
  display: none;
}

.nav {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 rgba(0, 0, 0, 0) inset;
  padding-right: 3px;
  &:hover {
    background-color: #f9f9f9;
    box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.05) inset;
  }
  @include transition(background-color 0.2s linear);
  &:after {
    display: block;
    content: ' ';
    height: 1px;
    overflow: hidden;
    width: 100%;
    background: #eee;
  }
}

.nav-active {
  padding-right: 0;
  border-right: 3px solid #fa0;
}

.horizontal-nav {
  background-color: #f5f6f5;
  box-shadow: 0 0 rgba(0, 0, 0, 0) inset;
  padding-right: 3px;
  &:hover {
    background-color: #fff;
  }
  @include transition(background-color 0.2s linear);
  &:after {
    display: block;
    content: ' ';
    height: 1px;
    overflow: hidden;
    width: 100%;
    background: #fff;
  }
}

.horizontal-nav-active {
  padding-right: 0;
  border-top: 3px solid #fa0;
}

.legend-square .color-box {
  width: 10px;
  height: 10px;
  display: inline-block;
  float: left;
  margin-top: 5px;
}

.legend-square span {
  padding-left: 10px;
  vertical-align: top;
}

.notfc {
  height: 25px;
  display: inline-block;
  vertical-align: middle;
  padding: 0 7px;
  font-size: 0.9em;
  background: $pink;
  color: #fff;
  font-weight: bold;
  line-height: 25px;
  text-align: center;
  @include border-radius(35%);
}

.tag {
  display: inline-block;
  vertical-align: middle;
  padding: 0 5px;
  margin: 3px;
  height: 26px;
  line-height: 26px;
  background: #f4f4f4;
  font-size: 0.8em;
  &:hover {
    background: #eee;
  }
}

.tag-delete {
  background: #eee;
  display: inline-block;
  height: 16px;
  width: 16px;
  line-height: 16px;
  vertical-align: middle;
}

.js--no-pointer-events,
.js--no-pointer-events * {
  pointer-events: none !important;
}

.ptr {
  cursor: pointer;
}

.bl-cc {
  border-left: 4px solid shade(#f9efd4, 5%);
}

.bg-cc-green {
  background-color: #d0fcd8;
}

.bl-cc-d {
  border-left: 4px solid shade(#f9efd4, 10%);
}

.bl-rr {
  border-left: 4px solid #eef;
}

.bl-rr-d {
  border-left: 4px solid shade(#eef, 5%);
}

.bb-gray {
  border-bottom: 1px solid #ddd;
}

.bt-gray {
  border-top: 1px solid #ddd;
}

.tnav.tnav-active {
  color: lighten($text-color, 15%);
  &.tnav-pink {
    border-bottom-color: $pink;
  }
  &.tnav-orange {
    border-bottom-color: $orange;
  }
  &.tnav-blue {
    border-bottom-color: $blue;
  }
}

.tnav {
  padding: 0 20px 5px 20px;
  display: inline-block;
  border-bottom: 2px solid transparent;
  line-height: 1.5em;
  margin-right: 20px;
  @include transition(border-bottom-color 0.15s ease-in-out);
}

.tnav:hover {
  color: lighten($text-color, 15%);
  &.tnav-orange {
    border-bottom-color: $orange;
  }
  &.tnav-pink {
    border-bottom-color: $pink;
  }
  &.tnav-blue {
    border-bottom-color: $blue;
  }
}

.tbar {
  padding: 0 20px 5px 20px;
  display: inline-block;
  border-bottom: 2px solid transparent;
  line-height: 1.5em;
  margin-right: 20px;
}

.border-r-thin {
  border-right: 1px solid #ebe2c9;
}

.chart .tick {
  line {
    opacity: 0;
  }
}

.chart.chart-teal {
  .tick text {
    fill: #777;
  }
}

.chart.chart-blue .tick text {
  fill: #777;
}

.chart.chart-teal {
  .y.axis .domain {
    opacity: 0;
  }
  .x.axis .domain {
    stroke: #ec1d76;
  }
}

.chart.chart-blue {
  .y.axis .domain {
    opacity: 0;
  }
  .x.axis .domain {
    stroke: #bad432;
  }
}

.chart .tooltip {
  display: inline-block;
  padding: 3px;
  font-size: 11px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  background: #fff;
}

.piechart polyline {
  display: none;
}

.active-block {
  background-color: #dcffed;
  border-bottom: 1px solid #e0e0e0;
}

.disabled-block {
  background-color: #ffe4e1;
  border-bottom: 1px solid #e0e0e0;
}

.phone-ask {
  display: block;
  border: 1px solid #ada;
  border-radius: 5px;
  color: #0b0;
  padding: 7px;
  &:hover {
    background: #f9fff9;
    border-color: #8b8;
  }
}

.phone-ask--mask {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  @include background-image(
    linear-gradient(left, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 1))
  );
}

.phone-cta {
  border-style: dashed;
  border-color: #acdcf4;
  background: #ddf1fa;
  text-align: center;
  color: #0093df;

  &:hover {
    background: lighten(#ddf1fa, 3%);
  }

  &.phone-cta--big {
    border-radius: 5px;
    border-width: 3px;
    display: block;
  }
  &.phone-cta--small {
    display: inline-block;
    border-radius: 5px;
    border-width: 1px;
    padding: 7px 20px;
    line-height: 20px;
  }
}

.gallery-picture {
  width: 184px;
  height: 143px;
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  position: relative;
}

.gallery-project {
  height: 234px;
  width: 232px;
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
  position: relative;
}

.gallery-project-image {
  height: 182px;
  width: 100%;
  float: left;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  position: relative;
}

.gallery-project-info {
  float: left;
  width: 100%;
  p {
    margin: 0px;
  }
}

#projects h3 {
  position: absolute;
  margin-top: -1px;
  color: #ffffff;
}

.padz {
  padding: 3px 6px 3px 6px;
  float: left;
}

.padz-xl {
  padding: 3px 30px 3px 30px;
  float: left;
}

.project-sell-p {
  font-weight: 400;
  background: #234956;
}

.project-price-inc {
  background: #00c100;
  padding-left: 10px;
  padding-right: 10px;
}

.org-sticky {
  .t4,
  .t7 {
    border-bottom: 1px solid transparent;
    @include transition(all 0.15s ease-in-out);
  }
}

.org-sticky-active {
  .t4,
  .t7 {
    border-bottom: 1px solid #bbb;
    //font-size: 1em;
  }
}

.art-img-r {
  float: right;
  margin-left: 10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 740px) {
  .fp-wrap {
    display: block;
  }
}

/* Breakpoint mobile */
@media only screen and (max-width: 860px) {
  .start-top {
    padding: 40px 20px;
  }

  .logo.logo--responsive {
    width: 81px;
    height: 34px;
  }

  .logo-wrapper {
    width: 81px;
  }

  .header-search {
    padding-top: 50px;
    margin-left: 0;
  }
}

.article {
  h2 {
    font-size: 18px;
    line-height: 1.2;
    margin: 0;
    margin-top: 10px;
  }

  p {
    margin: 0;
    margin-top: 10px;
    padding: 0;
    line-height: 1.4em;
    a {
      @include link-decoration;
    }
  }
  ul {
    padding: 0 0 0 20px;
    li {
      margin: 5px 0;
    }
  }

  div:first-of-type {
    p {
      margin-top: 0;
    }
  }

  table {
    thead td {
      background: #f5f5f5;
      font-weight: bold;
    }

    td {
      border-collapse: collapse;
      border: 1px solid #ddd;
      padding: 5px 10px;
    }
  }
}

/* Strike through label */
.stkt {
  position: relative;

  &:before {
    height: 1px;
    background: #ddd;
    width: 100%;
    top: 50%;
    position: absolute;
    left: 0;
    display: block;
    content: ' ';
  }
  > .stkt--label {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.tooltip {
  cursor: default;
}

#save-partner-fastighetsmäklare {
  .partner {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .off {
    background-image: url(/assets/images/icons/star-2.svg);
    &:hover {
      background-image: url(/assets/images/icons/star-1.svg);
    }
  }
  .on {
    background-image: url(/assets/images/icons/star-1.svg);
  }
}

.partner-list-item {
  border: 1px solid #ffffff;
}

.sent-check {
  transition: opacity 1s ease-out;
  opacity: 0;
}

.partner-sent {
  border: 1px solid #00c100;
  -webkit-transition: border 2s ease-out;
  -webkit-transition: border 2s ease-out;
  -moz-transition: border 2s ease-out;
  -o-transition: border 2s ease-out;
  transition: border 2s ease-out;
}

.frontpage-reco-puff {
  background: linear-gradient(160deg, #18365e, #3cb992);
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  span {
    position: relative;
    z-index: $layer-2;
  }

  &:hover:before {
    opacity: 1;
  }

  &:before {
    z-index: $layer-1;
    background: linear-gradient(160deg, lighten(#18365e, 3%), lighten(#3cb992, 10%));
    border-radius: 5px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    content: ' ';
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
}

//top project gallery(tpg)

.tpg-content {
  max-width: 1040px;
  margin: 0 auto;
  padding: 20px 0 20px 0;
  position: relative;

  #tpg-p {
    white-space: nowrap;
  }

  .tpg-card {
    width: 252px;
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    position: relative;
    background-color: #ffffff;
    padding: 2px;
    color: #234956;
    @include border-radius(3px);
    @include box-shadow(0 0 3px rgba(0, 0, 0, 0.2));

    & a {
      color: #234956;
    }

    .tpg-image {
      height: 182px;
      width: 100%;
      float: left;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      position: relative;
    }

    .a-image {
      position: absolute;
      margin-top: -8px;
      left: -7px;
      border-radius: 100%;
    }

    h3 {
      float: right;
      margin-top: 152px;
      color: #ffffff;
    }

    .sell-p {
      font-weight: 400;
      background: #234956;
    }

    .price-inc {
      background: #00c100;
      padding-left: 10px;
      padding-right: 10px;
    }

    .padz {
      padding: 3px 6px 3px 6px;
    }

    .tpg-info {
      float: left;
      width: 100%;
      padding: 5px;
      p {
        margin: 0px;
      }
    }
  }
}

/** Clamp review */
.review-clamp {
  @include clamp(6);
}

/* Profile page, @TODO(torh): refactor this */
.profile--image {
  border-radius: 100%;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
  height: 120%;
  position: absolute;
  left: 40px;
  max-height: 160px;
  max-width: 160px;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (max-width: $breakpoint-large-tablet) {
    height: 120px;
    left: 20px;
    margin-top: -10px;
    top: 0;
    transform: translateY(0);
  }
}

.profile--image + .profile--stats {
  margin-left: 210px;

  @media screen and (max-width: $breakpoint-large-tablet) {
    margin-left: 140px;
  }
}

.profile--dates {
  @media screen and (max-width: $breakpoint-large-tablet) {
    background: #fff;
  }
}

.profile--dates--spacer {
  display: inline-block;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;

  @media screen and (max-width: $breakpoint-large-tablet) {
    height: 0;
    width: 10px;
  }
}

.header-legend {
  @media screen and (max-width: $breakpoint-large-tablet) {
    border-bottom: 1px solid #eee;
    position: relative;
  }
}

.header-info {
  padding-left: 230px;

  @media screen and (max-width: $breakpoint-large-tablet) {
    text-align: center;
    padding-top: 14px;
    padding-left: 6px;
  }
}

// Background half white top
.bg-hw-top {
  background: linear-gradient(#fff 50%, rgba(255, 255, 255, 0) 50.001%);
}

// Background half gray top
.bg-hw-bottom {
  background: linear-gradient(rgba(255, 255, 255, 0) 50%, #fff 50.0001%);
}

.visma-info {
  margin: 0 auto;
  max-width: 1040px;
  padding: 40px;
  min-height: 400px;
  padding-top: 150px;
}

.timelineMenu {
  padding: 10px;
  margin: 5px;
  background-color: rgb(238, 238, 238);
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.fbtn-thin {
  max-height: 20px;
  padding-top: 5px;
  padding-bottom: 23px;
}
.leadBoxesContainer {
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: space-between;
  display: flex;
  padding-left: 2px;
}
.leadSummaryBox {
  max-width: 102px;
  margin-right: 5px;
  font-size: 0.8em;
  padding: 3px 0;
  line-height: 17px;
  vertical-align: middle;
  display: inline-block;
  width: 90%;
  background: #85a134;
  color: #fff;
  text-shadow: -1px -1px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

.admin-margin-top-5 {
  margin-top: 5px;
}

button.dropdown-toggle.btn.btn-primary {
  &:after {
    position: relative;
    width: auto;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.reviews-subtitle {
  @include font-family-body;
  @include font-paragraph-14;
  font-weight: var(--font-weight-light);
  color: var(--color-grey-500);
}

.reviews-filter-title {
  @include heading2(false);
  margin-bottom: 0.5rem;
}

.ga4-fix > * {
  pointer-events: none;
}

.font-medium {
  font-weight: 500;
}

.truncated-text {
  margin: 0;
  white-space: pre-line;
  overflow-wrap: anywhere;
}

.truncated-text-ellipsis {
  white-space: pre;
}
