// Used only in business area. Should be replaced with the new .form-select
.form-select-legacy {
  position: relative;
  border: 1px solid $gray4;
  border-radius: 0.375em;
  line-height: 1.6;
  color: $gray9;
  font-size: 0.875em;
  padding: 0 0.75em;
  display: block;

  select {
    padding: 0.75em 0;
    color: $gray9;
    font-family: Roboto;
    appearance: none;
    border: none;
    margin-right: 0.75em;
    border-right: 1px solid $gray4;
    width: 92%;

    @media screen and (min-width: $breakpoint-small-tablet) {
      width: auto;
      padding: 0.4295em 0;
    }

    @media screen and (min-width: $breakpoint-large-tablet) {
      width: 95%;
      padding: 0.75em 0;
    }

    @media screen and (min-width: $breakpoint-tablet) {
      width: auto;
      padding: 0.4295em 0;
    }

    &:focus {
      outline: none;
    }
  }
}

// Used in LeadForm and review filtering. Should be replaced by form-check
label.form-check {
  display: flex;
  margin: 0.5em 0 0.5em 0;

  & + .form-control,
  & + .form-select,
  & + .form-check {
    margin-top: 1.5em;
  }

  input[type='checkbox'],
  input[type='radio'] {
    top: 0;
    margin-right: 0.375em;
  }
}

.f-row {
  position: relative;
}
.f-label {
  margin: 0 0 4px 0;
  font-weight: bold;
  display: block;
}
.f-label-float {
  position: absolute;
  padding: $input-block-padding;
  z-index: $layer-2;
  margin: 0;
}
.f-label-g {
  height: 47px - 4px;
  line-height: 47px - 4px;
}

.isDisabled {
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.radio-button {
  position: relative;
  display: inline-block;

  .positive {
    position: absolute;
    top: 40px;
    right: -2px;
    min-width: 121px;
  }

  .negative {
    position: absolute;
    top: 40px;
    min-width: 102px;
  }

  @media screen and (max-width: $breakpoint-small-tablet) {
    .positive {
      right: 0px;
      min-width: inherit;
    }
  }
}

.input-copy {
  position: relative;

  input.rounded {
    border-radius: 8px;
  }

  a {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }
}
