.dropdown {
  position: absolute;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid $gray4;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  background-color: $white;
  z-index: $layer-6;

  &.dropdown-top-right {
    top: 100%;
    right: 0;
  }

  &.narrow {
    max-width: 16.625rem;
  }
}
