$delimiter-200: (
  color: $grey-200,
  image: 'wavy-pattern-grey-200.svg'
);

$delimiter-600: (
  color: $grey-600,
  image: 'wavy-pattern-grey-600.svg'
);

$delimiters: ($delimiter-200, $delimiter-600);

@mixin delimiter($color: map-get($delimiter-200, color)) {
  content: '';
  display: block;
  width: 100%;
  height: 0.5rem;
  background-position: center;

  @each $delimiter in $delimiters {
    @if $color == map-get($delimiter, color) {
      background-image: url('/assets/images/#{map-get($delimiter, image)}');
    }
  }
}
