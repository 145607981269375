@import './reviewCard/review-card-person';
@import './reviewCard/review-card-rating';
@import './reviewCard/review-card-menu-actions';
@import './reviewCard/review-card-report';
@import './reviewCard/review-card-appreciate';
@import './reviewCard/review-card-response';

.review-card {
  display: flex;
  padding: 0.625em 0;
  flex-direction: column;
  position: relative;
  width: 100%;
  font-size: 16px;

  &.review-card-marked {
    background-color: var(--color-grey-200);
    padding: var(--grid-8);
    border-radius: var(--border-radius-default);
  }

  &--top {
    display: flex;
    align-items: center;
  }

  .text-clamp {
    text-align: left;
    word-break: break-word;
    width: 100%;
    color: black;

    q {
      &:before,
      &:after {
        display: none;
      }
    }

    &--expand {
      bottom: 0;
      .bttn {
        font-size: 0.75rem;
        font-family: $font-default;
      }
    }
  }

  &--reviewer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-right: 1em;
  }

  &--actions-wrapper {
    position: relative;
  }

  &--admin {
    font-size: 0.875em;
    margin-top: 1em;
  }
}

.review-card--rating-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 0;
}
