/* 3 column grid table */

.gtr {
  border-top: 1px solid #ddd;
  overflow: hidden;

  &:first-child {
    border-top: 0;
  }
}

.gt3 {
  float: left;
  border-left: 1px solid #ddd;
  width: 33.333%;
  &:first-child {
    border-left: 0;
  }
}

/* Breakpoint small tablet */
@media only screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-small-tablet) {
  .bp-st {
    &.gt .gtr {
      border: 0;
      &:first-child {
        border-top: 1px solid #ddd;
      }
    }
    &.gt .gt3 {
      width: auto;
      float: none;
      border-left: 0;
      border-bottom: 1px solid #ddd;
    }
  }
}

@media only screen and (max-width: $breakpoint-mobile) {
  .bp-m {
    &.gt .gtr {
      border: 0;
      &:first-child {
        border-top: 1px solid #ddd;
      }
    }
    &.gt .gt3 {
      width: auto;
      float: none;
      border-left: 0;
      border-bottom: 1px solid #ddd;
    }
  }
}
