#create-missing-organization {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 700;
  padding: 2rem 6rem 2rem 6rem;
  border: 1px solid $black;
  border-radius: 0.5rem;
  background-color: $white;

  .link.b2b {
    padding: 0.75rem 0.75rem;
    box-shadow: none;
    border: 2px solid $blue;
    border-radius: 0.5rem;
    background-color: $white;
    font-weight: 700;
    text-decoration: none;

    margin-right: 0.375rem; // optical ajustment due to the border

    &:hover {
      text-decoration: none;
    }
  }

  @media (max-width: 650px) {
      padding: 1rem 2rem 1rem 2rem;
      width: 90%;

      p {
        text-align: center;
      }
    }
}