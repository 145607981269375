.venue-leads-dialog {
  padding: 0;
  border: 0;
  border-radius: 0.5rem;
  width: 30rem;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  box-shadow: var(--shadow-lg);
  background-color: transparent;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    max-width: calc(100vw - 3rem);
    max-height: calc(100vh - 3rem);
  }

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.venue-leads-dialog-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  .felt-btn {
    display: grid;
    place-items: center;
    height: 2rem;
    aspect-ratio: 1;
    padding: 0;
    font-size: 1.125rem;
  }
}
