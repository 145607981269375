.dp {
  background: #fff;
  width: 240px;
  border: 1px solid #ddd;
  @include box-shadow(2px 2px 4px rgba(0,0,0,0.2));
}

.dp--day {
  padding: 5px;
  width: 14.28%;
  text-align: center;
  cursor: pointer;
  float: left;
  border-top: 1px solid #f4f4f4;
  border-left: 1px solid #f4f4f4;

  &.dp--day-1 {
    clear: left;
  }

  &.dp--redday {
    background: #f4f4f4;
  }

  &.dp--current {
    background: #3a5b66;
    color: #fff;
  }
}

.dp--header {
  border-bottom: 1px solid #ddd;
  padding: 5px;
  line-height: 25px;
}

.dp--day-header {
  border-bottom: 1px solid #ddd;
  overflow: hidden;
}

.dp--prev {
  float: left;
}
.dp--next {
  float: right;
}
.dp--prev,
.dp--next {
  display: inline-block;
  padding: 0 5px;
  text-decoration: none;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  background: #f4f4f4;
  color: #555;
  font-weight: bold;
  &:hover {
    background: #ddd;
  }
}

