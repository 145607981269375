@if ($mode == 'critical' or $mode == 'all') {
  .t0 {
    font-size: 12px;
  }
  .t1 {
    font-size: 14px;
  }
  .t2 {
    font-size: 16px;
  }
  .t3 {
    font-size: 20px;
  }
  .t4 {
    font-size: 22px;
  }
  .t5 {
    font-size: 26px;
  }
  .t6 {
    font-size: 30px;
  }
  .t6-5 {
    font-size: 34px;
  }
  .t7 {
    font-size: 40px;
  }
  .t8 {
    font-size: 50px;
  }
  .t9 {
    font-size: 60px;
  }
  .ln1 {
    line-height: 16px;
  }
  .ln2 {
    line-height: 18px;
  }
  .ln3 {
    line-height: 22px;
  }
  .ln4 {
    line-height: 24px;
  }
  .ln5 {
    line-height: 26px;
  }
  .ln6 {
    line-height: 30px;
  }
  .ln7 {
    line-height: 36px;
  }
  .ln8 {
    line-height: 44px;
  }
  .ln41 {
    line-height: 41px;
  }
  .nstl {
    font-style: normal;
    font-weight: normal;
  }
  .cntr {
    text-align: center;
  }
  .rght {
    text-align: right;
  }
  .lft {
    text-align: left;
  }
  .itlc {
    font-style: italic;
  }
  .bld {
    font-weight: bold;
  }
  .bldl {
    font-weight: 500;
  }

  .cdnsd {
    font-family: 'Roboto Condensed', Roboto, helvetica, arial, sans-serif;
  }

  /*
   * Prevents text from wrapping, hiding overflow.
   */
  .wnw {
    white-space: nowrap;
    overflow: hidden;
  }

  /*
   * Overflowing text is truncated with "...".
   * It is advisable to use this with .wnw class.
   */
  .eps {
    text-overflow: ellipsis;
  }

  .wba {
    word-break: break-all;
  }

  .hide-txt {
    overflow: hidden;
    text-indent: -9999px;
  }

  @media only screen and (max-width: $breakpoint-large-tablet) {
    body .cntr-lt {
      text-align: center;
    }
  }

  @media only screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-small-tablet) {
    .cntr-st {
      text-align: center;
    }

    .left-st {
      text-align: left;
    }
  }

  /* Breakpoint mobile */
  @media only screen and (max-width: $breakpoint-mobile) {
    .cntr-m {
      text-align: center;
    }

    .left-m {
      text-align: left;
    }
  }
}

@if ($mode != 'critical') {
  .nou {
    text-decoration: none;
  }
  .uh:hover {
    text-decoration: underline;
  }
  .uline {
    text-decoration: underline;
  }
  .up {
    text-transform: uppercase;
  }
  .ts5 {
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5);
  }
}

@if ($mode == 'critical' or $mode == 'all') {
  .orange {
    color: #e6983e;
  }
  .pink {
    color: #ff6f9c;
  }
  .dpink {
    color: $dpink;
  }
  .gray {
    color: #999;
  }
  .blue {
    color: #479bd3;
  }
  .dblue {
    color: $dblue;
  }
  .dgray {
    color: #555;
  }
  .lgreen {
    color: #00c200;
  }
  .green {
    color: #85a134;
  }
  .red {
    color: #d00;
  }
  .black {
    color: $text-color;
  }
  .blackl {
    color: tint($text-color, 20);
  }
  .white {
    color: #fff;
  }
  .redorange {
    color: $red-orange;
  }
  .error {
    color: #900;
  }
  .warning {
    color: #990;
  }
  .success {
    color: #090;
  }
}

@media only screen and (max-width: $breakpoint-small-tablet) and (max-width: $breakpoint-large-tablet) {
  html .bp-st-t0 {
    font-size: 12px;
  }
  html .bp-st-t1 {
    font-size: 14px;
  }
  html .bp-st-t2 {
    font-size: 16px;
  }
  html .bp-st-t3 {
    font-size: 20px;
  }
  html .bp-st-t4 {
    font-size: 22px;
  }
  html .bp-st-t5 {
    font-size: 26px;
  }
}
