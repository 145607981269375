@import '../src/_index.scss';

:root {
  /********************
   ** COLORS
   ********************/

  // Shades of black
  --color-black: #{$black};
  --color-grey-600: #{$grey-600};
  --color-grey-500: #{$grey-500};
  --color-grey-400: #{$grey-400};
  --color-grey-300: #{$grey-300};
  --color-grey-200: #{$grey-200};
  --color-grey-100: #{$grey-100};
  --color-white: #{$white};

  // Reco base colors
  --color-reco-green: #{$reco-green};
  --color-reco-pink: #{$reco-pink};
  --color-reco-blue: #{$reco-blue};
  --color-reco-yellow: #{$reco-yellow};

  // Complementary light shades
  --color-reco-green-light: #{$reco-green-light};
  --color-reco-pink-light: #{$reco-pink-light};
  --color-reco-blue-light: #{$reco-blue-light};
  --color-reco-yellow-light: #{$reco-yellow-light};

  // Signal colors
  --color-signal-green: #{$signal-green};
  --color-signal-red: #{$signal-red};
  --color-signal-blue: #{$signal-blue};
  --color-signal-orange: #{$signal-orange};

  // Brand colors
  --color-brand-facebook: #{$brand-facebook};

  // Aliases
  --color-link: var(--color-reco-blue);

  /********************
   ** SHADOWS
   ********************/

  --shadow-sm: $shadow-sm;
  --shadow-md: $shadow-md;
  --shadow-lg: $shadow-lg;

  /********************
   ** DIMENSIONS
   ********************/

  // grid
  --grid-4: 0.25rem;
  --grid-8: 0.5rem;
  --grid-16: 1rem;
  --grid-24: 1.5rem;
  --grid-32: 2rem;
  --grid-40: 2.5rem;
  --grid-48: 3rem;
  --grid-64: 4rem;
  --grid-96: 6rem;
  --grid-320: 20rem;
  --grid-500: 31.25rem;
  --grid-640: 40rem;
  --grid-768: 48rem;
  --grid-960: 60rem;

  // border
  --border-radius-small: 0.25rem;
  --border-radius-medium: 0.5rem;
  --border-radius-large: 1rem;
  --border-radius-full: 999rem;
  --border-radius-default: var(--border-radius-medium);

  // margin
  --outer-margin-default: var(--grid-48);

  /********************
   ** Z-INDEX
   ********************/

  --layer-alpha-centauri: #{$layer-6};
  --layer-andromeda: #{$layer-5};
  --layer-sun: #{$layer-4};
  --layer-moon: #{$layer-3};
  --layer-sky: #{$layer-2};
  --layer-hill: #{$layer-1};
  --layer-ground: #{$layer-initial};
  --layer-soil: -10;
  --layer-crust: -20;
  --layer-magma: -30;
  --layer-core: -40;

  /********************
   ** TYPOGRAPHY
   ********************/

  --font-weight-bold: 700;
  --font-weight-regular: 400;
  --font-weight-light: 300;

  --font-default: #{$font-default};
  --font-serif: 'bitstream-cooper-md';
}
