.cooltip-wrapper {
  position: relative;
}

.cooltip-hint {
  position: relative;
  color: var(--color-grey-600);

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: 1px dotted black;
  }
}

.cooltip {
  position: absolute;
  padding: 0.25rem 0 0;
  inset: 100% auto auto 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column-reverse;
  filter: drop-shadow(var(--shadow-md));
  z-index: 1;
  width: 16rem;
  max-width: 66vw;

  &:not(.cooltip--visible) {
    display: none;
  }

  p {
    margin: 0;
  }
}

.cooltip:after {
  content: '';
  flex: 0 0 9px;
  margin: 0 1rem -1px 1rem;
  background: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 8 8 0 0 8v1a1 1 0 0 0 .707-.293L8 1.414l7.293 7.293A1 1 0 0 0 16 9V8Z' fill='%23D9D9D9'/%3E%3Cpath d='M8 1.414.707 8.707A1 1 0 0 1 0 9h16a1 1 0 0 1-.707-.293L8 1.414Z' fill='%23fff'/%3E%3C/svg%3E")
    center / 16px 9px no-repeat;
  z-index: 1;
}

.cooltip--left {
  transform: translateX(calc(-100% + 1.5rem));

  &:after {
    background-position: right;
  }
}

.cooltip--right {
  transform: translateX(-1.5rem);

  &:after {
    background-position: left;
  }
}

.cooltip--top {
  padding: 0 0 0.25rem;
  inset: auto auto 100% 50%;
  flex-direction: column;

  &:after {
    margin: -1px 1rem 0 1rem;
    transform: scaleY(-1);
  }
}

.cooltip-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: var(--color-white);
  box-shadow: inset 0 0 0 1px var(--color-grey-400);
}

.cooltip-header {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.cooltip-header-icon {
  font-size: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
}

.cooltip-box {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: var(--color-grey-200);
  border-radius: 0.5rem;
}
