@mixin clamp($lines) {
  position: relative;
  overflow: hidden;
  max-height: (22px*$lines);
  font-size: 0.875em;
  font-weight: 300;
  line-height: 1.57;

  &:before {
    position: absolute;
    top: 22px * ($lines - 1);
    left: 0;
    width: 100%;
    height: 22px;
    content: ' ';
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
  }

  &--center {
    text-align: center;
  }

  &.review-clamp--expanded {
    max-height: none;

    &:before {
      display: none;
    }

    .review-clamp-expand {
      display: none;
    }
  }

  .review-clamp-expand {
    position: absolute;
    top: 103px;
    left: 50%;
    transform: translateX(-50%);
  }
}
