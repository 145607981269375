.share {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: $breakpoint-reco) {
    flex-direction: row;
    padding: 0;
  }

  &.content-large {
    margin-top: $margin-medium * 2;
  }

  aside {
    margin-bottom: $margin-medium;
  }

  &--wrapper {
    background-color: $bg-default;
    width: 100%;
    border-radius: 4px;
    padding: 5vw;
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-reco) {
      padding: 24px 48px;
      max-width: 680px;
    }

    > .bttn-primary {
      align-self: center;
    }

    .heading1 {
      align-self: center;
      text-align: center;
      margin-bottom: $margin-medium;
    }

    .heading4 {
      margin: $margin-medium 0 $margin-small;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @media (min-width: $breakpoint-small-tablet) {
        flex-direction: row;
      }

      li {
        margin-bottom: $margin-small;

        @media (min-width: $breakpoint-small-tablet) {
          margin-bottom: 0;
        }

        a,
        button {
          width: 100%;
        }
      }
    }

    .review {
      background: #fff;
      width: 80%;
      margin: 0 auto;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);

      &--wrapper {
        padding: 8vw 0;

        @media (min-width: $breakpoint-reco) {
          padding: 100px 0 140px;
        }
      }
    }

    .generated-image {
      width: 100%;
    }

    .figure-wrap {
      position: relative;
      padding-top: 100%;
    }

    figure {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: 0;

      // Loader
      > div {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
      }

      img {
        display: block;
        width: 100%;
      }

      figcaption {
        h2 {
          margin: 0 0 6px;
          color: $white;
          font-family: 'bitstream-cooper-md';
          color: #000;
        }
      }

      &.regular {
        figcaption {
          display: flex;
          align-items: center;
          background-color: rgba($bg-lgray, 0.5);
          flex-direction: column;

          @media (min-width: $breakpoint-small-tablet) {
            flex-direction: row;
          }

          h2 {
            margin: 0 0 2px;
            text-align: center;

            @media (min-width: $breakpoint-small-tablet) {
              text-align: left;
            }
          }

          .profile-image {
            width: 76px;
            height: 76px;
            border-radius: 50%;
            border: 2px solid $input-block-border;
            margin-bottom: $margin-medium;

            @media (min-width: $breakpoint-small-tablet) {
              width: 56px;
              height: 56px;
              margin-right: 16px;
              margin-bottom: 0;
            }
          }

          .logotype {
            width: 82px;
            height: auto;
            margin-top: $margin-medium;

            @media (min-width: $breakpoint-small-tablet) {
              margin-left: auto;
              align-self: flex-end;
              margin-top: 0;
              margin-bottom: 4px;
            }
          }
        }

        &--green {
          background-color: $light-green;
        }

        &--blue {
          background-color: $light-blue;
        }

        &--yellow {
          background-color: $light-yellow;
        }

        &--pink {
          background-color: $light-pink;
        }

        > img {
          width: 80%;
          margin: 0 10%;
          padding: $margin-medium 0;
        }

        .rating {
          color: #000;
        }
      }

      &.profile-share {
        border: 2px solid rgba(0, 0, 0, 0.3);

        figcaption {
          position: absolute;
          bottom: 0;
          width: 100%;
          background-color: rgba($shadow-gray, 0.4);

          .rating {
            color: $white;
          }
        }
      }
    }
  }
}

// @todo Move this to a better location
.review {
  .reco-rating {
    margin: 0 auto;
  }

  &--reviewer {
    display: flex;
    align-items: center;

    figure {
      width: 50px;
      height: 50px;
      margin-right: 14px;

      img {
        width: 100%;
        border-radius: 50%;
      }

      .review-icon {
        border-radius: 50%;
      }
    }
  }

  &--meta {
    h3,
    p {
      margin: 0;
      line-height: 1.25;
    }
  }

  &--date {
    font-size: 12px;
    margin-left: auto;
    text-align: right;

    svg {
      color: $dark-green;
    }

    strong {
      display: block;
    }
  }
}
