.stats-widget-header {
  .stats-widget-header-content {
    display: flex;
    flex-direction: column;

    h2 {
      margin: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    @include breakpoint($breakpoint-portrait-tablet) {
      flex-direction: row;
      align-items: center;
    }
  }

  .stats-widget-header-subtitle,
  .stats-widget-header-description {
    color: $grey-500;
  }

  .stats-widget-header-subtitle {
    font-size: 0.875rem;
  }

  .stats-widget-header-trend {
    margin-left: 0.3125em;

    &.stats-widget-header-trend-negative {
      color: $signal-red;

      svg {
        transform: rotate(135deg);
      }
    }

    &.stats-widget-header-trend-positive {
      color: $signal-green;

      svg {
        transform: rotate(45deg);
      }
    }
  }

  .heading3 {
    display: flex;
    align-items: center;
    margin: 0 0 0.375rem;

    @include breakpoint($breakpoint-portrait-tablet) {
      margin: 0;
    }

    small {
      color: $reco-blue;
      margin: 0 0 0 1rem;
    }
  }
}
