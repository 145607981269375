@mixin previewHorizontal() {
  grid-column: 1 / span 2;
  grid-row: 1 / 1;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

@mixin previewVertical() {
  grid-column: 1 / 2;
  grid-row: 1 / span 2;
  border-radius: 0;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

@mixin controlsHorizontal() {
  grid-column: 1 / span 2;
  grid-row: 2 / 2;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

@mixin controlsVertical() {
  grid-column: 2 / 2;
  grid-row: 1 / span 2;
  border-radius: 0;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.widgets {
  display: grid;
  gap: var(--grid-24);
  width: 100%;
  height: fit-content;

  .widgets-description {
    font-size: 0.875em;
  }

  .widgets-navigation {
    display: flex;
    margin-bottom: 1.5rem;

    button {
      margin-right: 0.5em;
    }
  }

  .widgets-groups {
    max-width: 22.5rem;
    margin-bottom: 1.5rem;
  }

  .widgets-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: var(--grid-24);

    &.widgets-content-horizontal {
      .widgets-preview {
        @include previewHorizontal();
      }

      .widgets-controls {
        @include controlsHorizontal();
      }
    }

    &.widgets-content-vertical {
      .widgets-preview {
        @include previewHorizontal();

        @media (min-width: $breakpoint-landscape-tablet) {
          @include previewVertical();
        }
      }

      .widgets-controls {
        @include controlsHorizontal();

        @media (min-width: $breakpoint-landscape-tablet) {
          @include controlsVertical();
        }
      }
    }
  }

  .widgets-preview,
  .widgets-controls {
    min-height: 18.75rem;
    padding: 1.5rem;
  }

  .widgets-preview {
    border: 1px solid var(--color-grey-200);

    .widget-preview {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  &-controls {
    background-color: var(--color-grey-200);
  }
}
