.table-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625em;
  border: 1px solid $gray7;
  border-top: none;
  border-bottom-left-radius: 0.25em;
  border-bottom-right-radius: 0.25em;

  &.table-pagination-borderless {
    border: none;
  }
}

.table-pagination-options {
  display: flex;
  align-items: center;
  font-size: 0.875em;

  select {
    margin-left: 0.5rem;
  }
}

.table-pagination-nav {
  display: flex;
  align-items: center;

  span {
    display: block;
    padding: 0 0.5em;
    font-size: 0.875em;
  }
}