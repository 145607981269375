@mixin _no-margin-bottom() {
  margin-bottom: 0;

  @include breakpoint($breakpoint-reco) {
    margin-bottom: 0;
  }
}

/**
 * Heading – H1-H6
 */
@mixin heading1($margin-bottom: true) {
  @include _font-heading-24;

  @include breakpoint($breakpoint-reco) {
    @include _font-heading-32;
  }

  @if $margin-bottom == false {
    @include _no-margin-bottom;
  }
}

@mixin heading2($margin-bottom: true) {
  @include _font-heading-18;

  @include breakpoint($breakpoint-reco) {
    @include _font-heading-24;
  }

  @if $margin-bottom == false {
    @include _no-margin-bottom;
  }
}

@mixin heading3($margin-bottom: true) {
  @include _font-heading-16;

  @include breakpoint($breakpoint-reco) {
    @include _font-heading-20;
  }

  @if $margin-bottom == false {
    @include _no-margin-bottom;
  }
}

@mixin heading4($margin-bottom: true) {
  @include _font-heading-16;

  @include breakpoint($breakpoint-reco) {
    @include _font-heading-18;
  }

  @if $margin-bottom == false {
    @include _no-margin-bottom;
  }
}

@mixin heading5 {
  @include font-family-body;
  @include _font-heading-14;

  @include breakpoint($breakpoint-reco) {
    @include _font-heading-16;
  }
}

@mixin heading6 {
  @include font-family-body;
  @include _font-heading-14;

  @include breakpoint($breakpoint-reco) {
    @include _font-heading-16;
  }
}

/*
 * Heading – Underline
 */
@mixin heading-underline($border-color: 'pink', $inline: false) {
  padding-bottom: 0.75rem;
  border-radius: 0.125rem;
  border-bottom-width: 0.125rem;
  border-bottom-style: solid;

  @if $inline {
    display: inline-block;
  }

  @if $border-color == 'pink' {
    border-bottom-color: $light-pink;
  }

  @if $border-color == 'grey' {
    border-bottom-color: $grey-300;
  }

  &-gray,
  &-grey {
    border-bottom-color: $grey-300;
  }
}

@mixin link-decoration {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
