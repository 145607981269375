.venue-history-card {
  display: flex;
  color: black;
  flex-direction: column;

  @media (min-width: $breakpoint-small-tablet) {
    flex-direction: row;
  }

  &--image {
    border: 1px solid $gray10;
    border-radius: 0.5em;
    margin-right: 1.5em;
    display: flex;
    align-items: center;
    padding: 1em;
    width: 100%;
    margin-bottom: 1em;

    @media (min-width: $breakpoint-small-tablet) {
      width: 9.875em;
      margin: 0 1.5em 0 0;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &--content {
    h4 {
      line-height: 1.375;
      margin: 0 0 0.325em;
    }

    .venue-history-card--label {
      font-size: 1rem;
      font-family: "Roboto",HelveticaNeue,Arial,sans-serif;
      font-weight: 700;
    }
}

  &--period,
  &--reviews {
    font-size: 0.875em;
    font-weight: 300;
  }

  &--period {
    margin-bottom: 0.325em;
  }

  &--reviews {
    font-family: $font-default;
  }

  &--rating {
    display: flex;
    align-items: center;
    margin-bottom: 0.325em;

    .reco-rating {
      margin-right: 0.625em;
    }

    strong {
      margin-right: 0.3em;
    }
  }
}
