/**
* @todo This file seems obsolete. Cannot match selectors to any file in the filebase.
* Please confirm and delete.
*/

.logged-in .um-item {
  display: inline-block;
  padding: 5px;
  height: 32px;
  line-height: 20px;
  text-decoration: none;
  color: #555;
  font-weight: bold;
  border-radius: 4px;
  text-shadow: 1px 1px rgba(255,255,255,0.6);
}

.logged-in .um-item:hover {
  color: #000;
  //background: rgba(0,0,0,0.05);
}

.logged-in .um-item.um-active,
.logged-in .um-item.um-active:hover {
  background: rgba(0,0,0,0.5);
}

.logged-in a.um-icon {
  display: inline-block;
  padding: 5px 0px;
  height: 30px;
  line-height: 20px;
  text-decoration: none;
  color: #555;
  text-shadow: 1px 1px rgba(255,255,255,0.6);
  position: relative;
  z-index: $layer-3;
  border-radius: 2px 2px 0 0;
}

.logged-in a.um-icon hover {
  color: #000;
}

.logged-in a.um-icon.um-active,
.logged-in a.um-icon.um-active:hover {
  text-shadow: none;
  color: rgba(0,0,0,0.9);
}

.logged-in a.um-link {
  display: block;
  padding: 3px 20px;
  color: $text-color;
  text-decoration: none;
  font-size: 13px;
}

.logged-in .um-div {
  margin: 5px 0;
  border-bottom: 1px solid #ddd;

  + .um-div,
  &:last-child {
    display: none;
  }
}

.logged-in .um-link:hover {
  color: #fff;
  background: $text-color;
}

.logged-in .um-dropdown {
  position: absolute;
  right: 0px;
  z-index: $layer-4;
  white-space: nowrap;
  padding: 10px 0;
  margin-top: 10px;
  border: 1px solid rgba(0,0,0,0.15);

  @include border-radius(2px);
  @include box-shadow(0 1px 2px rgba(0,0,0,0.07));

  > .um-dropdown-arrow {
    right: -7px;
    position: absolute;
    width: 50px;
    top: 0px;
    height: 10px;
    &:after, &:before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      z-index: $layer-3;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #fff;
      border-width: 10px;
      margin-left: -10px;
    }
    &:before {
      border-color: rgba(0, 0, 0, 0);
      border-bottom-color: rgba(0,0,0,0.2);
      border-width: 11px;
      margin-left: -11px;
    }
  }

  a {
    padding: 0px;
    text-align: left;
    line-height: 1.5;
  }

}
