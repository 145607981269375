.claim-ownership {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $light-green;
  border-radius: 0.5em;
  padding: 1.5em;

  .heading3 {
    margin-bottom: 0.5em;
  }

  p {
    font-size: 0.875em;
    margin-top: 0;
  }
}