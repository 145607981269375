// Element wrapper required because select element doesn't allow for pseudo elements
.form-select {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 2.5em;
    height: 100%;
    right: 0;
    top: 0;
    border-left: 1px solid $gray4;
    background-image: url('/assets/images/icons/chevron-down.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1em;
    pointer-events: none;
  }

  select {
    display: block;
    padding: 0.3125em 3.657em 0.3125em 0.75rem;
    font-size: 0.875em;
    font-weight: 300;
    line-height: 1.33rem;
    color: $black;
    margin: 0;
    border: 1px solid $gray4;
    border-radius: 0.25em;
    appearance: none;
    -webkit-appearance: none;
    background-color: $white;
    cursor: pointer;
    width: 100%;

    &:focus {
      border-color: $blue3;
      outline: 0;
    }

    &:disabled {
      color: lighten($gray9, 20%);
      border-color: lighten($gray4, 20%);
    }

    // Remove outline from select box in FF
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $gray9;
    }
  }

  & + .form-control,
  & + .form-select,
  & + .form-check {
    margin-top: 1.5em;
  }

  &.error select,
  select:invalid {
    &,
    &:focus {
      border-color: $pink3;
      box-shadow: none;
    }
  }
}
