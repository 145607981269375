.navigation {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: $light-blue;
  display: flex;
  flex-direction: column;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.25s ease-in-out;
  z-index: $layer-9;

  &.navigation-open {
    transform: translate3d(0, 0, 0);
  }

  @media (min-width: $breakpoint-large-tablet) {
    width: 15.125rem;
    transform: translate3d(0, 0, 0);
  }

  .navigation-top {
    display: flex;
    align-items: center;
    padding: 1rem;
    position: relative;
    min-height: 4.5rem;

    .bttn {
      @media (min-width: $breakpoint-large-tablet) {
        display: none;
      }
    }
  }

  .navigation-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    text-decoration: none;

    img {
      margin-right: 0.75rem;
    }

    span {
      font-family: 'bitstream-cooper-md';
      font-size: 1.125rem;
      color: $black;
    }
  }

  .form-select {
    margin: 0.5rem 1rem;

    select {
      width: 100%;
    }
  }

  ul {
    display: grid;
    gap: var(--grid-4);
    margin: 0;
    list-style: none;
    padding: 0;

    li {
      a,
      button {
        display: flex;
        @include font-lead-14;
        color: var(--color-signal-blue);
        font-family: $font-default;
        padding: 0.5rem;
        align-items: center;
        margin: 0 0.5rem;
        border-radius: 0.25rem;
        background: none;
        border: none;
        width: calc(100% - 1rem);
        text-decoration: none;
        cursor: pointer;

        &.active {
          background-color: var(--color-white);
        }

        &:not(.active):hover {
          background-color: rgba($white, 0.5);
        }

        svg:first-child {
          margin-right: 1rem;
          color: var(--color-grey-600);
        }

        svg.arrow {
          color: var(--color-grey-500);
          margin-left: auto;
          transition: transform 0.25s ease-in-out;
        }
      }

      // Children
      ul {
        display: none;

        li {
          a,
          button {
            font-size: 0.75rem;
            font-weight: 300;
            color: var(--color-black);
          }
        }
      }

      &.unfolded {
        .arrow {
          transform: rotate(90deg);
        }

        ul {
          display: block;
        }
      }
    }
  }

  .notification-badge {
    margin-left: 0.5rem;
  }

  .user-navigation {
    margin: 0.5rem;
    margin-top: auto;
  }
}
