.table {
  width: 100%;
  border-collapse: collapse;
  color: $black;

  &.table-simple {
    thead {
      background: none;
    }

    thead th,
    tfoot th {
      font-size: 1em;
      padding: 1em 0.5em;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    tbody tr {
      &:first-child td {
        border-top: 1px solid $grey-300;
      }

      td {
        font-size: 1em;
        border-bottom: 1px solid $grey-300;
        padding: 1em 0.5em;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  td,
  th {
    padding: 0.5rem;
    text-align: left;
  }

  thead {
    background: $bg-gray;
    font-weight: 700;
    font-size: 0.75rem;
  }

  tr {
    td {
      font-size: 0.875em;
      border-top: 1px solid $bg-gray;
      border-bottom: 1px solid $bg-gray;

      &:first-child {
        border-left: 1px solid $bg-gray;
      }

      &:last-child {
        border-right: 1px solid $bg-gray;
      }
    }
  }

  &-parent-row {
    cursor: pointer;

    .table-chevron svg {
      transition: transform 0.25s ease-in-out;
    }

    &.table-parent-row-expanded {
      .table-chevron svg {
        transform: rotate(90deg);
      }

      td {
        border-bottom: none;
      }
    }
  }

  tr.table-child-row {
    display: none;

    &.table-child-row-expanded {
      display: table-row;
    }

    td {
      font-size: 0.75rem;
      border-top: none;
    }
  }

  &-actions {
    div {
      display: flex;
      justify-content: end;

      > * {
        margin-left: 0.5rem;
      }
    }
  }
}
