@mixin button($background-color: $reco-blue) {
  background-color: $background-color;
  border: 1px solid shade($background-color, 10%);
  outline: 0;

  @if ($mode != 'critical') {
    @include background-image(linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1)));

    &:hover {
      background-color: shade($background-color, 5%);
      border: 1px solid shade($background-color, 15%);
    }

    &[disabled]:hover {
      background-color: $background-color;
      border: 1px solid shade($background-color, 10%);
    }
  }
}

@mixin bttn-type($type: primary) {
  @if $type == selector {
    border-radius: 1.25rem;
    color: $grey-600;
    border-color: $grey-100;
    background-color: $grey-100;
  }
}

// @todo
// make sure that ghost is part of
// the [Design system] Button component
//
// NOTE: this mixin and setup for buttons as a component structure is convoluted and messy
// It cannot be used fully without the .bttn utility class
// A mixin like this should include:able anywhere.
@mixin bttn($fullwidth: false, $hasIcon: false, $hasShadow: true) {
  display: inline-flex;
  align-items: center;

  font-family: $font-default;
  font-size: $default-font-size;
  font-weight: 300;
  line-height: 1.25rem;
  text-decoration: none;

  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 2px solid transparent;
  cursor: pointer;

  @if $hasShadow {
    @include shadow;

    &:hover {
      @include shadow(hover);
    }
  }

  &[aria-disabled],
  &:disabled {
    cursor: default;
  }

  > .svg-inline--fa {
    margin-right: 4px;
  }

  &.bttn-icon-right > .svg-inline--fa {
    margin-right: 0;
    margin-left: 4px;
  }

  &.bttn-icon-only > .svg-inline--fa {
    margin-right: 0;
  }

  &.bttn-icon-lg {
    > .svg-inline--fa {
      font-size: 22px;
    }

    &,
    &.bttn-primary,
    &.bttn-secondary,
    &.bttn-ghost,
    &.bttn-dense,
    &.bttn-success {
      padding: 7px 15px;
    }
  }

  &.bttn-primary {
    color: $white;
    background-color: $reco-blue;
    border-color: $reco-blue;
    font-weight: 700;

    &[aria-disabled],
    &:disabled {
      background: $blue4;
    }
  }

  &.bttn-secondary {
    color: $grey-600;
    background-color: $grey-100;

    &:hover {
      color: $white;
      background: $reco-blue;
      border-color: $reco-blue;
    }

    &.bttn-sm {
      padding: 4px 8px;
    }

    &[aria-disabled],
    &:disabled {
      background: $bg-gray;
      border-color: $grey-400;
      color: $grey-400;
    }
  }

  &.bttn-selector {
    @include bttn-type(selector);

    &:hover:not(.bttn-active) {
      color: $white;
      background: $reco-blue;
      border-color: $reco-blue;
    }

    // use `active` state or `disable`?
    &.bttn-active {
      font-weight: 300;
      border-color: $grey-400;
      background-color: $grey-300;
      box-shadow: 0px 2px 2px 0px rgba($black, 0.25) inset;
    }
  }

  &.bttn-ghost {
    color: $black;
    border-color: transparent;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;

    &:hover {
      font-weight: 700;
      box-shadow: none;
    }

    &[aria-disabled],
    &:disabled {
      color: $grey-400;
    }
  }

  &.bttn-dense {
    color: $black;
    font-weight: 300;
    border: 1px solid $grey-400;
    background-color: $white;

    &:hover {
      background-color: $gray11;
      border: 1px solid $gray11;
    }

    &[aria-disabled],
    &:disabled {
      background: lighten($gray11, 20%);
    }
  }

  &.bttn-smoke {
    color: $black;
    font-weight: 300;
    border: 1px solid $gray7;
    background-color: $gray7;

    &:hover {
      background-color: $gray11;
      border: 1px solid $gray11;
    }

    &[aria-disabled],
    &:disabled {
      color: lighten($black, 20%);
    }
  }

  &.bttn-success {
    color: $black;
    font-weight: 300;
    background-color: $light-green;
    border: 1px solid $light-green;

    > .svg-inline--fa {
      color: rgba(0, 0, 0, 0.3);
    }

    &:hover {
      background-color: darken($light-green, 20%);
      border: 1px solid darken($light-green, 20%);
    }

    &[aria-disabled],
    &:disabled {
      background: lighten($light-green, 5%);
      border: 1px solid lighten($light-green, 5%);
    }
  }

  &.bttn-link {
    color: $blue;
    border: none;
    border-radius: 0;
    background-color: transparent;
    padding: 0;
    margin: 0;
    display: inline;
    font-weight: 300;
    text-decoration: underline;
    box-shadow: none;

    &:hover {
      font-weight: 300;
      cursor: pointer;
    }

    &[aria-disabled],
    &:disabled {
      color: $gray4;
    }
  }

  &.bttn-rounded {
    border-radius: 2.5em;

    &.bttn-sm {
      border-radius: 1.5em;
    }
  }

  &.bttn-spaced {
    justify-content: space-between;
  }

  &.bttn-content-center {
    text-align: center;
    justify-content: center;
  }

  &.bttn-active {
    font-weight: 700;

    &.bttn-secondary {
      color: $white;
      background-color: $reco-blue;

      &:hover {
        background-color: $blue2;
        border-color: $blue2;
      }
    }

    &.bttn-dense {
      font-weight: 300;
      background-color: $gray11;
      border: 1px solid $gray11;
    }

    &.bttn-ghost {
      border-bottom: 2px solid $light-pink;
    }
  }

  &.bttn-center {
    text-align: center;
  }

  &.bttn-thin {
    font-weight: 300;
  }

  &.bttn-sm {
    font-size: 12px;
    font-weight: 400;
    padding: 6px 8px;
  }

  &.bttn-md {
    font-size: 14px;
    padding: 8px 10px;
  }

  .form-control + &,
  .form-select + &,
  .form-check + & {
    margin-top: 1rem;
  }

  &.bttn-full-width {
    width: 100%;
    justify-content: center;
  }

  @if $fullwidth == true {
    width: 100%;
    justify-content: center;
  }

  @if $hasIcon == true {
    gap: 0.5rem;
  }
}
