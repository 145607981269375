@mixin foldout-link {
  padding: 0.5rem 0.75rem;
  border-radius: 8px;

  &:hover {
    background-color: rgba($white, 0.2);
  }

  [data-icon='chevron-down'] {
    transition: transform 0.2s linear;
  }

  &.active {
    [data-icon='chevron-down'] {
      transform: rotate(180deg);
    }
  }
}
