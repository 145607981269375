.shared-buttons-Button {
  @include font-paragraph-14;
  @include font-family-body;
  @include shadow;

  display: flex;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
  word-break: break-word;

  border-radius: var(--border-radius-default);
  padding: 0.5rem 1rem;
  height: 2.5rem;
  border: 0;
  cursor: pointer;

  &.primary {
    background-color: $reco-blue;
    color: $white;

    &:hover,
    &:active {
      background-color: $signal-blue;
    }
  }

  &.secondary {
    background-color: $grey-100;
    color: $black;

    &:hover,
    &:active {
      background-color: $grey-200;
    }
  }

  &.link {
    color: $blue;
    border: none;
    border-radius: 0;
    background-color: transparent;
    padding: 0;
    margin: 0;
    display: inline;
    font-weight: 300;
    text-decoration: underline;
    box-shadow: none;
    height: auto;

    &:hover {
      font-weight: 300;
      cursor: pointer;
    }

    &[aria-disabled],
    &:disabled {
      color: $gray4;
    }
  }

  &.loading {
    &.primary {
      &[disabled] {
        cursor: initial;

        &,
        &:hover {
          background-color: var(--color-reco-blue);
          filter: brightness(1.2);
          color: var(--color-grey-400);
        }
      }
    }
    &.secondary {
      &[disabled] {
        cursor: initial;

        &,
        &:hover {
          background-color: var(--color-grey-200);
          color: var(--color-grey-400);
        }
      }
    }
  }

  &[disabled]:not(.loading) {
    cursor: initial;

    &,
    &:hover {
      background-color: $grey-200;
      color: $grey-400;
    }
  }

  // a tag
  @at-root a#{&} {
    display: inline-block;
    text-align: center;
    text-decoration: none;
  }

  width: fit-content;

  &.stretch-all {
    width: 100%;
  }

  &.stretch-small-only {
    width: 100%;

    @include breakpoint($breakpoint-reco) {
      width: fit-content;
    }
  }

  &.stretch-large-only {
    @include breakpoint($breakpoint-reco) {
      width: 100%;
    }
  }

  &.small,
  &.xsmall {
    padding: 0.25rem 0.5rem;
    height: 2rem;
  }

  &.xsmall {
    &,
    .button-icon {
      font-size: 0.75rem;
    }
  }

  .button-icon {
    font-size: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
  }
}
