.map {
  display: flex;
  flex-direction: column;
  
  .heading2 {
    align-self: center;
    text-align: center;
  }

  p {
    text-align: center;
    margin-top: 0;
  }

  &--static {
    max-height: 9.6875em;
    overflow: hidden;
    display: flex;
    align-items: center; 
    justify-content: center;
    margin-bottom: 1em;

    img {
      width: 100%;
      height: auto;
    }
  }

  &--buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}