.notification-badge {
  display: inline-block;
  padding: 0.2rem 0.37rem;
  border-radius: 1rem;
  line-height: 1;
  font-size: 0.75rem;
  font-weight: 700;
  color: $white;
  letter-spacing: 0.03em;
}

.notification-badge-secondary {
  color: $gray9;
  background-color: $gray4;
}

.notification-badge-danger {
  background-color: $pink3;
}