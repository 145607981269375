.button-bar {
  display: flex;
  justify-content: center;
  padding: $margin-medium;
  background-color: $bg-gray;
  background-image: url(/assets/images/question-mark-bg.svg), url(/assets/images/review-bg.svg);
  background-position: -38px center, 134% center;
  background-repeat: no-repeat;

  @media (min-width: $breakpoint-small-tablet) {
    background-position: left center, right center;
  }
}