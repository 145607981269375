.snackbar-wrapper {
  display: flex;
  align-items: center;
  padding: 1rem;
  position: fixed;
  right: 0;
  left: 0;
  z-index: $layer-1;
  transition: opacity 150ms, transform 150ms;
  pointer-events: none;
  bottom: 0;
  justify-content: flex-end;
}

.snackbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0.5rem;
  min-width: 20rem;
  max-width: 40rem;
  background-color: $light-green;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.2);
  font-size: 0.875em;
  font-weight: 300;
  color: $black;
  letter-spacing: 0.25px;
  line-height: 1.33;
}

@media (max-width: 480px), (max-width: 344px) {
  .snackbar {
    min-width: 100%;
  }
}

.snackbar-close {
  display: inline-block;
  margin-left: 0.5em;
}

.snackbar-text {
  flex-grow: 1;
  padding: 1.375em;
  margin: 0;
  pointer-events: auto;
}

/* CSSTransition classNames logic */
.snackbar-enter,
.snackbar-exit-active {
  opacity: 0;
}

.snackbar-enter-active {
  opacity: 1;
}

.snackbar-exit-active-bottom-right {
  transform: translateY(16px);
}

.snackbar-enter-active-bottom-right {
  transform: translateY(0px);
}
