/**
* Breakpoint mixin that simplifies media queries
* Mobile first: Need to be simple and only support `min-width`
* Usage: @include breakpoint($breakpoint-portrait-tablet)
*/
@mixin breakpoint($breakpoint-value, $medium: 'screen') {
  @media #{$medium} and (min-width: $breakpoint-value) {
    @content;
  }
}
