// Typeahead
.twitter-typeahead {
  vertical-align: top;
  background: #fff;
  @include border-radius(3px);
}

.typeahead, .tt-query, .tt-hint {
  height: 20px;
  min-width: 200px;
  padding: 8px 12px;
  line-height: 20px;
  border: 2px solid #ccc;
  border-radius: 8px;
  outline: none;
}

.typeahead:focus {
  border: 2px solid #0097cf;
}

.tt-query {
  @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.075));
}

.tt-hint {
  color: #999999;
  visibility: hidden;
}

.tt-menu {
  min-width: 300px;
  padding: 0;
  border: 1px solid #ccc;
  background: #fff;
  text-align: left;
  @include box-shadow(0 5px 10px rgba(0, 0, 0, 0.2));
}

.tt-suggestion {
  line-height: 24px;
  padding: 8px 20px;
  cursor: pointer;

  &.tt-cursor {
    color: #fff;
    background-color: #0097cf;

    .dgray,
    .gray,
    a:hover {
      color: #fff !important;
    }
  }

  p {
    margin: 0;
  }

  + .tt-suggestion {
    border-top: 1px solid #ccc
  }
}
