@mixin form-layout {
  form {
    display: grid;
    gap: var(--grid-24);
  }

  @include breakpoint($breakpoint-reco) {
    padding: 1.5rem;
    border: 1px solid var(--color-grey-400);
    border-radius: var(--border-radius-default);
  }
}
