.d-none {
  display: none !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-table-header-group {
  display: table-header-group !important;
}

@media (min-width: $breakpoint-mobile) {
  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }
  
  .d-sm-table-row {
    display: table-row !important;
  }
  
  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-table-header-group {
    display: table-header-group !important;
  }

  .d-sm-none {
    display: none !important;
  }
}

@media (min-width: $breakpoint-portrait-tablet) {
  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }
  
  .d-md-table-row {
    display: table-row !important;
  }
  
  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-table-header-group {
    display: table-header-group !important;
  }

  .d-md-none {
    display: none !important;
  }
}

@media (min-width: $breakpoint-reco) {
  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }
  
  .d-lg-table-row {
    display: table-row !important;
  }
  
  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-table-header-group {
    display: table-header-group !important;
  }

  .d-lg-none {
    display: none !important;
  }
}

@media (min-width: $breakpoint-tablet) {
  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }
  
  .d-xl-table-row {
    display: table-row !important;
  }
  
  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-table-header-group {
    display: table-header-group !important;
  }

  .d-xl-none {
    display: none !important;
  }
}

@media (min-width: $breakpoint-reco-large) {
  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-table {
    display: table !important;
  }
  
  .d-xxl-table-row {
    display: table-row !important;
  }
  
  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-table-header-group {
    display: table-header-group !important;
  }

  .d-xxl-none {
    display: none !important;
  }
}