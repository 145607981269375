.profile-badges {
  &-hidden {
    .profile-sidebar & {
      display: none;
    }
  }

  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-landscape-tablet) {
    align-items: center;
    flex-direction: row;
  }

  .profile-card--rating {
    align-items: flex-start;
    flex-direction: column;

    @media (min-width: $breakpoint-landscape-tablet) {
      align-items: center;
      flex-direction: row;
    }

    .reco-rating {
      margin: 0.3em 0;

      @media (min-width: $breakpoint-landscape-tablet) {
        margin: 0 0.571em 0 0;
      }
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;

    @media (min-width: $breakpoint-landscape-tablet) {
      margin-left: auto;
      margin-top: 0;
    }

    &-image {
      position: relative;
      height: 3.75rem;

      button {
        display: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
      }

      &:hover {
        button {
          display: block;
        }
      }
    }

    &-image,
    > button {
      margin-right: 0.5rem;

      @media (min-width: $breakpoint-landscape-tablet) {
        margin-left: 0.5rem;
        margin-right: 0;
      }
    }

    img {
      width: 4rem;
    }

    > button {
      border: none;
      background: none;
      color: $blue5;
      cursor: pointer;
    }
  }
}
