.table-toolbar {
  display: flex;
  flex-wrap: wrap;
  padding: 0.625em;
  border: 1px solid $gray7;
  border-bottom: none;
  align-items: center;
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;

  &.table-toolbar-active {
    background-color: $gray12;
  }

  &.table-toolbar-without-background.table-toolbar-active {
    background-color: transparent;
  }

  &-label {
    font-size: 0.875em;
    color: $gray9;
    display: block;
    margin-right: 1rem;
  }

  &-filter {
    display: flex;
    align-items: center;
    flex: 1;
    margin-right: 1em;

    span {
      white-space: nowrap;
    }

    input {
      width: 100%;
      max-width: 18.75rem;
    }
  }

  &-actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    order: 3;
    justify-content: space-between;
    width: 100%;
    margin-top: 1em;

    @media (min-width: $breakpoint-reco) {
      order: inherit;
      justify-content: flex-start;
      width: auto;
      margin-top: 0;
    }
    
    > * {
      margin-right: 0.5em;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-align-right {
    margin-left: auto;

    &:not(.bttn-icon-lg) > .svg-inline--fa {
      margin-right: 0;

      @media (min-width: $breakpoint-mobile) {
        margin-right: 4px;
      }
    }
  }
}