.alert {
  font-size: 14px;
  font-weight: 300;
  color: $black;
  padding: 1.5rem;
  position: relative;
  border-radius: var(--border-radius-default);

  &,
  .outer-wrap {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .outer-wrap {
    padding-top: 0;
    padding-bottom: 0;
  }

  img {
    margin-right: 1.5rem;
    display: none;

    @media (min-width: $breakpoint-small-tablet) {
      display: block;
    }
  }

  .heading {
    margin-bottom: 0.5em;
  }

  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;
    font-weight: 700;
    color: $blue3;

    &:hover {
      text-decoration: underline;
    }
  }

  b,
  strong {
    font-weight: 700;
  }

  strong {
    display: block;
  }

  &-close {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
  }
}

.alert-sm {
  font-size: 12px;
  padding: 0.5rem 1em;
}

.alert-folded {
  p {
    display: none;

    &:first-child {
      display: block;
      margin: 0;
    }
  }
}

.alert-success {
  background-color: $light-green;

  &.alert-folded:after {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $light-green 60%);
  }
}

.alert-info {
  background-color: $light-blue;

  &.alert-folded:after {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $light-blue 60%);
  }
}

.alert-warning {
  background-color: $light-yellow;

  &.alert-folded:after {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $light-yellow 60%);
  }
}

.alert-danger {
  background-color: $light-pink;

  &.alert-folded:after {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $light-pink 60%);
  }
}
