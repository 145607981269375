.badge-list-dialog {
  &-badges {
    width: 100%;
    max-width: 22rem;
    margin: 0;
    padding: 0;
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(6rem, 3fr));

    li {
      list-style: none;
      position: relative;

      img {
        width: 100%;
      }

      button {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        button {
          display: block;
        }
      }
    }
  }
}
