@mixin shadow($type: soft) {
  @if $type == soft {
    box-shadow: 0px 1px 3px 0px rgba($black, 0.15);
  }

  @if $type == hover {
    box-shadow: 0px 3px 3px 0px rgba($black, 0.1);
  }

  @if $type == inset {
    box-shadow: 0px 0px 4px 0px rgba($black, 0.4) inset;
  }
}

$shadow-sm: 0px 0px 4px rgba($black, 0.16);
$shadow-md: 0px 0px 8px rgba($black, 0.08);
$shadow-lg: 0px 0px 24px rgba($black, 0.08);
