.overview-widgets {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  margin-bottom: 1.5rem;

  @include breakpoint($breakpoint-portrait-tablet) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .overview-widgets-widget {
    width: 100%;

    @include breakpoint($breakpoint-portrait-tablet) {
      max-width: calc(50% - 0.75rem);
    }

    @include breakpoint($breakpoint-reco-large) {
      max-width: calc(33.3333% - 1rem);
    }
  }
}

.overview-header {
  font-family: 'bitstream-cooper-md';
  font-weight: 500;
  color: $black;

  &.header-subtitle {
    font-family: $font-default;
    color: $grey-500;
    font-weight: 200;
    font-size: 1rem;
    margin-top: 4px;
    margin-bottom: 4rem;
  }
}
