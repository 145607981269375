.admin-bar {
  margin-right: -300px;
  width: 300px;
  position: fixed;
  bottom: 0;
  right: 0;

  background: #024;
  z-index: $layer-9;

  @include transition(margin-right ease-in-out 0.2s);

  &:before {
    z-index: $layer-8;
    display: block;
    content: ' ';
    width: 20px;
    height: 20px;
    position: fixed;
    right: 0;
    background: #024;
    bottom: 0;
    cursor: pointer;
    border: 12px solid #024;
    background: linear-gradient(
      to bottom,
      white, white 20%,
      #024 20%, #024 40%,
      white 40%, white 60%,
      #024 60%, #024 80%,
      white 80%, white 100%
    );

    @include transition(opacity ease-in-out 0.2s);

  }
}

.admin-bar:hover {
  margin-right: 0;

  &:before {
    opacity: 0;
  }
}
