.organization-Todos {
  display: grid;
  gap: var(--grid-8);

  .todos-container {
    display: grid;
    gap: var(--grid-8);
  }

  .todos-heading {
    @include font-lead-14;
    color: var(--color-grey-600);
  }
}

.organization-Todos--Todo {
  display: grid;
  gap: var(--grid-8);
  justify-content: space-between;
  background-color: var(--color-grey-200);
  padding: 1rem;
  border-radius: var(--border-radius-default);
  grid-template-columns: auto 1fr auto;
  align-items: center;

  .todo-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .todo-action {
    a {
      display: block;

      text-decoration: none;
      color: var(--color-signal-blue);
    }

    button {
      @include font-family-body;
      @include font-paragraph-14;

      background-color: transparent;
      border: none;
      padding: 0;
      color: var(--color-signal-blue);
      cursor: pointer;
    }
  }
}


.complete {
  text-decoration: line-through;
}
