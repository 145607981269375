.filter {
  width: 14.5rem;

  &-button {
    width: 100%;
  }

  .form-control-button {
    .bttn {
      color: $blue1;
    }
  }

  &-text {
    .form-control {
      margin-bottom: 1.25rem;
    }

    > button {
      margin-top: 1rem;
    }

    &-list {
      button {
        display: flex;
        margin: 0.5rem 0;

        svg {
          color: $blue3;
        }
      }
    }
  }
}