.fortnox-confirm {
  @media (min-width: $breakpoint-portrait-tablet) {
    padding-top: 3rem;
  }

  &-container {
    min-height: 25rem;
  }

  &-inner {
    display: grid;
    place-items: center;
  }

  &-content {
    display: flex;
    flex-direction: column;
    max-width: 21rem;
    width: 100%;
    border-radius: 0.5rem;
    background-color: $white;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
    padding: 1rem;
  }

  &-loader {
    padding-top: 0.75rem;
    display: flex;
    flex-direction: column;
  }

  p {
    font-size: 0.875rem;
    line-height: 1.57;
    color: $black;
  }

  .heading2 {
    align-self: center;
  }

  .bttn {
    width: 100%;
  }

  .invalid-feedback {
    padding: 0.25rem 0;
  }

  &-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 19rem;
    width: 100%;
    padding-top: 3rem;

    &-logotypes {
      display: flex;
      justify-content: space-between;
      width: 100%;

      img {
        width: 7.5rem;
      }
    }
  }
}
