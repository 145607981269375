.prepared-invites {
  display: grid;
  gap: var(--grid-24);
  height: fit-content;
  width: 100%;

  .prepared-invites-actions {
    display: flex;
    flex-wrap: wrap;

    .bttn {
      margin: 0 0.5rem 0.5rem 0;

      strong {
        margin-left: 0.5em;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .prepared-invites-sorting {
    font-size: 0.875em;

    a {
      display: inline-flex;
      align-items: center;

      svg {
        width: 0.65em;
        margin-left: 0.25em;
      }
    }

    &-desc {
      svg {
        transform: rotate(180deg);
      }
    }

    &-active {
      font-weight: 700;
    }
  }

  .prepared-invites-attention-body {
    p {
      @include font-paragraph-14;
    }
  }

  .prepared-invites-extras {
    border: 1px solid $gray7;
    border-bottom: none;
    border-top-left-radius: 0.25em;
    border-top-right-radius: 0.25em;
    padding: 0.625em;
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-small-tablet) {
      flex-direction: row;
    }

    label {
      display: flex;
      align-items: center;
      font-size: 0.875em;
      cursor: pointer;
      margin-top: 1em;

      @media (min-width: $breakpoint-small-tablet) {
        margin-top: 0;
        margin-left: auto;
      }

      input {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  .prepared-invites-col-select {
    width: 2.25em;
  }

  .prepared-invites-col-firstname {
    width: auto;

    @media (min-width: $breakpoint-reco-large) {
      width: 17%;
    }

    > div {
      color: $gray14;
    }

    &-extended {
      color: $gray9;
    }
  }

  .prepared-invites-col-lastname {
    width: 17%;
  }

  .prepared-invites-col-email {
    width: 17%;
  }

  .prepared-invites-col-address {
    width: 22%;
  }

  .prepared-invites-col-type {
    width: 19%;
  }

  .prepared-invites-col-chevron {
    width: 2.25em;

    button {
      background: none;
      border: none;
      cursor: pointer;
      width: 100%;
      height: 100%;
    }
  }

  .prepared-invites-empty {
    h3 {
      margin: 0;
    }

    p {
      margin: 1em 0 0;
    }
  }

  .prepared-invites-warning {
    color: $pink3;
  }

  .table-child-row td span {
    display: block;
    color: $gray9;
  }

  .table-pagination {
    border-radius: 0;
  }

  .mobile-details {
    border-right: 1px solid $bg-gray;

    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;
      padding: 0.75em 0;
      margin-top: 0;
      border-top: 1px solid $gray7;
      border-bottom: 1px solid $gray7;
    }

    &-actions {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &.mobile-details-borderless {
      ul {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  .prepared-invites-submit {
    border: 1px solid $gray7;
    border-top: none;
    border-bottom-left-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
    padding: 0.625em;

    .bttn {
      width: 100%;

      @media (min-width: $breakpoint-mobile) {
        width: auto;
      }
    }
  }

  .opt-out-chip > div {
    height: auto;
    border-radius: 9999px;
    padding: 0 0.375rem;
    padding-right: 0.5rem;
    gap: 0.25rem;
    font-size: 0.75rem;
    letter-spacing: -0.025em;
    line-height: 1.5rem;

    svg {
      font-size: 0.875rem;
    }
  }
}
