.send-invites-form {
  .heading2 {
    width: 100%;
  }

  .heading3 {
    width: 100%;
    margin-bottom: 1em;
  }

  .heading5 {
    margin-bottom: 0;
  }

  &-intro {
    font-size: 0.875em;
    color: $black;
  }

  &-input {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0 0 1.25em;

    @media (min-width: $breakpoint-landscape-tablet) {
      flex-direction: row;
      justify-content: space-between;
    }

    li {
      margin-bottom: 1.25em;

      &:last-child {
        margin-bottom: 0;
      }

      @media (min-width: $breakpoint-landscape-tablet) {
        width: calc(33.333% - 0.8125em);
        margin-bottom: 0;
      }
    }
  }

  &-phone-warning,
  &-generic-error {
    margin-bottom: 1rem;
  }

  &-table {
    width: 100%;
    min-width: 36em;
    color: $black;
    margin-bottom: 1.375em;

    &-wrapper {
      overflow-x: scroll;
    }

    tr {
      th,
      td {
        padding: 0.25rem 0.375em;
        font-size: 0.875em;
        width: 33.333%;

        &.send-invites-form-table-spacer {
          width: 1.25rem;
          min-width: 1.25rem;
          background-color: transparent;
        }
      }

      th {
        font-size: 0.875em;
        font-weight: 300;
        text-align: left;
      }

      td {
        background-color: $light-green;

        &.send-invites-form-invalid {
          background-color: $light-pink;
        }
      }
    }
  }

  &-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (min-width: $breakpoint-portrait-tablet) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }

    .loader-circular {
      margin: 0 1em;
    }

    .form-check + .bttn {
      @media (min-width: $breakpoint-portrait-tablet) {
        margin-top: 0;
        margin-left: 1em;
      }
    }
  }

  &-not-sent-list {
    margin-bottom: 1rem;

    p {
      margin: 0;
    }
  }
}
