.location-search-action {
  visibility: hidden;
  cursor: pointer;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 17px;
  background-color: transparent;
  background-image: url('/assets/images/search/location-icon.svg');
  width: 2rem;
  height: calc(100% - 4px);
  right: 0.125rem;
  top: 2px;
  border: 0;
}
