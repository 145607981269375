input[type='number'],
input::-webkit-inner-spin-button {
  /* Hide spinner in WebKit browsers for number inputs */
  -webkit-appearance: none;
}

.select {
  width: 100%;
  display: block;
  padding: 5px ($input-block-padding + 20px) 5px $input-block-padding;
  height: 40px;
  @if ($mode == 'critical' or $mode == 'all') {
    margin: 0;
  }
  border: 1px solid $input-block-border;
  font-family: 'Roboto', HelveticaNeue, sans-serif;
  font-size: $input-font-size;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  line-height: 2;
  background-repeat: no-repeat, repeat;
  background-position: right 15px center, center center;
  background-size: auto, 100%;

  @if ($mode != 'critical') {
    @include transition(all linear 0.05s);
    @include box-shadow(0 0 2px rgba(0, 0, 0, 0));
    @include border-radius(2px);
    @include background-image(
      url('/assets/images/icons/dropdown-arrow.png'),
      linear-gradient(to bottom, $input-select-bg, shade($input-select-bg, 5%))
    );
  }

  &:hover,
  &:focus {
    border-color: shade($input-block-border, 10%);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  }

  &:focus:hover,
  &:focus {
    border-color: lighten($blue, 15%);
  }

  &.js-validation-error {
    border-color: $input-error-border-color;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
}

.input {
  width: 100%;
  padding: 0 $input-block-padding;
  height: 40px;
  line-height: 1.2em;
  background: $input-bg;
  font-size: $input-font-size;
  font-family: 'Roboto', HelveticaNeue, sans-serif;
  outline: 0;
  @if ($mode == 'critical' or $mode == 'all') {
    margin: 0;
  }
  border: 1px solid $input-block-border;
  -webkit-appearance: none;
  vertical-align: middle;
  text-overflow: ellipsis;

  @if ($mode != 'critical') {
    @include transition(box-shadow linear 0.05s, border-color linear 0.05s);

    &.rounded {
      @include border-radius(3px);
    }

    &:hover {
      @include box-shadow(2px 2px 3px rgba(0, 0, 0, 0.05) inset);
      border-color: shade($input-block-border, 10%);
    }

    &:focus:hover,
    &:focus {
      border-color: lighten($blue, 15%);
      @include box-shadow(2px 2px 2px rgba(0, 0, 0, 0.05) inset);
    }
  }

  &[disabled] {
    opacity: 0.4;
  }

  &.mono {
    font-family: 'San Fransisco Mono', monospace;
  }
}

.input--icon-l {
  position: relative;

  > .input {
    padding-left: 34px;
  }

  &.icon:before {
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -0.5em;
  }
}

.search-input-front {
  width: 100%;
  padding: 7px 21px;
  line-height: 2;
  background: $input-bg;
  font-size: 16px;
  font-family: 'Roboto', HelveticaNeue, sans-serif;
  color: $text-color;
  height: 48px;
  outline: 0;
  margin: 0;
  border: 0;
  -webkit-appearance: none;
  vertical-align: middle;
  text-overflow: ellipsis;

  &[disabled] {
    background: #ddd;
  }

  &.bordered {
    border: 1px solid #ccc;
  }

  @if ($mode != 'critical') {
    @include transition(box-shadow linear 0.1s, border-color linear 0.05s);
    @include box-shadow(2px 2px 3px rgba(0, 0, 0, 0.1) inset);
    @include border-radius(27px);

    &:hover {
      @include box-shadow(2px 2px 3px rgba(0, 0, 0, 0.05) inset);
      border-color: shade($input-block-border, 10%);
    }

    &:focus:hover,
    &:focus {
      border-color: #dc7;
      @include box-shadow(0 0 6px #fea);
    }

    &.with-icon {
      background-image: url(/assets/images/icons/searchicon.png);
      background-repeat: no-repeat;
      background-position: center right;
    }
  }
}

textarea.input,
.input-textarea {
  border-width: 3px;
  border-color: #b3e5f5;
  box-shadow: 0;
  resize: none;
  min-height: 120px;

  &:hover {
    border-color: #b3e5f5;
  }

  &:hover:focus,
  &:focus {
    box-shadow: none;
  }
}

.input-b {
  width: 100%;
}

textarea.input {
  line-height: 19px;
  padding: $input-block-padding;
}

.input.shadowed {
  @if ($mode != 'critical') {
    @include box-shadow(2px 2px 2px rgba(0, 0, 0, 0.3) inset);
  }
}

.input.input-as {
  width: auto;
}

.input.bordered {
  font-size: $input-font-size;
  height: 50px;
  line-height: normal;

  @if ($mode != 'critical') {
    @include box-shadow(inset 1px 1px 1px 0px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.5));

    &:focus {
      @include box-shadow(0 0 1px #dc7, 0 0 6px #fea);
    }

    &::-webkit-input-placeholder {
      font-style: italic;
    }

    &::-moz-placeholder {
      font-style: italic;
    }

    &:-ms-input-placeholder {
      font-style: italic;
    }
  }
}

@if ($mode != 'critical') {
  input.input.input-search {
    background-image: url(/assets/images/icons/searchicon.png);
    background-repeat: no-repeat;
    background-position: center right;
  }

  .input.indented {
    padding-left: 90px;
  }
  .input.js-validation-success {
    background: url(/assets/images/topForm_input_check.png) #efe no-repeat right center;
    border: 1px solid #0a0;
  }

  .input.js-validation-error,
  textarea.js-validation-error,
  input.js-validation-error {
    background: url(/assets/images/topForm_input_error.png) $input-bg no-repeat right center;
    @include box-shadow(none);
    border-color: $input-error-border-color;
    &:hover {
      border-color: $input-error-border-color;
    }
    outline: 0;

    &:focus {
      border-color: lighten($red, 15%);
      @include box-shadow(none);
    }
  }
  textarea.input.js-validation-success,
  textarea.input.js-validation-error {
    background-position: right 14px;
  }
  .input.js-validation-error,
  .input.js-validation-success {
    padding-right: $input-block-padding + 25px;
  }
  .input.js-validation-error.indented {
    padding-left: 88px;
  }
}

.b-checkbox {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 5px;
  margin-top: -5px;

  span {
    width: 18px;
    height: 18px;
    display: inline-block;
    border: 2px solid #3c99fc;
    border-radius: 2px;
    line-height: 13px;
    overflow: hidden;
  }

  input {
    display: none;
  }
  input:checked + span {
    background: #3c99fc;
  }
}

/* Updated inputs */

.input-wrap {
  color: $reco-blue;
  margin-right: 1em;
  position: relative;

  &.geolocation {
    .input {
      padding-right: 1.5rem;
    }
  }
}

.input {
  display: block;
  height: 2.285714286em;
  width: 100%;
  font-style: normal;
  font-weight: 300;
  padding-right: 0.75em;
  padding-left: 0.75em;
  padding-top: 0.125em;
  padding-bottom: 0.125em;
  border-radius: 0.25em;
  font-family: 'Roboto', HelveticaNeue, sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:placeholder {
    color: $gray9;
  }

  &:hover,
  &:focus,
  &:active {
    outline: none;
    border-color: $blue6;
    box-shadow: none;
  }
}

label {
  &.location-icon {
    &:after {
      content: '';
      cursor: pointer;
      position: absolute;
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 17px;
      background-color: rgba($white, 0.8);
      background-image: url('/assets/images/search/location-icon.svg');
      width: 1.875rem;
      height: calc(100% - 0.75rem);
      right: 3px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
