.organization-SendInvites {
  display: grid;
  gap: var(--grid-24);
  height: fit-content;
  width: 100%;

  .form-container {
    @include form-layout;
  }
}
