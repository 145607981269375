.msg-green {
  background: $bg-light-green;
  border-bottom: 1px solid darken($bg-light-green, 20%);
  color: darken($bg-light-green, 70%);
}

.msg-red {
  background: $bg-light-red;
  border: 1px solid darken($bg-light-red, 10%);
  color: darken($bg-light-red, 70%);
}

.msg-yellow {
  background: lighten(#fcf0b0, 5%);
  border: 1px solid darken(#fcf0b0, 10%);
  color: darken(#fcf0b0, 70%);
}

.msg {
  padding: 15px;
}
