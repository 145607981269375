/* Lined item */
.llitem {
  border-top: 1px solid transparent;
  list-style-type: none;

  &.ll-lgray {
    border-color: #f0f0f0;
  }
  &:first-child {
    border-top: 0;
  }
}

.llitemb {
  border-bottom: 1px solid #f0f0f0;
  list-style-type: none;

  &:last-child {
    border-bottom: 0;
  }
}
