:root {
  --rating-size: 1.5rem;
  --rating-gap: 0.25rem;
}

.venue-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
  background-color: white;
  scroll-margin-top: 9.5rem;
  position: relative;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    padding: 1.5rem;
    border-radius: 0.5rem;
    scroll-margin-top: 5.5rem;
  }
}

.venue-hgroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--color-grey-200);

  &--wide {
    padding: 0 1.5rem 1.5rem;
    margin: 0 -1.5rem;
  }

  p {
    margin: 0;
    color: var(--color-grey-500);
  }
}

[class*='venue-heading'] {
  margin: 0;
  font-family: 'bitstream-cooper-md';
  font-weight: 400;
}

.venue-heading-1 {
  font-size: 1.25rem;
  line-height: 1.5rem;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

.venue-heading-2 {
  font-size: 1.125rem;
  line-height: 1.5rem;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.venue-heading-3 {
  font-size: 1.125rem;
  line-height: 1.5rem;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

.venue-heading-4 {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.venue-ratings {
  display: flex;
  gap: var(--rating-gap);
  align-items: center;

  span,
  ins,
  em {
    aspect-ratio: 1/1;
    background-size: cover;
    height: var(--rating-size);
  }

  span {
    background-image: url('/assets/images/rating/rating-4.svg');
  }

  ins {
    background-image: url('/assets/images/rating/rating-2.svg');
  }

  em {
    background-image: url('/assets/images/rating/rating-0.svg');
  }
}

.venue-rating-0 {
  background-image: url('/assets/images/rating/rating-0.svg');
}

.venue-rating-1 {
  background-image: url('/assets/images/rating/rating-1.svg');
}

.venue-rating-2 {
  background-image: url('/assets/images/rating/rating-2.svg');
}

.venue-rating-3 {
  background-image: url('/assets/images/rating/rating-3.svg');
}

.venue-rating-4 {
  background-image: url('/assets/images/rating/rating-4.svg');
}

.venue-nav-list {
  display: grid;
  color: var(--color-reco-blue);

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.5rem;
  }
}

.venue-clean-link {
  color: inherit;
  text-decoration: none;
}

.venue-link {
  font: inherit;
  background: none;
  border: none;
  text-decoration: none;
  padding: 0;
  cursor: pointer;
  color: var(--color-reco-blue);
  transition: color 150ms ease-out;

  &:hover {
    color: var(--color-signal-blue);
  }
}

.venue-meter {
  display: flex;
  gap: 1px;
  height: 0.875rem;
  width: 3rem;
  border-radius: 999px;
  overflow: hidden;

  &--wide {
    width: 6rem;
  }

  &[data-value='1'] .venue-meter-bar:nth-child(1) {
    background-color: var(--color-signal-orange);
  }

  &[data-value='2'] .venue-meter-bar:nth-child(-1n + 2) {
    background-color: var(--color-reco-green);
  }

  &[data-value='3'] .venue-meter-bar:nth-child(-1n + 3) {
    background-color: var(--color-signal-green);
  }

  &[data-value='4'] .venue-meter-bar:nth-child(1n) {
    background-color: var(--color-signal-green);
  }
}

.venue-meter-bar {
  flex: 1 1 0%;
  background-color: var(--color-grey-400);
}
