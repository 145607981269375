.navigation-popup {
  position: absolute;
  background-color: $white;
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.2);
  border-radius: 0.5em;
  text-align: left;
  padding: 0.6rem 0;

  &-top-left {
    top: 2.25rem;
    left: 0;
  }

  &-top-right {
    top: 2.25rem;
    right: 0;
  }

  &-bottom-left {
    bottom: 2.25rem;
    left: 0;
  }

  &-bottom-right {
    bottom: 2.25rem;
    right: 0;
  }

  a {
    display: block;
    padding: 0.4rem 1rem;
    color: $blue7;
    text-decoration: none;
    font-size: 0.875em;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
}