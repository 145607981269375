.text-clamp {
  position: relative;
  margin-bottom: 0.25em;

  &--inner {
    font-size: 0.875em;
    font-weight: 300;
    line-height: 1.57;
  }

  &--expand {
    display: none;
    position: absolute;
    bottom: -2em;
    width: 100%;
    height: 5.625em;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 69.09%);
    align-items: flex-end;
    justify-content: center;
  }

  &.text-clamp--gray {
    .text-clamp--expand {
      background: linear-gradient(to bottom, rgba(255,255,255,0), $gray2);
    }
  }

  &.text-clamp--clamped {
    .text-clamp--inner {
      max-height: 6.5em;
      overflow: hidden;
    }

    .text-clamp--expand {
      display: flex;
    }
  }

  .bttn {
    color: $blue5;
  }
}