.image-order {
  padding-top: 1em;

  .heading3 {
    margin-bottom: 0.5em;
  }

  &-description {
    font-size: 0.875em;
    margin-top: 0;
    margin: 0 0 1.25rem;
  }

  &-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    &-item {
      margin-right: 0.25em;
      margin-bottom: 0.25em;
      text-align: center;
      background-size: cover;
      background-position: center;
      width: calc(50% - 0.25em);
      height: 8.5em;

      @media (min-width: $breakpoint-large-tablet) {
        width: calc(25% - 0.25em);
        height: 12.5em;
      }

      &[draggable="true"] {
        cursor: grab;
      }
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .bttn,
    .loader-circular.xs {
      margin: 0 0.5rem 0 0;
    }
  }

  &-error {
    color: $red;
    margin-top: 1em;

    @media (min-width: $breakpoint-small-tablet) {
      margin-top: 0;
    }
  }
}

body.image-order-no-scroll {
  overflow: hidden;
}