.entity-list {
  margin: 0;
  padding: 0;
  list-style: none;

  &-item {
    margin-bottom: 1.5em;

    &:last-child {
      margin-bottom: 0;
    }

    &, .heading4 > a {
      color: $gray14;
    }

    .heading {
      margin-bottom: 0.5em;
    }

    > div {
      display: flex;
      align-items: center;
    }

    &-rating {
      font-size: 0.875em;
    }
  }
}