.categories {
  text-align: center;

  h2 {
    @include heading-underline($inline: true);
  }
}

.categories-table {
  text-align: left;
  .category-header {
    display: flex;
    overflow-x: auto;
    list-style: none;
    gap: 1rem;

    margin: 0.5rem;
    padding: 0.5rem 0.5rem 1rem 0.5rem;

    @include breakpoint($breakpoint-small-tablet) {
      justify-content: center;
    }

    .region-label {
      flex-shrink: 0;
      flex-basis: auto;
    }
  }

  .categories-content {
    margin: 0 1rem;
    .category-list {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 50%));
      gap: 1rem 1.375rem;
      padding: 0;
      margin: 0;
      list-style: none;

      &.hidden {
        display: none;
      }

      @include breakpoint($breakpoint-small-tablet) {
        grid-template-columns: repeat(4, 1fr);
      }

      .region-information-category {
        @include bttn(true, true);
        justify-content: flex-start;
        background-color: $white;
        padding: 0.5rem;
        .category-label {
          font-weight: 700;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .all-categories {
    display: flex;
    justify-content: center;
    font-weight: 700;
    margin-top: 2.5rem;
    line-height: 22px;
    text-decoration: none;
  }
}
