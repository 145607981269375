.review-list {
  margin: 0;
  padding: 0;

  @media (min-width: $breakpoint-large-tablet) {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    gap: 1.5em;
  }

  li {
    break-inside: avoid;
    margin-bottom: 1.5em;
  }
}

.venue-main--heading,
.profile-main--heading {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: $breakpoint-small-tablet) {
    flex-direction: row;
  }

  @media (min-width: $breakpoint-large-tablet) {
    flex-direction: column;
  }

  @media (min-width: $breakpoint-tablet) {
    flex-direction: row;
  }

  &-options {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1.5em;

    .form-check {
      font-size: 0.875em;
    }
  }

  > .heading3 {
    width: 100%;
    text-align: center;
  }

  .heading3 {
    @media (min-width: $breakpoint-small-tablet) {
      margin-right: 1em;
    }

    @media (min-width: $breakpoint-large-tablet) {
      width: 100%;
    }

    @media (min-width: $breakpoint-tablet) {
      margin-right: 1em;
    }
  }

  a {
    margin-bottom: 2em;
    width: 100%;

    @media (min-width: $breakpoint-small-tablet) {
      margin: 0 0 0 auto;
      width: auto;
    }

    @media (min-width: $breakpoint-large-tablet) {
      margin: 0 0 2em 0;
      width: 100%;
    }

    @media (min-width: $breakpoint-tablet) {
      margin: 0 0 0 auto;
      width: auto;
    }

    &.standalone {
      margin-bottom: 1.75rem;
      margin-left: 0;
    }
  }
}

.venue-main,
.profile-main {
  ul {
    li {
      list-style: none;
      margin-bottom: 0.875em;
      padding-bottom: 0.875em;
      border-bottom: 1px solid $gray11;

      &.review-not-found {
        text-align: center;
      }
    }

    &#review-list > li {
      display: flex;
      flex-direction: column;
      align-items: center;

      &.review-hidden {
        display: none;
      }

      > a {
        font-size: 0.875rem;
        display: block;
        margin-top: .5rem;
      }
    }
  }

  .more-reviews-button {
    overflow-anchor: none;
  }
}
