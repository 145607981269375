.lead-cta {
  border-top: 1px solid $gray11;
  border-bottom: 1px solid $gray11;
  padding: 1.125rem 1.5rem 1.125rem 4.125rem;
  list-style: none;
  cursor: pointer;
  background: url('/assets/images/reco-circle-half.svg') no-repeat center left $gray12;

  .heading3 {
    margin-bottom: 0.25em;
  }

  p {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 300;
  }

  a {
    text-decoration: none;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
}