.review-card-v2 {
  display: block;
  width: 100%;
  font-size: 0.875rem; // 👈 fix for profile pages
  scroll-margin-top: 5.125rem;
}

.review-card-v2 p {
  margin: 0;
}

.review-card-v2 small {
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: var(--color-grey-600);
}

.review-card-v2-header {
  display: flex;
  padding: 1rem 1.5rem;
  gap: 0.5rem;
  align-items: flex-start;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border: 1px solid var(--color-grey-300);
  background: var(--color-grey-100);
}

.review-card-v2-header-avatar {
  border-radius: 999px;
  flex: 0 0 auto;
}

.review-card-v2-header-info {
  flex: 1 1 auto;
}

.review-card-v2-header-info-top {
  display: flex;
  align-items: center;
  place-content: space-between;
}

.review-card-v2-header-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.review-card-v2-header-time {
  margin-left: 1rem;
  flex: 0 0 auto;
}

.review-card-v2-body {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 0.5rem;
  border-left: 1px solid var(--color-grey-300);
  border-right: 1px solid var(--color-grey-300);
}

.review-card-v2-body:last-child {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-bottom: 1px solid var(--color-grey-300);
}

.review-card-v2-body-top {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 0.75rem;
  row-gap: 0.125rem;

  .felt-btn {
    margin-left: -0.25rem;
  }
}

.review-card-v2-footer {
  padding: 0.5rem 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border: 1px solid var(--color-grey-300);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.review-response-wrapper {
  display: flex;
  gap: 0.5rem;
  margin-top: -0.5rem;

  .felt-icon {
    transform: scaleX(-1);
    color: var(--color-grey-500);
  }
}

.review-drawer-wrapper {
  position: relative;
}

.review-drawer-wrapper:not(.open) .review-drawer {
  display: none;
}

.review-drawer {
  position: absolute;
  top: 100%;
  right: 0px;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 16rem;
  padding: 1rem;
  margin-top: 0.5rem;
  background-color: white;
  box-shadow: $shadow-sm;
  z-index: 10;
  border-radius: 0.5rem;
}

.review-card-v2-report-marker {
  color: var(--color-grey-500);
  transition: transform 150ms ease-out;
}

.review-card-v2-report[open] .review-card-v2-report-marker {
  transform: rotate(90deg);
}

.review-card-v2-report-body {
  margin: 0.5rem 1rem;
}

.review-card-v2-report fieldset {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 -1rem;
  border: 0;
  gap: 0.25rem;
}

.review-card-v2-report legend {
  font-weight: 500;
  margin: 0 1rem 0.25rem;
}

.review-card-v2-admin-bar {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}
