.results-ad {
  padding: 20px;
  background-color: white;
  border: 1px solid $gray13;
  border-radius: 5px;
  z-index: $layer-8;
  margin: 1em 0 0;
  color: $black;
  position: relative;
  font-size: 16px;

  &--tip {
    position: absolute;
    left: 50%;
    margin-top: -2.45em;
    transform: translateX(-50%);
    padding: 0.35em 0.6875em;
    background-color: $white;
    box-sizing: border-box;
    border: 1px solid $gray13;
    border-radius: 3em;

    .icon {
      width: 20px;
      height: 20px;
      background: url(/assets/images/icons/reco-menu-lightup.svg) no-repeat center
        center;
      background-size: 20px 17px;
      display: inline-block;
      vertical-align: middle;
      margin-right: .25em;
    }

    .title {
      font-style: normal;
      font-size: .875em;
      vertical-align: middle;
      font-weight: 700;
    }
  }

  &--content {

    .top {
      display: flex;

      &--rating {

        a {
          margin-bottom: .25em;
          display: flex;
        }

        > div {
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          @media (min-width: $breakpoint-small-tablet) {
            align-items: center;
            flex-direction: row;
          }

          .reco-rating {
            margin-bottom: 0.625em;

            @media (min-width: $breakpoint-small-tablet) {
              margin-bottom: 0;
            }
          }

          div:not(.reco-rating) {
            font-size: .875em;
            line-height: 1;

            @media (min-width: $breakpoint-small-tablet) {
              margin-left: .5em;
            }
          }
        }
      }

      &--badges {
        margin-left: auto;
      }
    }

    .bottom {
      margin-top: 1em;
      border-top: 1px solid $gray13;

      &--review {
        padding: 1em 0;
        font-size: 1em;
        font-style: italic;
        font-weight: 400;
        color: $black;
      }

      &--more {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .fbtn-green {
          opacity: 0;
          transition: opacity .25s ease-in-out;
        }
      }
    }
  }

  &:hover {
    .bottom--more {
      .fbtn-green {
        opacity: 1;
      }
    }
  }
}
