.date-range-picker {
  @media (min-width: $breakpoint-tablet) {
    display: flex;
    align-items: flex-start;
  }

  &.date-range-picker-boxed {
    background-color: $gray12;
    padding: 1em;
    border-radius: 0.5em;

    @media (min-width: $breakpoint-tablet) {
      padding: 1.5em;
    }
  }

  label {
    font-size: 0.875em;
    margin-bottom: 0.75rem;

    @media (min-width: $breakpoint-tablet) {
      margin-bottom: 0;
      margin-right: 0.5rem;
    }
  }

  .form-control {
    margin-bottom: 0.75rem;

    @media (min-width: $breakpoint-tablet) {
      margin-bottom: 0;
    }
  }

  button {
    width: 100%;

    @media (min-width: $breakpoint-tablet) {
      width: auto;
      margin-left: 0.375em;
    }
  }

  &-fields {
    display: flex;
    flex-direction: row;

    @media (min-width: $breakpoint-tablet) {
      width: 100%;
    }

    > div {
      width: 100%;

      &:last-child {
        margin-left: 0.5rem;

        @media (min-width: $breakpoint-tablet) {
          margin-left: 1rem;
        }
      }

      @media (min-width: $breakpoint-tablet) {
        display: flex;
        align-items: center;
      }
    }
  }
}
