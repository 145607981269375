$advice-card-min-with: 526px;

.shared-reviews-ReplyForm {
  display: grid;
  gap: var(--grid-16);

  border-radius: var(--border-radius-default);
  padding: var(--grid-16);
  background-color: var(--color-grey-100);

  @include breakpoint($breakpoint-reco) {
    grid-template-columns: 1fr auto;
  }

  .control-container {
    @include breakpoint($breakpoint-reco) {
      grid-column: span 2;
    }
  }

  .review-card--reply-advice {
    @include breakpoint($breakpoint-reco) {
      position: relative;
      margin-left: auto;
    }

    .review-card--reply-advice-text {
      display: none;
      padding: var(--grid-16);
      z-index: var(--layer-alpha-centauri);

      @include breakpoint($breakpoint-reco) {
        position: absolute;
        right: 0;
        margin-top: 0.5rem;

        background-color: var(--color-white);
        border-radius: var(--border-radius-default);

        /* Card Drop Shadow */
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

        min-width: $advice-card-min-with;
      }

      h4 {
        @include heading4;
        margin-bottom: 0.5rem;
      }

      p {
        margin: 0 0 1.5rem;
      }

      img {
        margin-top: 0.5rem;
      }
    }

    // @note this styling is "tapping in" to a subcomponent – we should rather
    // find a way to configure such settings via component properties or CSS variables.
    .button-icon {
      transition: transform 0.3s ease-in-out;
    }

    &.unfolded {
      .button-icon {
        transform: rotate(-180deg);
      }

      .review-card--reply-advice-text {
        display: block;
      }
    }
  }

  .alert {
    @include breakpoint($breakpoint-reco) {
      grid-column: span 2;
      z-index: var(--layer-andromeda);
    }
  }

  .review-card--reply-error {
    @include breakpoint($breakpoint-reco) {
      grid-column: span 2;
    }
  }
}
