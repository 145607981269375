.shared-dividers-HorizontalRule {
  $divider-thickness: 2px;

  border-width: 0;
  height: 0;
  margin: 0;

  border-top-color: $grey-200;
  border-top-width: $divider-thickness;
  border-top-style: solid;

  &.spacing {
    margin-top: 1rem;
    margin-bottom: 1rem;

    @include breakpoint($breakpoint-reco) {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
}
