$orange: #FAA51A;
$pink: #EB2975;
$dpink: #EB1D74;
$green: #5CC83B;
$red: #C00;
$blue: #0093DF;
$dblue: #0D91E6;
$bluegray: #234955;
$red-orange: #FF6745;
$shadow-gray: #050505;
$dark-green: #BBD531;
$gray1: #E5E5E5;
$gray2: #F6F6F6;
$gray3: #AEAEAE;
$gray4: #D6D6D6;
$gray5: #202020;
$gray6: #262626;
$gray8: #6D6D6D;
$gray10: #EFEFEF;
$gray13: #E1E1E1;
$blue1: #489BD4;
$blue2: #244E6A;
$blue4: #A3CDE9;
$blue5: #1578B0;
$blue6: #51A7D8;
$pink1: #CA1A73;
$pink2: #EC1A75;
$input-block-border: #BBB;
$input-error-border-color: #FAA;
$bg-sky: #B4E5F5;
$bg-dgray: #EEEEEE;
$bg-light-red: #FDD;
$bg-light-green: #EBFBEB;
$bg-lgreen: #F2FCF2;

// Alias
// simple search/replace refactor

// aliases to defenitions in: [Design system] Color scheme
$text-color: $black;

$lpink: $reco-pink-light;
$light-green: $reco-green-light;
$light-blue: $reco-blue-light;
$light-yellow: $reco-yellow-light;

$gray14: $grey-600;
$gray9: $grey-500;
$gray11: $grey-300;
$gray7: $grey-200;
$gray12: $grey-100;

$blue3: $reco-blue;
$blue7: $signal-blue;
$pink3: $reco-pink;

$input-bg: $white;
$input-select-bg: $white;

$button-fg: $white;

$bg-default: $white;
$bg-gray: $grey-200;
$bg-lgray: $grey-100;

// aliases to defenitions in: the legacy Color scheme
$light-pink: $lpink;
