.send-invites {
  &-intro {
    font-size: 0.875em;
    color: $black;
  }

  &-tabs {
    @media (min-width: $breakpoint-small-tablet) {
      display: flex;
    }

    .form-select {
      margin-bottom: 1.5em;

      @media (min-width: $breakpoint-small-tablet) {
        display: none;
      }
    }

    button {
      display: none;

      @media (min-width: $breakpoint-small-tablet) {
        display: block;
        margin-right: 1em;
      }
    }
  }

  > .hr {
    display: none;

    @media (min-width: $breakpoint-small-tablet) {
      display: block;
    }
  }

  textarea {
    height: 6rem;
  }
}
