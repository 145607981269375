/**
 * This files is a barrel file for the current Sass loading system.
 * @deprecated replace with CSSModule
 */

// Components
@import '../components/Todos/todos';

// Views
@import '../views/SendInvites/sendInvites';

