.footer-hero {
  padding: 0 0 1.25rem;
  background-color: $reco-yellow-light;
  color: $black;
  display: flex;
  flex-flow: column;
  font-size: 1rem;
  justify-content: center;

  .footer-hero-content {
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;

    h2,
    p {
      max-width: 17.65rem;
      @include breakpoint($breakpoint-portrait-tablet) {
        max-width: none;
      }
    }

    h2 {
      margin: 3rem auto 1rem;
      @include breakpoint($breakpoint-portrait-tablet) {
        margin-top: 0;
        font-size: 2rem;
        line-height: 1.25;
      }
    }

    p {
      font-size: $default-font-size;
      margin: 0 auto 1rem;

      @include breakpoint($breakpoint-portrait-tablet) {
        font-size: 1rem;
        max-width: 30.625rem;
        margin-bottom: 1.5rem;
      }
    }

    a {
      display: inline-block;
      padding: 0.5625rem 0.5rem;
      font-size: $default-font-size;
      font-weight: 700;
      text-decoration: none;
    }

    @include breakpoint($breakpoint-portrait-tablet) {
      order: 1;

      margin: 0;
    }
  }

  .lottie-wrapper {
    width: 11.6rem;
    margin-top: 0;
    align-self: center;


    @include breakpoint($breakpoint-portrait-tablet) {
      margin-top: 0;
      // align-self: normal;

      &:nth-child(2n) {
        order: 0;
      }
      &:nth-child(2n + 1) {
        order: 2;
      }
    }

    svg {
      width: 100%;
      height: 100%;
      transform: translate3d(0px, 0px, 0px);

      &:not(:root) {
        overflow: hidden;
      }
    }

    @include breakpoint($breakpoint-landscape-tablet) {
      height: 374px;
      width: 350px;
    }
  }

  .hidden-sm {
    display: none;

    @include breakpoint($breakpoint-portrait-tablet) {
      display: initial;
    }
  }

  @include breakpoint($breakpoint-portrait-tablet) {
    flex-flow: row;
    min-height: 31.25rem;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 0 0;
  }
}
