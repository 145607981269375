@mixin tooltip-theme($theme: 'info') {
  $border-color: $grey-300;
  $background-color: $white;

  @if $theme == 'warning' {
    $border-color: $reco-yellow;
    $background-color: $reco-yellow-light;
  }

  @if $theme == 'action' {
    $border-color: $reco-blue;
    $background-color: $reco-blue-light;
  }

  .tooltip-layout {
    border-color: $border-color;
    background-color: $background-color;
  }
  .tooltip-arrow {
    border-color: $border-color;
    background-color: $background-color;
  }
  .tooltip-content {
    background-color: $background-color;
  }
}

// If Tooltip is positioned NOT using the prefered TooltipLink
// it will default to this value.
$default-tooltip-width: 200px;

.tooltip-wrapper {
  --tooltip-color: #{$black};
  --tooltip-arrow-layout-dimentions: 1rem;
  --tooltip-arrow-dimentions: calc(var(--tooltip-arrow-layout-dimentions) * 0.75);

  $tooltip-arrow-y-adjustment: calc(var(--tooltip-arrow-layout-dimentions) * -0.5);
  $tooltip-contet-y-adjustment: calc(var(--tooltip-arrow-layout-dimentions) * -1);

  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  z-index: $layer-10;
  transform: translateY(calc($tooltip-arrow-y-adjustment * -1));

  .tooltip-layout,
  .tooltip-arrow {
    border-style: solid;
    border-width: 2px;
  }

  @include tooltip-theme;

  &.warning {
    @include tooltip-theme('warning');
  }

  &.action {
    @include tooltip-theme('action');
  }

  .tooltip-layout {
    width: $default-tooltip-width;
    max-width: 96vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1.125rem;
    color: var(--tooltip-color);
    border-radius: 0.5rem;

    .tooltip-arrow-layout {
      margin: 0 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateY($tooltip-arrow-y-adjustment);
      width: var(--tooltip-arrow-layout-dimentions);
      height: var(--tooltip-arrow-layout-dimentions);
      .tooltip-arrow {
        width: var(--tooltip-arrow-dimentions);
        height: var(--tooltip-arrow-dimentions);
        transform: rotate(45deg);
      }
    }

    .tooltip-content {
      margin-top: $tooltip-contet-y-adjustment;
      padding: 0.5rem;
      width: 100%;
      z-index: 1;
      border-radius: 0.5rem;
    }
  }
}
