@import 'carousel-control';

@mixin carousel-track-animation {
  margin-left: 0;
  transition: margin-left 0.5s ease-out;
}

// for better overview – all root variables should be defined
//  in a root (global) css variables file
:root {
  --carousel-next-fade-color: #{$white};
}

$carousel-control-width: 4rem;
$carousel-control-width-large: 6rem;

.carousel-container {
  --carousel-gap-value: 1.5rem;

  position: relative;
  overflow: hidden;

  @include carousel-control();

  .carousel-track-container {
    margin-left: $carousel-control-width;
    @include breakpoint($breakpoint-reco-large) {
      margin-left: $carousel-control-width-large;
    }

    &:after {
      position: absolute;
      content: ' ';
      pointer-events: none;
      top: 0;
      right: 0;
      height: 100%;
      width: 4rem;
      background: linear-gradient(to left, var(--carousel-next-fade-color), transparent);
    }

    .carousel-track {
      display: inline-flex;
      align-items: start;
      gap: var(--carousel-gap-value);

      @include carousel-track-animation();
    }
  }
}
