.review-filter-dialog {
  position: fixed;
  inset: 0;
  display: flex;
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms ease-out;
  padding: 0;
  border: 0;
  margin: 0;
  box-shadow: var(--shadow-lg), 0 0 0 100vw rgba(0, 0, 0, 0.25);
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  max-height: none;
  max-width: none;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    width: 24rem;
  }

  &::backdrop {
    background: none;
  }

  &[open] {
    opacity: 1;
    pointer-events: inherit;
  }
}

.review-filter-dialog-header {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem 1.5rem;
}

.review-filter-form {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.review-filter-fields {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  overflow: auto;
  padding: 1rem 1.5rem;
  margin: 0;
  border: 0;
}

.review-filter-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.review-filter-actions {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-around;
  gap: 1.5rem;
  padding: 1rem 1.5rem;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.08);
  clip-path: inset(-100vh 0 0);
  margin: 0;
  border: 0;
}

.review-filter-period-group {
  display: flex;
  gap: 0.25rem;
  padding: 0.25rem;
  background-color: var(--color-grey-200);
  border-radius: 0.5rem;

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  label {
    flex: 1 1 0%;
    text-align: center;
    border-radius: 0.5rem;
    padding: 0.25rem 0;
    line-height: 1.5rem;
    transition-property: background, box-shadow, color;
    transition-duration: 300ms;
    transition-timing-function: ease-out;
    cursor: pointer;

    &:hover {
      background-color: var(--color-grey-300);
    }
  }

  input:checked + label {
    background-color: var(--color-white);
    box-shadow: 0px 1px 3px rgba(#000, 0), inset 0 0 0 1px var(--color-grey-400);
  }

  input:focus-visible + label {
    outline: auto 1px;
  }
}

.review-filter-rating-group {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.review-filter-rating-meter {
  flex: 1 1 0;
  position: relative;
  height: 0.5rem;
  border-radius: 0.125rem;
  background-color: var(--color-grey-300);
  overflow: hidden;
}

.review-filter-rating-meter-inner {
  position: absolute;
  inset: 0;
  transition: inset 300ms ease-out;
}

.review-filter-rating-number {
  flex: 0 0 4.5ch;
  text-align: right;
}

.review-filter-category-group {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  row-gap: 1rem;

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  input:focus-visible + .felt-btn {
    outline: auto 1px;
  }
}
