@import "../layout/grid-background";

// The base font size.
$base-font-size: 16px !default;

// The base line height determines the basic unit of vertical rhythm.
$base-line-height: 24px !default;

// Set the default border style for rhythm borders.
$default-rhythm-border-style: solid !default;

// The default font size in all browsers.
$browser-default-font-size: 16px;

// Set to false if you want to use absolute pixels in sizing your typography.
$relative-font-sizing: true !default;

// Allows the `adjust-font-size-to` mixin and the `lines-for-font-size` function
// to round the line height to the nearest half line height instead of the
// nearest integral line height to avoid large spacing between lines.
$round-to-nearest-half-line: false !default;

// Ensure there is at least this many pixels
// of vertical padding above and below the text.
$min-line-padding: 2px !default;

// $base-font-size but in your output unit of choice.
// Defaults to 1em when `$relative-font-sizing` is true.
$font-unit: if($relative-font-sizing, 1em, $base-font-size) !default;

// The basic unit of font rhythm.
$base-rhythm-unit: calc($base-line-height / $base-font-size * $font-unit);

// The leader is the amount of whitespace in a line.
// It might be useful in your calculations.
$base-leader: calc(($base-line-height - $base-font-size) * $font-unit / $base-font-size);

// The half-leader is the amount of whitespace above and below a line.
// It might be useful in your calculations.
$base-half-leader: calc($base-leader / 2);

// True if a number has a relative unit.
@function relative-unit($number) {
  @return unit($number) == "%" or unit($number) == "em" or unit($number) == "rem"
}

// True if a number has an absolute unit.
@function absolute-unit($number) {
  @return not(relative-unit($number) or unitless($number));
}

@if $relative-font-sizing and not(relative-unit($font-unit)) {
  @warn "$relative-font-sizing is true but $font-unit is set to #{$font-unit} which is not a relative unit.";
}

// Establishes a font baseline for the given font-size.
@mixin establish-baseline($font-size: $base-font-size) {
  // IE 6 refuses to resize fonts set in pixels and it weirdly resizes fonts
  // whose root is set in ems. So we set the root font size in percentages of
  // the default font size.
  * html {
    font-size: 100% * calc($font-size / $browser-default-font-size);
  }
  html {
    font-size: $font-size;
    @include adjust-leading-to(1, if($relative-font-sizing, $font-size, $base-font-size));
  }
}

// Resets the line-height to 1 vertical rhythm unit.
// Does not work on elements whose font-size is different from $base-font-size.
//
// @deprecated This mixin will be removed in the next release.
// Please use the `adjust-leading-to` mixin instead.
@mixin reset-baseline {
  @include adjust-leading-to(1, if($relative-font-sizing, $base-font-size, $base-font-size));
}

// Show a background image that can be used to debug your alignments.
// Include the $img argument if you would rather use your own image than the
// Compass default gradient image.
@mixin debug-vertical-alignment($img: false) {
  @if $img {
    background: image-url($img);
  } @else {
    @include baseline-grid-background($base-rhythm-unit);
  }
}

// Adjust a block to have a different font size and line height to maintain the
// rhythm. $lines specifies how many multiples of the baseline rhythm each line
// of this font should use up. It does not have to be an integer, but it
// defaults to the smallest integer that is large enough to fit the font.
// Use $from-size to adjust from a font-size other than the base font-size.
@mixin adjust-font-size-to($to-size, $lines: lines-for-font-size($to-size), $from-size: $base-font-size) {
  @if not($relative-font-sizing) and $from-size != $base-font-size {
    @warn "$relative-font-sizing is false but a relative font size was passed to adjust-font-size-to";
  }
  font-size: calc($font-unit * $to-size / $from-size);
  @include adjust-leading-to($lines, if($relative-font-sizing, $to-size, $base-font-size));
}

// Adjust a block to have different line height to maintain the rhythm.
// $lines specifies how many multiples of the baseline rhythm each line of this
// font should use up. It does not have to be an integer, but it defaults to the
// smallest integer that is large enough to fit the font.
@mixin adjust-leading-to($lines, $font-size: $base-font-size) {
  line-height: rhythm($lines, $font-size);
}

// Calculate rhythm units.
@function rhythm(
  $lines: 1,
  $font-size: $base-font-size,
  $offset: 0
) {
  @if not($relative-font-sizing) and $font-size != $base-font-size {
    @warn "$relative-font-sizing is false but a relative font size was passed to the rhythm function";
  }
  $rhythm: calc($font-unit * ($lines * $base-line-height - $offset) / $font-size);
  // Round the pixels down to nearest integer.
  @if unit($rhythm) == px {
    $rhythm: floor($rhythm);
  }
  @return $rhythm;
}

// Calculate the minimum multiple of rhythm units needed to contain the font-size.
@function lines-for-font-size($font-size) {
  $lines: if($round-to-nearest-half-line,
              ceil(2 * $font-size / $base-line-height) / 2,
              ceil($font-size / $base-line-height));
  @if $lines * $base-line-height - $font-size < $min-line-padding * 2 {
    $lines: $lines + if($round-to-nearest-half-line, 0.5, 1);
  }
  @return $lines;
}

// Apply leading whitespace. The $property can be margin or padding.
@mixin leader($lines: 1, $font-size: $base-font-size, $property: margin) {
  #{$property}-top: rhythm($lines, $font-size);
}

// Apply leading whitespace as padding.
@mixin padding-leader($lines: 1, $font-size: $base-font-size) {
  padding-top: rhythm($lines, $font-size);
}

// Apply leading whitespace as margin.
@mixin margin-leader($lines: 1, $font-size: $base-font-size) {
  margin-top: rhythm($lines, $font-size);
}

// Apply trailing whitespace. The $property can be margin or padding.
@mixin trailer($lines: 1, $font-size: $base-font-size, $property: margin) {
  #{$property}-bottom: rhythm($lines, $font-size);
}

// Apply trailing whitespace as padding.
@mixin padding-trailer($lines: 1, $font-size: $base-font-size) {
  padding-bottom: rhythm($lines, $font-size);
}

// Apply trailing whitespace as margin.
@mixin margin-trailer($lines: 1, $font-size: $base-font-size) {
  margin-bottom: rhythm($lines, $font-size);
}

// Shorthand mixin to apply whitespace for top and bottom margins and padding.
@mixin rhythm($leader: 0, $padding-leader: 0, $padding-trailer: 0, $trailer: 0, $font-size: $base-font-size) {
  @include leader($leader, $font-size);
  @include padding-leader($padding-leader, $font-size);
  @include padding-trailer($padding-trailer, $font-size);
  @include trailer($trailer, $font-size);
}

// Apply a border and whitespace to any side without destroying the vertical
// rhythm. The whitespace must be greater than the width of the border.
@mixin apply-side-rhythm-border($side, $width: 1px, $lines: 1, $font-size: $base-font-size, $border-style: $default-rhythm-border-style) {
  @if not($relative-font-sizing) and $font-size != $base-font-size {
    @warn "$relative-font-sizing is false but a relative font size was passed to apply-side-rhythm-border";
  }
  border-#{$side}-style: $border-style;
  border-#{$side}-width: calc($font-unit * $width / $font-size);
  padding-#{$side}: rhythm($lines, $font-size, $offset: $width);
}

// Apply borders and whitespace equally to all sides.
@mixin rhythm-borders($width: 1px, $lines: 1, $font-size: $base-font-size, $border-style: $default-rhythm-border-style) {
  @if not($relative-font-sizing) and $font-size != $base-font-size {
    @warn "$relative-font-sizing is false but a relative font size was passed to rhythm-borders";
  }
  border: {
    style: $border-style;
    width: calc($font-unit * $width / $font-size);
  };
  padding: rhythm($lines, $font-size, $offset: $width);
}

// Apply a leading border.
@mixin leading-border($width: 1px, $lines: 1, $font-size: $base-font-size, $border-style: $default-rhythm-border-style) {
  @include apply-side-rhythm-border(top, $width, $lines, $font-size, $border-style);
}

// Apply a trailing border.
@mixin trailing-border($width: 1px, $lines: 1, $font-size: $base-font-size, $border-style: $default-rhythm-border-style) {
  @include apply-side-rhythm-border(bottom, $width, $lines, $font-size, $border-style);
}

// Apply both leading and trailing borders.
@mixin horizontal-borders($width: 1px, $lines: 1, $font-size: $base-font-size, $border-style: $default-rhythm-border-style) {
  @include leading-border($width, $lines, $font-size, $border-style);
  @include trailing-border($width, $lines, $font-size, $border-style);
}

// Alias for `horizontal-borders` mixin.
@mixin h-borders($width: 1px, $lines: 1, $font-size: $base-font-size, $border-style: $default-rhythm-border-style) {
  @include horizontal-borders($width, $lines, $font-size, $border-style);
}

// Shorthand mixin to apply whitespace for top and bottom margins.
@mixin rhythm-margins(
  $leader: 1,
  $trailer: $leader,
  $font-size: $base-font-size
) {
  @include leader($leader, $font-size);
  @include trailer($trailer, $font-size);
}
